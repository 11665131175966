"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForcedPlaceholder = exports.AlertMaxTagCount = exports.SearchBarItemTitle = exports.SearchBar = exports.Search = exports.SearchIcon = exports.SearchBarItem = exports.SelectWrapper = exports.ButtonWrapper = void 0;
const icons_1 = require("@ant-design/icons");
const elements_1 = require("@src/components/elements");
const styled_breakpoints_1 = require("styled-breakpoints");
const styled_components_1 = __importStar(require("styled-components"));
exports.ButtonWrapper = (0, styled_components_1.default)(elements_1.Button) `
	&& {
		width: 100%;
		height: 100%;
		border-radius: 0;
		border: none;
		display: flex;
		justify-content: center;
	}
`;
exports.SelectWrapper = styled_components_1.default.div `
	position: relative;
	.ant-select-selector {
		padding: 0.25rem 0.25rem 0.25rem 1.75rem;
		border-radius: 0 !important;
	}
	.ant-select-selection-placeholder {
		padding-left: 1.5rem;
	}
	.ant-tag {
		display: none;
	}
	.ant-select-selection-search {
		margin-inline-start: 7px !important;
	}
`;
exports.SearchBarItem = styled_components_1.default.div `
	flex: 1;
	width: 50%;

	${props => props.hasNegativeMargin &&
    (0, styled_components_1.css) `
			margin-left: -1px;
		`}

	${(0, styled_breakpoints_1.down)('md')} {
		margin-left: 0;
		width: 100%;
	}
`;
exports.SearchIcon = (0, styled_components_1.default)(icons_1.SearchOutlined) `
	position: absolute;
	z-index: 2;
	top: 30%;
	left: 8px;

	svg {
		width: 16px;
		margin-right: 4px;
	}
`;
exports.Search = styled_components_1.default.div `
	align-self: flex-start;
	margin-left: -1px;
	margin-top: 25px;
	width: max-content;

	${(0, styled_breakpoints_1.down)('md')} {
		display: none;
	}
`;
exports.SearchBar = styled_components_1.default.div `
	flex: 1;
	display: flex;

	${(0, styled_breakpoints_1.down)('md')} {
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
	}
`;
exports.SearchBarItemTitle = styled_components_1.default.div `
	color: ${({ theme }) => theme.color.brand1};
	font-size: 14px;
	margin: 0 0 4px 4px;
`;
exports.AlertMaxTagCount = (0, styled_components_1.default)(elements_1.Alert) `
	border-top: none;
	border-radius: 0 0 4px 4px;
`;
exports.ForcedPlaceholder = styled_components_1.default.span `
	font-size: 14px;
	z-index: 1;
	position: absolute;
	top: 50%;
	right: 11px;
	left: 11px;
	transform: translateY(-50%);
	transition: all 0.3s;
`;
