"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledCloudUpload = exports.DropzoneOverlayText = exports.DropzoneContent = exports.DropzoneOverlay = exports.DropzoneContainer = void 0;
const icons_1 = require("@ant-design/icons");
const styled_components_1 = __importStar(require("styled-components"));
exports.DropzoneContainer = styled_components_1.default.div `
	position: relative;
`;
exports.DropzoneOverlay = styled_components_1.default.div `
	outline: 2px dashed ${props => props.theme.color.brand4};
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	background: linear-gradient(
		-45deg,
		rgba(85, 139, 221, 0.4) 0%,
		rgba(85, 139, 221, 0.1) 20%,
		rgba(85, 139, 221, 0.4) 40%,
		rgba(85, 139, 221, 0.1) 60%,
		rgba(85, 139, 221, 0.4) 80%,
		rgba(85, 139, 221, 0.1) 100%
	);
	z-index: 2;
	display: none;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	${props => props.isDraggingOverBody &&
    (0, styled_components_1.css) `
			display: flex;
		`}
`;
exports.DropzoneContent = styled_components_1.default.div `
	${props => props.isDraggingOverBody &&
    (0, styled_components_1.css) `
			opacity: 0.25;
		`}
`;
exports.DropzoneOverlayText = styled_components_1.default.span `
	color: ${props => props.theme.color.brand1};
	font-size: 24px;
	font-weight: bold;
`;
exports.StyledCloudUpload = (0, styled_components_1.default)(icons_1.CloudUploadOutlined) `
	font-size: 150px;
	color: ${props => props.theme.color.brand1};
`;
