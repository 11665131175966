"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactFormFieldType = exports.ContactFormField = void 0;
var ContactFormField;
(function (ContactFormField) {
    ContactFormField["name"] = "name";
    ContactFormField["email"] = "email";
    ContactFormField["type"] = "type";
    ContactFormField["message"] = "message";
})(ContactFormField || (exports.ContactFormField = ContactFormField = {}));
var ContactFormFieldType;
(function (ContactFormFieldType) {
    ContactFormFieldType["servicesJobAdverts"] = "SERVICES_JOB_ADVERTS";
    ContactFormFieldType["servicesRecruitment"] = "SERVICES_RECRUITMENT";
    ContactFormFieldType["cooperation"] = "COOPERATION";
    ContactFormFieldType["prAndMarketing"] = "PR_AND_MARKETING";
    ContactFormFieldType["support"] = "SUPPORT";
})(ContactFormFieldType || (exports.ContactFormFieldType = ContactFormFieldType = {}));
