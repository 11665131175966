const BREAKPOINTS = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1600,
}

const theme = {
	color: {
		brand1: 'hsl(214, 61%, 39%)',
		brand2: 'hsl(199, 67%, 45%)',
		brand3: 'hsl(224, 63%, 40%)',
		brand4: 'hsl(216, 67%, 60%)',
		brand5: 'hsl(215, 36%, 87%)',
		brandDarkBlue: '#1E357B',
		background: 'hsl(0, 0%, 99%)',
		white: 'hsl(0, 0%, 100%)',
		black: 'hsl(0, 0%, 0%)',
		gray1: 'hsl(0, 0%, 98%)',
		gray2: 'hsl(0, 0%, 96%)',
		gray3: 'hsl(0, 0%, 91%)',
		gray4: 'hsl(0, 0%, 86%)',
		gray5: 'hsl(0, 0%, 80%)',
		gray6: 'hsl(0, 0%, 60%)',
		gray7: 'hsl(0, 0%, 52%)',
		gray8: 'hsl(0, 0%, 43%)',
		gray9: 'hsl(0, 0%, 25%)',
		gray10: 'hsl(0, 0%, 14%)',
		gray11: 'hsl(0, 0%, 7%)',
		lightSlateGray: 'hsl(209 , 15% , 45%)',
		gold: '#FFEA80',
		success: 'hsl(152, 92%, 38%)',
		warning: 'hsl(29, 97%, 55%)',
		error: 'hsl(11, 92%, 57%)',
		notification: 'hsl(350, 87%, 55%)',
		facebook: 'hsl(214deg 89% 52%)',
		linkedin: 'hsl(210deg 89% 40%)',
		twitter: 'hsl(203, 89%, 53%)',
		instagram: '#d6249f',
		youtube: '#FF0000',
		instagramGradient: 'url(#instagramGradient)',
		border: 'rgba(0,0,0, 0.06)',
		premium: 'rgb(39, 110, 241)',
	},
	breakpoints: {
		xs: `${BREAKPOINTS.xs}px`,
		sm: `${BREAKPOINTS.sm}px`,
		md: `${BREAKPOINTS.md}px`,
		lg: `${BREAKPOINTS.lg}px`,
		xl: `${BREAKPOINTS.xl}px`,
		xxl: `${BREAKPOINTS.xxl}px`,
	},
	zIndex: {
		notification: 2200000000,
		drawer: 2150000000,
		// chat support zindex 10000001 / 2147483647
		loader: 100000,
		modal: 1000,
		stickyButton: 500,
		alertBar: 400,
		navigationMain: 300,
		cookieConsent: 200,
		logo: 150,
		navigationMobile: 100,
		loading: 50,
	},
	fontSizes: {
		xs: '0.5rem',
		s: '0.75rem',
		m: '1rem',
		l: '1.25rem',
		xl: '1.5rem',
		xxl: '1.75rem',
		xxxl: '2rem',
	},
	size: {
		headerHeight: 80,
	},
	borderRadius: {
		large: '8px',
		base: '4px',
	},
	boxShadow: {
		hover: '0 10px 26px 0 rgb(31 31 35 / 10%), 0 30px 26px 0 rgb(31 31 35 / 10%);',
	},
	transition: {
		basic: '0.2s ease',
	},
	modalWidth: {
		largeModal: 1048,
	},
}

module.exports = { theme, BREAKPOINTS }
