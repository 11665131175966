"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactInfoDiv = exports.AddNote = exports.AddNoteDot = exports.TextToLowerCaseWrapper = exports.SmallGreyTextWrapper = exports.StyledInfoAlert = exports.BoldTextWrapper = exports.HistoryItemMessageWrapper = exports.HistoryItemTitleText = exports.DropdownButtonWrapper = exports.HistoryItemTitleWrapper = exports.DrawerWrapper = exports.InputTextArea = exports.ExpandingInput = exports.TimestampWrapper = exports.Message = exports.Documents = exports.Header = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const antd_1 = require("antd");
const elements_1 = require("@src/components/elements");
const styled_breakpoints_1 = require("styled-breakpoints");
const icons_1 = require("@ant-design/icons");
const polished_1 = require("polished");
exports.Header = styled_components_1.default.div `
	display: flex;
	height: fit-content;
	align-items: center;
`;
exports.Documents = styled_components_1.default.div `
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
`;
exports.Message = styled_components_1.default.div `
	margin-top: 1rem;
	background: #ffffff;
	border: 1px solid #ecd7cc;
	border-radius: 8px;
	min-height: 200px;
	display: flex;
	padding: 1rem;
`;
exports.TimestampWrapper = styled_components_1.default.div `
	color: gray;
	white-space: nowrap;
	font-size: 0.8rem;
`;
exports.ExpandingInput = (0, styled_components_1.default)(antd_1.Input.TextArea) `
	overflow: hidden;
	resize: none;
	&& {
		transition: all 0.3s ease-out;
		height: 3.5rem;
		max-height: 3.5rem;
	}

	&:focus {
		overflow: auto;
		&& {
			height: 12rem;
			max-height: 12rem;
		}
	}
`;
exports.InputTextArea = (0, styled_components_1.default)(antd_1.Input.TextArea) `
	& {
		line-height: 1.35;
	}
`;
exports.DrawerWrapper = (0, styled_components_1.default)(antd_1.Drawer) `
	z-index: ${props => props.theme.zIndex.drawer} !important;
	transform: none !important;

	.ant-drawer-content-wrapper {
		width: 480px !important;

		${(0, styled_breakpoints_1.down)('xs')} {
			width: 100% !important;
		}
		.ant-drawer-header-title {
			display: flex;
			flex: 1;
			flex-direction: row-reverse;

			.ant-drawer-close {
				align-self: baseline;
				padding: 0;
				margin: 0;
			}
		}
	}
`;
exports.HistoryItemTitleWrapper = styled_components_1.default.div `
	display: flex;
	justify-content: space-between;
`;
exports.DropdownButtonWrapper = (0, styled_components_1.default)(elements_1.Button) `
	position: absolute;
	right: 0;
	top: -3px;
`;
exports.HistoryItemTitleText = styled_components_1.default.div `
	max-width: 85%;
	line-height: normal;
`;
exports.HistoryItemMessageWrapper = styled_components_1.default.div `
	line-height: 1.35;
	margin-top: 0.5rem;
	padding: 0.75rem;
	border: 1px solid ${({ theme }) => theme.color.gray4};
	background: ${({ theme }) => theme.color.white};
	border-radius: ${({ theme }) => theme.borderRadius.base};

	${({ isNote, theme }) => isNote &&
    (0, styled_components_1.css) `
			background: ${(0, polished_1.lighten)(0.4, theme.color.warning)};
		`}
`;
exports.BoldTextWrapper = styled_components_1.default.div `
	font-weight: bold;
	display: inline;
`;
exports.StyledInfoAlert = (0, styled_components_1.default)(antd_1.Alert) `
	background-color: #e6f7ff;
	border: 1px solid #91d5ff;
	color: rgba(0, 0, 0, 0.85);
	.anticon {
		color: #1890ff;
	}
`;
exports.SmallGreyTextWrapper = styled_components_1.default.div `
	color: ${props => props.theme.color.gray7};
	line-height: normal;
	font-size: small;
`;
exports.TextToLowerCaseWrapper = styled_components_1.default.span `
	text-transform: lowercase;
`;
exports.AddNoteDot = (0, styled_components_1.default)(icons_1.PlusCircleOutlined) `
	width: 16px;
	height: 16px;
`;
exports.AddNote = styled_components_1.default.div `
	color: ${({ theme }) => theme.color.brand1};
	cursor: pointer;
	transition: 0.1s;

	&:hover {
		color: ${({ theme }) => (0, polished_1.darken)(0.1, theme.color.brand1)};
	}
`;
exports.ContactInfoDiv = styled_components_1.default.div `
	margin-bottom: 0.25rem;
	display: inline-flex;
	align-content: center;
`;
