"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LANGUAGE_HEADER_NAME = exports.userSessionCookieName = exports.socketCookieName = exports.SupportedLanguage = void 0;
var SupportedLanguage;
(function (SupportedLanguage) {
    SupportedLanguage["en"] = "en";
    SupportedLanguage["sk"] = "sk";
    SupportedLanguage["uk"] = "uk";
})(SupportedLanguage || (exports.SupportedLanguage = SupportedLanguage = {}));
exports.socketCookieName = 'pracujSocketCookieSession';
exports.userSessionCookieName = 'connect.sid';
exports.LANGUAGE_HEADER_NAME = 'Language';
