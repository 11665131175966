"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAccount = getAccount;
exports.putAccount = putAccount;
exports.getMyResumes = getMyResumes;
exports.getAccountExistsByEmail = getAccountExistsByEmail;
exports.getMyNotifications = getMyNotifications;
exports.patchNotification = patchNotification;
exports.watchAllNotifications = watchAllNotifications;
exports.getMyNotificationTypeSettings = getMyNotificationTypeSettings;
exports.updateMyNotificationTypeSettings = updateMyNotificationTypeSettings;
exports.markOnboardingAsCompleted = markOnboardingAsCompleted;
exports.resetPassword = resetPassword;
exports.resendVerificationEmail = resendVerificationEmail;
exports.verifyAccount = verifyAccount;
exports.disableProfileProgress = disableProfileProgress;
exports.getAllAccountsCount = getAllAccountsCount;
const axios_1 = require("./clients/axios");
async function getAccount() {
    return (await axios_1.client.get('/0/auth-check', {
        validateStatus: status => status >= 200 && status <= 309,
    })).data;
}
async function putAccount(account) {
    return (await axios_1.client.put('/0/account', account)).data;
}
async function getMyResumes() {
    return (await axios_1.client.get('/0/account-resume')).data;
}
async function getAccountExistsByEmail(email) {
    try {
        const response = await axios_1.client.get('/0/account', { params: { email } });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getMyNotifications(page) {
    try {
        const response = await axios_1.client.get('/0/account/notifications', {
            params: {
                page,
            },
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function patchNotification(uuid, data) {
    try {
        const response = await axios_1.client.patch(`/0/account/notifications/${uuid}`, data);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function watchAllNotifications() {
    try {
        const response = await axios_1.client.put('/0/account/notifications/watch-all');
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getMyNotificationTypeSettings() {
    const response = await axios_1.client.get('/0/account/notifications/settings');
    return response.data;
}
async function updateMyNotificationTypeSettings(notificationTypesSettings) {
    if (!notificationTypesSettings) {
        return;
    }
    await axios_1.client.put('/0/account/notifications/settings', notificationTypesSettings);
}
async function markOnboardingAsCompleted() {
    await axios_1.client.patch('/0/account/onboarding');
}
async function resetPassword(token, password) {
    const response = await axios_1.client.put(`/0/reset-password?token=${token}`, { password });
    return response.data;
}
async function resendVerificationEmail() {
    return (await axios_1.client.post('/0/resend-verification-email', {})).data;
}
async function verifyAccount({ verificationToken }) {
    return (await axios_1.client.post(`/0/verification?token=${verificationToken}`, {})).data;
}
async function disableProfileProgress(accountId) {
    return (await axios_1.client.patch('/0/account/profile-progress', accountId)).data;
}
async function getAllAccountsCount() {
    return (await axios_1.client.get('/0/account/count-all')).data;
}
