"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBillingProfiles = getBillingProfiles;
exports.getBillingProfileById = getBillingProfileById;
exports.createBillingProfile = createBillingProfile;
exports.editBillingProfile = editBillingProfile;
exports.setDefaultBillingProfile = setDefaultBillingProfile;
exports.deleteBillingProfile = deleteBillingProfile;
const axios_1 = require("./clients/axios");
async function getBillingProfiles() {
    try {
        const response = await axios_1.client.get(`/0/company-billing-profile`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getBillingProfileById(billingProfileId) {
    try {
        const response = await axios_1.client.get(`/0/company-billing-profile/edit-billing-info`, {
            params: { billingProfileId },
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function createBillingProfile(billingProfileData) {
    try {
        const response = await axios_1.client.post(`/0/company-billing-profile`, { billingProfileData });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function editBillingProfile(billingProfileId, billingProfileData) {
    try {
        const response = await axios_1.client.patch(`/0/company-billing-profile`, { billingProfileId, billingProfileData });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function setDefaultBillingProfile(billingProfileId) {
    try {
        const response = await axios_1.client.patch(`/0/company-billing-profile/set-default-billing-profile`, {
            billingProfileId,
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function deleteBillingProfile(billingProfileId) {
    try {
        const response = await axios_1.client.delete(`/0/company-billing-profile/${billingProfileId}`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
