"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCompanyAssetGalleries = getCompanyAssetGalleries;
exports.createCompanyAssetGallery = createCompanyAssetGallery;
exports.updateCompanyAssetGallery = updateCompanyAssetGallery;
exports.deleteCompanyAssetGallery = deleteCompanyAssetGallery;
const axios_1 = require("./clients/axios");
async function getCompanyAssetGalleries(companyId) {
    const response = await axios_1.client.get('/0/company-asset-gallery', { params: companyId });
    return response.data;
}
async function createCompanyAssetGallery({ galleryTitle, assetIds, isPrimaryGallery, }) {
    const response = await axios_1.client.post('/0/company-asset-gallery', { galleryTitle, assetIds, isPrimaryGallery });
    return response.data;
}
async function updateCompanyAssetGallery({ galleryId, assetIds, galleryTitle, }) {
    const response = await axios_1.client.put(`/0/company-asset-gallery/${galleryId}`, {
        assetIds,
        galleryTitle,
    });
    return response.data;
}
async function deleteCompanyAssetGallery({ galleryId }) {
    const response = await axios_1.client.delete(`/0/company-asset-gallery/${galleryId}`);
    return response.data;
}
