import React from 'react'

const BriefcaseContractor = () => (
	<svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
			<path
				d="M11.75 10.5a.5.5 0 0 1-.5.5H.75a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h10.5a.5.5 0 0 1 .5.5ZM4 3l.25-1.75A.25.25 0 0 1 4.5 1h3a.25.25 0 0 1 .25.25L8 3M2.25 5.001h7.5M2.25 7.001h7.5M2.25 9.001h7.5"
				strokeWidth=".5"
			/>
		</g>
	</svg>
)

export default BriefcaseContractor
