"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OfferStepPaymentType = OfferStepPaymentType;
const jsx_runtime_1 = require("react/jsx-runtime");
const elements_1 = require("@src/components/elements");
const admin_context_1 = require("@src/components/pages/admin/admin-context");
const account_context_1 = require("@src/context/account-context");
const types_1 = require("@src/types");
const react_1 = __importStar(require("react"));
const ri_1 = require("react-icons/ri");
const react_intl_1 = require("react-intl");
const styled_components_1 = __importStar(require("styled-components"));
const BillingProfileForm_1 = require("../../../payments/billing-profile/BillingProfileForm");
const offer_context_1 = require("../../offer-context");
function OfferStepPaymentType({ goToNextStep, goToNextStepButtonText, isEditAndNotDuplicate }) {
    var _a, _b, _c, _d;
    const intl = (0, react_intl_1.useIntl)();
    const theme = (0, styled_components_1.useTheme)();
    const account = (0, account_context_1.useAccount)();
    const setOfferPaymentTypeValuesInContext = (0, offer_context_1.useOfferContextValueSelector)(v => v.offer.paymentType.setValues);
    const offerPaymentTypeValuesInContext = (0, offer_context_1.useOfferContextValueSelector)(v => v.offer.paymentType.values);
    const { companyInfo: company } = (0, admin_context_1.useAdminContextValue)();
    const [selectedBillingProfileId, setSelectedBillingProfileId] = (0, react_1.useState)(offerPaymentTypeValuesInContext.billingProfileId ||
        ((_b = (_a = company.companyBillingProfiles) === null || _a === void 0 ? void 0 : _a.find(cbp => cbp.isDefault)) === null || _b === void 0 ? void 0 : _b.id));
    const isNextStepDisabled = !(account === null || account === void 0 ? void 0 : account.verified) ||
        !offerPaymentTypeValuesInContext.paymentType ||
        (offerPaymentTypeValuesInContext.paymentType === types_1.OfferPaymentType.PAY_WITH_OFFER_CREDITS &&
            !company.availableCredits &&
            !isEditAndNotDuplicate);
    (0, react_1.useEffect)(() => {
        setOfferPaymentTypeValuesInContext({
            billingProfileId: selectedBillingProfileId,
        });
    }, [selectedBillingProfileId]);
    return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("h2", { className: "text-center", children: intl.formatMessage({ id: 'admin.offer_form.payment_type_choose' }) }), (0, jsx_runtime_1.jsx)("div", { className: "text-center m-t-m m-b-m", children: intl.formatMessage({ id: 'admin.offer_form.payment_type_do_you_wish_to_pay' }) }), (0, jsx_runtime_1.jsx)("div", { className: "m-t-m flex-center", children: (0, jsx_runtime_1.jsx)(elements_1.RadioGroupBox, { disabled: isEditAndNotDuplicate, onChange: e => setOfferPaymentTypeValuesInContext({ paymentType: e.target.value }), value: offerPaymentTypeValuesInContext.paymentType, defaultValue: offerPaymentTypeValuesInContext.paymentType, size: "large", boxes: [
                        {
                            label: intl.formatMessage({
                                id: 'admin.offer_form.payment_type_do_you_wish_to_pay_with_credits',
                            }),
                            value: types_1.OfferPaymentType.PAY_WITH_OFFER_CREDITS,
                            icon: (0, jsx_runtime_1.jsx)(ri_1.RiCopperCoinLine, { size: "auto" }),
                        },
                        {
                            label: intl.formatMessage({
                                id: 'admin.offer_form.payment_type_do_you_wish_to_pay_per_resume',
                            }),
                            value: types_1.OfferPaymentType.PAY_PER_APPLICATION,
                            icon: (0, jsx_runtime_1.jsx)(ri_1.RiUserStarLine, { size: "auto" }),
                        },
                    ] }) }), (0, jsx_runtime_1.jsx)("div", { className: "m-t-l m-b-s", children: (0, jsx_runtime_1.jsxs)("div", { className: "m-b-m", children: [offerPaymentTypeValuesInContext.paymentType === types_1.OfferPaymentType.PAY_WITH_OFFER_CREDITS && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!company.availableCredits && !isEditAndNotDuplicate && ((0, jsx_runtime_1.jsxs)("div", { className: "flex-center gap-m text-center", children: [(0, jsx_runtime_1.jsx)(elements_1.Alert, { type: "warning", showIcon: true, message: intl.formatMessage({ id: 'general.credits.none' }) }), (0, jsx_runtime_1.jsx)(elements_1.Button, { style: {
                                                background: theme.color.success,
                                                borderColor: theme.color.success,
                                                color: theme.color.white,
                                            }, link: "/admin/kredity", icon: (0, jsx_runtime_1.jsx)(ri_1.RiCopperCoinLine, { className: "m-r-s" }), children: intl.formatMessage({ id: 'general.credits.buy' }) })] })), !!(account === null || account === void 0 ? void 0 : account.verified) && !!company.availableCredits && ((0, jsx_runtime_1.jsx)("div", { className: "flex-center m-b-m", children: (0, jsx_runtime_1.jsx)(elements_1.Alert, { showIcon: true, type: "info", message: (0, jsx_runtime_1.jsx)("div", { children: intl.formatMessage({ id: 'admin.offer_form.payment_type_credits_available' }, { count: company.availableCredits }) }) }) }))] })), !!(account === null || account === void 0 ? void 0 : account.verified) &&
                            offerPaymentTypeValuesInContext.paymentType === types_1.OfferPaymentType.PAY_PER_APPLICATION && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", { className: "flex-center m-b-m", children: (0, jsx_runtime_1.jsx)(elements_1.Alert, { showIcon: true, type: "info", message: (0, jsx_runtime_1.jsx)("div", { children: intl.formatMessage({ id: 'admin.offer_form.payment_type_price_info' }, {
                                                a: chunks => ((0, jsx_runtime_1.jsx)("a", { target: "_blank", href: "/magazin/novy-model-platenia-pre-zamestnavate-ov-platba-iba-za-prijate-zivotopisy", children: chunks })),
                                            }) }) }) }), (0, jsx_runtime_1.jsxs)("div", { className: "flex-col", children: [(0, jsx_runtime_1.jsx)("h2", { className: "m-b-m m-t-m", children: intl.formatMessage({ id: 'admin.offer_form.billing_information' }) }), (((_c = company.companyBillingProfiles) === null || _c === void 0 ? void 0 : _c.length) || 0) > 1 && ((0, jsx_runtime_1.jsxs)("div", { className: "m-b-m m-t-m", children: [(0, jsx_runtime_1.jsx)(BillingProfileLabel, { children: intl.formatMessage({ id: 'admin.order.choose_billing_profile' }) }), (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(BillingProfileSelect, { options: (_d = company.companyBillingProfiles) === null || _d === void 0 ? void 0 : _d.map(cbp => ({
                                                            label: `${cbp.name}${cbp.isDefault
                                                                ? ` (${intl.formatMessage({
                                                                    id: 'admin.payments.default_profile',
                                                                })})`
                                                                : ''}`,
                                                            value: cbp.id,
                                                        })), value: selectedBillingProfileId, onSelect: value => {
                                                            setSelectedBillingProfileId(value);
                                                        } }) }), (0, jsx_runtime_1.jsx)(elements_1.Separator, {})] })), selectedBillingProfileId && ((0, jsx_runtime_1.jsx)(BillingProfileForm_1.BillingProfileForm, { billingProfileId: selectedBillingProfileId, onSubmit: goToNextStep, ActionsBlock: ({ isLoading }) => ((0, jsx_runtime_1.jsx)("div", { className: "flex-center gap-l m-b-l", children: (0, jsx_runtime_1.jsx)(elements_1.Button, { loading: isLoading, type: "primary", htmlType: "submit", disabled: isNextStepDisabled, children: goToNextStepButtonText }) })) }))] })] }))] }) }), !(account === null || account === void 0 ? void 0 : account.verified) && ((0, jsx_runtime_1.jsx)("div", { className: "flex-center m-b-m m-t-m", children: (0, jsx_runtime_1.jsx)(elements_1.Alert, { showIcon: true, type: "warning", message: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "companies.cvs_account_not_verified" }) }) })), offerPaymentTypeValuesInContext.paymentType !== types_1.OfferPaymentType.PAY_PER_APPLICATION && ((0, jsx_runtime_1.jsx)("div", { className: "flex-center gap-l", children: (0, jsx_runtime_1.jsx)(elements_1.Button, { type: "primary", disabled: isNextStepDisabled, onClick: goToNextStep, children: goToNextStepButtonText }) }))] }));
}
const BillingProfileLabel = styled_components_1.default.label `
	position: relative;
	display: inline-flex;
	align-items: center;
	max-width: 100%;
	height: 32px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
`;
const BillingProfileSelect = (0, styled_components_1.default)(elements_1.Select) `
	min-width: 50%;
`;
