"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateWorkingTimeType = exports.translateType = void 0;
const types_1 = require("@src/types");
const translateType = (duration_value, duration_type, intl) => {
    const specifyType = {
        one: {
            day: intl.formatMessage({
                id: 'offer_detail.details_model.one_day',
            }),
            week: intl.formatMessage({
                id: 'offer_detail.details_model.one_week',
            }),
            month: intl.formatMessage({
                id: 'offer_detail.details_model.one_month',
            }),
            year: intl.formatMessage({
                id: 'offer_detail.details_model.one_year',
            }),
        },
        twoToFive: {
            day: intl.formatMessage({
                id: 'offer_detail.details_model.two_days',
            }),
            week: intl.formatMessage({
                id: 'offer_detail.details_model.two_weeks',
            }),
            month: intl.formatMessage({
                id: 'offer_detail.details_model.two_months',
            }),
            year: intl.formatMessage({
                id: 'offer_detail.details_model.two_years',
            }),
        },
        fiveAndMore: {
            day: intl.formatMessage({
                id: 'offer_detail.details_model.days',
            }),
            week: intl.formatMessage({
                id: 'offer_detail.details_model.weeks',
            }),
            month: intl.formatMessage({
                id: 'offer_detail.details_model.months',
            }),
            year: intl.formatMessage({
                id: 'offer_detail.details_model.years',
            }),
        },
    };
    if (duration_value >= 5) {
        return specifyType.fiveAndMore[`${duration_type}`];
    }
    if (duration_value >= 2) {
        return specifyType.twoToFive[`${duration_type}`];
    }
    return specifyType.one[`${duration_type}`];
};
exports.translateType = translateType;
const translateWorkingTimeType = ({ type, intl }) => {
    const language = intl.locale;
    return types_1.WorkingTimeTypeMap[language][type];
};
exports.translateWorkingTimeType = translateWorkingTimeType;
