"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressSuggestionsTypes = void 0;
exports.getAddressSuggestions = getAddressSuggestions;
exports.getAddressDetail = getAddressDetail;
const axios_1 = require("./clients/axios");
var AddressSuggestionsTypes;
(function (AddressSuggestionsTypes) {
    AddressSuggestionsTypes["Addresses"] = "addresses";
    AddressSuggestionsTypes["Cities"] = "cities";
})(AddressSuggestionsTypes || (exports.AddressSuggestionsTypes = AddressSuggestionsTypes = {}));
async function getAddressSuggestions(searchValue, country) {
    const response = await axios_1.client.get(`/0/address/suggestions`, {
        params: { value: searchValue, country },
    });
    return response.data;
}
async function getAddressDetail(googlePlaceId) {
    const response = await axios_1.client.get('/0/address/details', {
        params: {
            googlePlaceId,
        },
    });
    return response.data;
}
