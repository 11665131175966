"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCdnImageUrlForAccountType = getCdnImageUrlForAccountType;
const get_cdn_image_url_1 = require("@shared/get-cdn-image-url");
const types_1 = require("@src/types");
function getCdnImageUrlForAccountType(imageSrc, accountType, transformation) {
    if (!imageSrc) {
        return null;
    }
    const cdnFolder = accountType === types_1.AccountType.company ? 'company' : 'account';
    return (0, get_cdn_image_url_1.getCdnImageUrl)({
        file: `web/${cdnFolder}/${imageSrc}`,
        transformation,
    });
}
