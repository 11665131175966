"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendInquiryNotification = sendInquiryNotification;
const axios_1 = require("./clients/axios");
async function sendInquiryNotification({ plan, contact }) {
    try {
        const response = await axios_1.client.post('/0/inquiry/send-notification', { plan, contact });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
