"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertMaxTagCount = exports.SearchBarItemTitle = exports.SearchBar = exports.Search = exports.ButtonWrapper = exports.SearchBarItem = exports.LocationPinIcon = exports.SearchIcon = exports.SelectWrapper = exports.MainFilterSearchWrapper = void 0;
const icons_1 = require("@ant-design/icons");
const elements_1 = require("@src/components/elements");
const styled_components_1 = __importStar(require("styled-components"));
const styled_breakpoints_1 = require("styled-breakpoints");
exports.MainFilterSearchWrapper = styled_components_1.default.div `
	padding: 0;
	${(0, styled_breakpoints_1.up)('lg')} {
		padding: 0 5rem;
	}
	.ant-select-selector {
		padding: 0.25rem 0.25rem 0.25rem 2rem;
		border-radius: 0 !important;
		min-height: 3rem;
	}
`;
exports.SelectWrapper = styled_components_1.default.div `
	position: relative;
	.ant-select-selection-placeholder {
		padding-left: 1.75rem;
		text-align: left;
	}
`;
exports.SearchIcon = (0, styled_components_1.default)(icons_1.SearchOutlined) `
	position: absolute;
	color: ${({ theme }) => theme.color.black};
	z-index: 2;
	top: 30%;
	left: 8px;
	margin-right: 0.2rem;
	svg {
		width: 18px;
		height: 18px;
	}
`;
exports.LocationPinIcon = (0, styled_components_1.default)(elements_1.Icon) `
	position: absolute;
	color: black;
	z-index: 2;
	top: 30%;
	left: 8px;
	margin-right: 0.2rem;
	svg {
		width: 18px;
	}
`;
exports.SearchBarItem = styled_components_1.default.div `
	flex: 1;
	width: 50%;

	${props => props.hasNegativeMargin &&
    (0, styled_components_1.css) `
			margin-left: -1px;
		`}

	${(0, styled_breakpoints_1.down)('md')} {
		margin-left: 0;
		width: 100%;
	}
`;
exports.ButtonWrapper = (0, styled_components_1.default)(elements_1.Button) `
	&& {
		width: 100%;
		height: 3rem;
		border-radius: 0;
		display: flex;
		justify-content: center;
	}
`;
exports.Search = styled_components_1.default.div `
	margin-top: 24px;
	margin-left: -1px;
	flex: 0 0 auto;
	align-self: flex-start;

	${(0, styled_breakpoints_1.down)('md')} {
		margin-left: 0;
		width: 100%;
	}
`;
exports.SearchBar = styled_components_1.default.div `
	flex: 1;
	display: flex;

	${(0, styled_breakpoints_1.down)('md')} {
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
	}
`;
exports.SearchBarItemTitle = styled_components_1.default.div `
	text-align: left;
	font-size: 1rem;
`;
exports.AlertMaxTagCount = (0, styled_components_1.default)(elements_1.Alert) `
	border-top: none;
	border-radius: 0 0 4px 4px;
`;
