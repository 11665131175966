"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBoostPeriodOptions = getBoostPeriodOptions;
function getBoostPeriodOptions(dayCountUntilDeadline) {
    const fullWeekCountUntilDeadline = Math.floor(dayCountUntilDeadline / 7);
    const fullPeriodOption = {
        isFullPeriodOption: true,
        boostDayCount: dayCountUntilDeadline,
    };
    const periodOptions = new Array(fullWeekCountUntilDeadline + 1)
        .fill(null)
        .map((_, index, array) => {
        const isLast = index === array.length - 1;
        if (isLast) {
            return fullPeriodOption;
        }
        return {
            isFullPeriodOption: false,
            boostDayCount: (index + 1) * 7,
        };
    })
        .reverse();
    return periodOptions.length > 3 ? [periodOptions[0], ...periodOptions.slice(-2)] : periodOptions;
}
