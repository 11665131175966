import React from 'react'

const IconBriefcase = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<title>briefcase</title>
		<path d="M3,22.5H21a3,3,0,0,0,3-3V8.5a3,3,0,0,0-3-3H17.1a.25.25,0,0,1-.248-.219l-.144-1.153A3,3,0,0,0,13.734,1.5H10.266A3,3,0,0,0,7.289,4.128L7.145,5.281A.25.25,0,0,1,6.9,5.5H3a3,3,0,0,0-3,3v11A3,3,0,0,0,3,22.5ZM9.273,4.376a1,1,0,0,1,.993-.876h3.468a1,1,0,0,1,.993.876l.105.844a.25.25,0,0,1-.248.28H9.416a.25.25,0,0,1-.248-.28ZM5.25,11A.75.75,0,0,1,6,10.25H18a.75.75,0,0,1,.75.75v.5a.75.75,0,0,1-.75.75H6a.75.75,0,0,1-.75-.75Zm0,5.5A.75.75,0,0,1,6,15.75H18a.75.75,0,0,1,.75.75V17a.75.75,0,0,1-.75.75H6A.75.75,0,0,1,5.25,17Z" />
	</svg>
)

export default IconBriefcase
