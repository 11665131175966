import React from 'react'
import Spinner from './spinner'

const LoadingWrapper = function (props) {
	return (
		<div className={props.isLoading ? props.wrapperClassNames : props.className}>
			<div className="loading-wrapper">
				{props.isLoading && (
					<div className="loading-wrapper__spinner-area">
						<Spinner />
					</div>
				)}
				<div className="loading-wrapper__content">{!props.isLoading && props.children}</div>
			</div>
		</div>
	)
}

export default LoadingWrapper
