"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAppQuery = useAppQuery;
const react_query_1 = require("react-query");
const react_intl_1 = require("react-intl");
const antd_1 = require("antd");
function useAppQuery(queryKey, queryFn, options) {
    const intl = (0, react_intl_1.useIntl)();
    return (0, react_query_1.useQuery)(queryKey, queryFn, {
        onError: (options === null || options === void 0 ? void 0 : options.onError)
            ? options === null || options === void 0 ? void 0 : options.onError
            : error => {
                console.error(error);
                antd_1.notification.error({
                    message: intl.formatMessage({
                        id: 'general.error',
                    }),
                    description: intl.formatMessage({
                        id: 'general.something_went_wrong',
                    }),
                });
            },
        ...options,
    });
}
