import React from 'react'
import ReactDOM from 'react-dom'
import App from './app/index'
import AppWrapper from './App'

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate
renderMethod(
	<AppWrapper>
		<App />
	</AppWrapper>,
	document.getElementById('root')
)

if (module.hot) {
	module.hot.accept()
}
