"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCompanyPremiumProfileHeaderInfo = setCompanyPremiumProfileHeaderInfo;
exports.setCompanyPremiumProfileMainInfo = setCompanyPremiumProfileMainInfo;
const axios_1 = require("./clients/axios");
async function setCompanyPremiumProfileHeaderInfo({ companyPremiumProfileInfo, shouldDeleteTitleImage, shouldDeleteCompanyImage, shouldSetCompanyImage, shouldSetTitleImage, companyImage, companyTitleImage, }) {
    const premiumProfileDataForForm = {
        companyPremiumProfileInfo,
        shouldDeleteTitleImage,
        shouldDeleteCompanyImage,
        shouldSetCompanyImage,
        shouldSetTitleImage,
    };
    const premiumProfileForm = new FormData();
    Object.keys(premiumProfileDataForForm).forEach(key => {
        const value = premiumProfileDataForForm[key];
        if (key === 'companyPremiumProfileInfo') {
            premiumProfileForm.append(key, JSON.stringify(value));
        }
        else {
            premiumProfileForm.append(key, value);
        }
    });
    if (shouldSetCompanyImage && companyImage) {
        premiumProfileForm.append('companyImage', companyImage);
    }
    if (shouldSetTitleImage && companyTitleImage) {
        premiumProfileForm.append('companyTitleImage', companyTitleImage);
    }
    await axios_1.client.put('/0/company-premium-profile/header', premiumProfileForm);
}
async function setCompanyPremiumProfileMainInfo(companyPremiumProfileInfo) {
    await axios_1.client.put('/0/company-premium-profile/main', companyPremiumProfileInfo);
}
