"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentInfoKeys = exports.PaymentMethod = void 0;
var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["credit_card"] = "card";
    PaymentMethod["bank_transfer"] = "bank";
})(PaymentMethod || (exports.PaymentMethod = PaymentMethod = {}));
var PaymentInfoKeys;
(function (PaymentInfoKeys) {
    PaymentInfoKeys["iban"] = "iban";
    PaymentInfoKeys["variable_symbol"] = "variable_symbol";
    PaymentInfoKeys["payment_due"] = "payment_due";
    PaymentInfoKeys["total_sum"] = "total_sum";
})(PaymentInfoKeys || (exports.PaymentInfoKeys = PaymentInfoKeys = {}));
