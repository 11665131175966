"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionSource = exports.ScheduledJobType = void 0;
var ScheduledJobType;
(function (ScheduledJobType) {
    ScheduledJobType["cardSubscriptionPayment"] = "cardSubscriptionPayment";
    ScheduledJobType["cardSubscriptionPaymentCorrection"] = "cardSubscriptionPaymentCorrection";
    ScheduledJobType["bankTransferSubscriptionPayment"] = "bankTransferSubscriptionPayment";
    ScheduledJobType["deactivateCompanyPremiumFeatures"] = "deactivateCompanyPremiumFeatures";
})(ScheduledJobType || (exports.ScheduledJobType = ScheduledJobType = {}));
var TransactionSource;
(function (TransactionSource) {
    TransactionSource["recurring"] = "recurring";
    TransactionSource["unscheduled"] = "unscheduled";
    TransactionSource["recurring_first"] = "recurring_first";
    TransactionSource["moto"] = "moto";
})(TransactionSource || (exports.TransactionSource = TransactionSource = {}));
