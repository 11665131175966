import actionNames from '../actions'

const defaultState = {
	cookieConsent: true,
	accountProgressNotice: true,
	image: null,
	loading: false,
}

export default function app(state = defaultState, action) {
	switch (action.type) {
		case actionNames.cookieConsentSuccess().type:
			return { ...state, cookieConsent: true }

		case actionNames.cookieConsentError().type:
			return { ...state, cookieConsent: false }

		case actionNames.hideAccountProgressNotice().type:
			return { ...state, accountProgressNotice: false }

		default:
			return state
	}
}
