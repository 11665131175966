"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkillTagGroupTitle = exports.LanguageLevel = exports.LanguageName = exports.DriversLicence = exports.JobExperienceBlock = exports.SocialLink = exports.SocialMediaBlock = exports.FullName = exports.ProfileImage = exports.ProfileImageBlock = exports.ContactInfoTitle = exports.ContactInfo = exports.BasicInfo = exports.LeftColumn = exports.RightColumn = exports.MainCVContent = exports.ImageTextWrapper = exports.LowerCasedTextWrapper = exports.CapitalizedSpan = exports.MainTextWrapper = exports.DateDiv = exports.PositionName = exports.TitleHeading = exports.Header = exports.SectionTitle = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const TOP_SECTION_HEIGHT_LG = '150px';
const TOP_SECTION_HEIGHT_SM = '100px';
exports.SectionTitle = styled_components_1.default.h3 `
	font-size: ${props => props.theme.fontSizes.m};
	font-weight: 500;
	color: ${props => props.theme.color.brandDarkBlue};
	margin-top: ${props => props.theme.fontSizes.m};
	margin-bottom: ${props => props.theme.fontSizes.m};
`;
exports.Header = styled_components_1.default.div `
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
`;
// Experience
exports.TitleHeading = styled_components_1.default.h2 `
	font-size: ${props => props.theme.fontSizes.m};
	color: ${props => props.theme.color.gray7};
	font-style: italic;
	font-weight: bold;
	padding: 0 0 0.25rem 0;
`;
exports.PositionName = styled_components_1.default.div `
	font-weight: bold;
	font-size: ${props => props.theme.fontSizes.m};
	color: ${props => props.theme.color.brandDarkBlue};
	line-height: 1;
	padding: 0 0 0.25rem;
`;
exports.DateDiv = styled_components_1.default.div `
	align-self: flex-start;
	flex: 0 0 auto;
	width: 140px;
	color: ${props => props.theme.color.gray6};
	padding-bottom: 0.25rem;
`;
exports.MainTextWrapper = styled_components_1.default.div `
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: ${props => props.theme.color.gray8};
`;
exports.CapitalizedSpan = styled_components_1.default.span `
	text-transform: capitalize;
`;
exports.LowerCasedTextWrapper = styled_components_1.default.span `
	text-transform: lowercase;
`;
exports.ImageTextWrapper = styled_components_1.default.div `
	display: flex;
	align-items: center;

	${({ belowMd }) => belowMd &&
    (0, styled_components_1.css) `
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		`}
`;
exports.MainCVContent = styled_components_1.default.div `
	display: flex;

	${({ belowSm }) => belowSm &&
    (0, styled_components_1.css) `
			flex-direction: column;
		`}
`;
exports.RightColumn = styled_components_1.default.div `
	flex: 7;
	padding: 0 0 0 1rem;

	${({ belowSm }) => belowSm &&
    (0, styled_components_1.css) `
			padding: 0;
		`}
`;
exports.LeftColumn = styled_components_1.default.div `
	flex: 3;
	padding: 0 1rem 0 0;
	border-right: 1px solid ${props => props.theme.color.border};

	${({ belowSm }) => belowSm &&
    (0, styled_components_1.css) `
			border-right: none;
			margin-bottom: 1rem;
			padding: 0;
		`}
`;
exports.BasicInfo = styled_components_1.default.div `
	display: flex;
	height: fit-content;

	${({ belowMd }) => belowMd &&
    (0, styled_components_1.css) `
			flex-direction: column-reverse;
		`}
`;
exports.ContactInfo = styled_components_1.default.div `
	min-width: 200px;
	padding: 1rem;

	margin-left: ${({ noMargin }) => !noMargin && '1rem'};
	${({ noMargin }) => noMargin &&
    (0, styled_components_1.css) `
			width: 100%;
		`};
	background: ${props => props.theme.color.gray1};
	color: ${props => props.theme.color.gray8};
	font-size: ${props => props.theme.fontSizes.s};
	border-radius: ${props => props.theme.borderRadius.base};
	display: flex;
	flex-direction: column;
	min-height: ${TOP_SECTION_HEIGHT_LG};
	max-height: fit-content;

	${({ belowMd, noMargin }) => belowMd &&
    (0, styled_components_1.css) `
			min-height: ${TOP_SECTION_HEIGHT_SM};
			margin-left: 0;

			${!noMargin &&
        (0, styled_components_1.css) `
				margin-bottom: 1rem;
			`}
		`}
`;
exports.ContactInfoTitle = styled_components_1.default.div `
	color: ${props => props.theme.color.gray8};
	font-weight: bold;
`;
exports.ProfileImageBlock = styled_components_1.default.div `
	height: auto;
	color: ${props => props.theme.color.black};
	border-radius: ${props => props.theme.borderRadius.base} ${props => props.theme.borderRadius.base} 0 0;
	background: ${({ theme }) => theme.color.gray1};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 1rem;
`;
exports.ProfileImage = styled_components_1.default.img `
	margin: 1rem 1rem 0;
	max-width: calc(100% - 2rem); // depends on margin from the sides
	max-height: ${TOP_SECTION_HEIGHT_LG};
	object-fit: contain;
	padding: 2px;
	background: white;
	width: min-content;

	border: 1px solid ${({ theme }) => theme.color.gray4};
	border-radius: ${props => props.theme.borderRadius.base};

	${({ belowSm }) => belowSm &&
    (0, styled_components_1.css) `
			max-height: ${TOP_SECTION_HEIGHT_SM};
		`}
`;
exports.FullName = styled_components_1.default.div `
	padding: 1rem 0 2rem;
	color: ${({ theme }) => theme.color.brandDarkBlue};
	font-size: ${props => props.theme.fontSizes.xl};
	font-weight: bold;
	text-align: center;

	${({ belowSm }) => belowSm &&
    (0, styled_components_1.css) `
			font-size: ${props => props.theme.fontSizes.l};
		`}
`;
exports.SocialMediaBlock = styled_components_1.default.div `
	padding: 1rem 1rem 1px;
	background: ${props => props.theme.color.gray1};
	color: ${props => props.theme.color.gray8};
	border-radius: ${props => props.theme.borderRadius.base};
	font-size: ${props => props.theme.fontSizes.s};

	${({ belowMd }) => belowMd &&
    (0, styled_components_1.css) `
			display: flex;
			justify-content: center;
		`}
`;
exports.SocialLink = styled_components_1.default.a `
	display: flex;
	align-items: flex-start;
	margin-bottom: 0.75rem;

	svg {
		width: 1.25rem;
		height: 1.25rem;
		margin-right: 0.5rem;
	}

	&:hover {
		color: ${props => props.theme.color.brand3} !important;
	}
`;
exports.JobExperienceBlock = styled_components_1.default.div ``;
exports.DriversLicence = styled_components_1.default.div `
	background: ${props => props.theme.color.gray1};
	color: ${props => props.theme.color.gray8};
	font-weight: bold;
	font-size: ${props => props.theme.fontSizes.m};
	margin-right: 0.5rem;
	border-radius: ${props => props.theme.borderRadius.base};

	display: flex;
	align-items: center;
	justify-content: center;

	width: 32px;
	height: 32px;
`;
exports.LanguageName = styled_components_1.default.div `
	align-self: flex-start;
	flex: 0 0 auto;
	width: 140px;
	color: ${props => props.theme.color.gray8};
	padding-bottom: 0.25rem;
`;
exports.LanguageLevel = styled_components_1.default.div `
	color: ${props => props.theme.color.gray8};
`;
exports.SkillTagGroupTitle = styled_components_1.default.div `
	margin-bottom: 0.5rem;
	font-size: ${props => props.theme.fontSizes.m};
	font-weight: 500;
	color: ${({ theme }) => theme.color.brandDarkBlue};
`;
