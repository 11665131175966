"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationImageOrIconWrapper = exports.ContentWrapper = exports.Content = exports.NotificationItemWrapper = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const polished_1 = require("polished");
const elements_1 = require("@src/components/elements");
exports.NotificationItemWrapper = (0, styled_components_1.default)(elements_1.Link) `
	padding: 0.6rem 3rem 0.6rem 1rem;
	width: 100%;
	font-size: 0.9rem;
	display: flex;
	align-items: flex-start;
	transition: all 0.2s ease;
	border-radius: 4px;
	position: relative;

	& + & {
		margin: 0.3rem 0 0 0;
	}

	&,
	&:link,
	&:visited,
	&:focus,
	&:hover,
	&:active {
		color: ${props => props.theme.color.brand1};
	}

	& > * + * {
		margin-left: 0.7rem;
	}
	&:hover,
	&:active {
		background: ${props => (0, polished_1.transparentize)(0.8, props.theme.color.brand4)};
	}
	span {
		margin-top: 0.2rem;
		flex: 1;
	}

	${props => props.isNew &&
    (0, styled_components_1.css) `
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				right: 0.5rem;
				width: 0.7rem;
				height: 0.7rem;
				background: ${props.theme.color.brand2};
				margin-top: -0.35rem;
				border-radius: 1rem;
			}
		`}
`;
exports.Content = styled_components_1.default.div `
	color: ${props => props.theme.color.gray6};
	font-weight: normal;

	${props => props.isNew &&
    (0, styled_components_1.css) `
			color: ${props.theme.color.brand1};
		`}
`;
exports.ContentWrapper = styled_components_1.default.div `
	width: 100%;
	text-transform: none;
`;
exports.NotificationImageOrIconWrapper = styled_components_1.default.div `
	width: 2rem;
	text-align: center;
	margin-top: 0.3rem;
`;
