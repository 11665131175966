"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarouselArrowContainer = exports.CarouselDotsContainer = exports.CarouselDot = exports.AssetImg = exports.AssetContainer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
exports.AssetContainer = styled_components_1.default.div `
	padding: 0 1px; // This must be here to hide edges of neighbouring images.
	height: 420px;
	background-color: ${props => props.theme.color.white};
	width: 100%;
	display: flex !important;
	justify-content: center;
`;
exports.AssetImg = styled_components_1.default.img `
	width: 100%;
	height: 100%;
	object-fit: contain;
`;
exports.CarouselDot = styled_components_1.default.div `
	border-radius: 100%;
	height: 12px;
	width: 12px;
	background-color: ${props => props.theme.color.gray4};
	cursor: pointer;
	${props => props.shouldBeHighlighted &&
    (0, styled_components_1.css) `
			background-color: ${({ theme }) => theme.color.brand1};
		`}
`;
exports.CarouselDotsContainer = styled_components_1.default.div `
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 1rem;
	gap: 0.5rem;
`;
exports.CarouselArrowContainer = styled_components_1.default.div `
	position: absolute;
	top: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	background-color: ${props => props.theme.color.gray2};
	border-radius: 100%;
	z-index: 1;
	font-size: 1rem;
	cursor: pointer;
	transform: translateY(-50%);
	${props => props.position === 'left'
    ? (0, styled_components_1.css) `
					left: 1rem;
			  `
    : (0, styled_components_1.css) `
					right: 1rem;
			  `}
`;
