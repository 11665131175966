"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteLinkedScheduledJobsToPaymentSubscription = deleteLinkedScheduledJobsToPaymentSubscription;
const axios_1 = require("./clients/axios");
async function deleteLinkedScheduledJobsToPaymentSubscription({ companyPaymentSubscriptionId, }) {
    const response = await axios_1.client.delete('/0/scheduled-job', {
        params: {
            companyPaymentSubscriptionId,
        },
    });
    return response.data;
}
