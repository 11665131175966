"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CouponResponses = void 0;
var CouponResponses;
(function (CouponResponses) {
    CouponResponses["VALID"] = "VALID";
    CouponResponses["INVALID"] = "INVALID";
    CouponResponses["NOT_FOUND"] = "NOT_FOUND";
    CouponResponses["ALREADY_USED"] = "ALREADY_USED";
    CouponResponses["EXPIRED"] = "EXPIRED";
    CouponResponses["NOT_APPLICABLE"] = "NOT_APPLICABLE";
    CouponResponses["FEATURE_ACTIVE_NO_NEED_TO_ACTIVATE"] = "FEATURE_ACTIVE_NO_NEED_TO_ACTIVATE";
})(CouponResponses || (exports.CouponResponses = CouponResponses = {}));
