"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchiveOfferModal = ArchiveOfferModal;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const react_intl_1 = require("react-intl");
const lodash_1 = require("lodash");
const elements_1 = require("@src/components/elements");
const types_1 = require("@src/types");
const get_product_pricing_1 = require("@src/shared/get-product-pricing");
const useAppMutation_1 = require("@src/hooks/useAppMutation");
const offer_1 = require("@src/api/offer");
const react_query_1 = require("react-query");
const queries_1 = require("@src/types/queries");
const ArchiveOfferModal_style_1 = require("./ArchiveOfferModal.style");
function ArchiveOfferModal({ offer, close, isOpen }) {
    const queryClient = (0, react_query_1.useQueryClient)();
    const [isSuccessfullyArchived, setIsSuccessfullyArchived] = (0, react_1.useState)(false);
    const { mutateAsync, isLoading } = (0, useAppMutation_1.useAppMutation)((id) => (0, offer_1.archiveOffer)(id), {
        onSuccess: () => {
            setIsSuccessfullyArchived(true);
            queryClient.invalidateQueries(queries_1.Queries.getMyOffers);
            queryClient.invalidateQueries(queries_1.Queries.getCompany);
        },
    });
    const uniqueApplications = (0, lodash_1.uniqBy)((offer === null || offer === void 0 ? void 0 : offer.offerApplications) || [], oa => oa.accountId);
    (0, react_1.useEffect)(() => {
        if (isSuccessfullyArchived &&
            ((offer === null || offer === void 0 ? void 0 : offer.paymentType) === types_1.OfferPaymentType.PAY_WITH_OFFER_CREDITS || uniqueApplications.length === 0)) {
            close();
        }
    }, [isSuccessfullyArchived, offer, uniqueApplications, close]);
    (0, react_1.useEffect)(() => {
        // cleanup the modal is always rendering even when closed
        if (!isOpen) {
            setIsSuccessfullyArchived(false);
        }
    }, [isOpen]);
    return ((0, jsx_runtime_1.jsx)(elements_1.Modal, { visible: isOpen, onOk: () => {
            if (!isSuccessfullyArchived) {
                mutateAsync(offer.id);
            }
            else {
                close();
            }
        }, onCancel: close, confirmLoading: isLoading, cancelButtonProps: { loading: isLoading }, children: (0, jsx_runtime_1.jsxs)("div", { className: "flex-center flex-col text-center m-l", children: [!isSuccessfullyArchived && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(ArchiveOfferModal_style_1.ArchiveOfferModalHeader, { className: "flex-center m-b-m m-t-m", children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "admin.offers.archive_offer_dialog.are_you_sure_to_archive" }) }), (0, jsx_runtime_1.jsx)(ArchiveOfferModal_style_1.ArchiveOfferModalOfferTitle, { className: "flex-center m-b-l", children: offer === null || offer === void 0 ? void 0 : offer.title }), (0, jsx_runtime_1.jsx)("div", { className: "m-b-l flex-center", children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "admin.offers.archive_offer_dialog.you_will_not_lose_data" }) }), (offer === null || offer === void 0 ? void 0 : offer.paymentType) === types_1.OfferPaymentType.PAY_PER_APPLICATION && uniqueApplications.length > 0 && ((0, jsx_runtime_1.jsx)(elements_1.Alert, { showIcon: true, description: (0, jsx_runtime_1.jsx)("span", { style: { whiteSpace: 'pre-line' }, children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "admin.offer_archive_invoice_generation", values: {
                                        price: (0, get_product_pricing_1.getProductPricing)({
                                            productCount: uniqueApplications.length,
                                            productId: types_1.ProductId.offerCV,
                                        }).totalPriceWithoutVat,
                                        strong: chunks => (0, jsx_runtime_1.jsxs)("strong", { children: [...chunks] }),
                                    } }) }), type: "info" }))] })), isSuccessfullyArchived && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(ArchiveOfferModal_style_1.ArchiveOfferModalHeader, { className: "flex-center m-b-m m-t-m", children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "admin.offer_archive_invoice_was_generated" }) }), (0, jsx_runtime_1.jsx)("img", { style: { width: '160px' }, src: "/media/illustrations/streamline-icon-file-binder.svg", alt: "" }), (0, jsx_runtime_1.jsx)(elements_1.Button, { link: "/admin/platby", children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "admin.offer_archive_go_to_invoices" }) })] }))] }) }));
}
