"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseQuery = parseQuery;
function parseQuery(path) {
    const queryToParse = path.substring(path.indexOf('?') + 1);
    const query = queryToParse.split(/&|=/).reduce((acc, param, i, queries) => {
        if (i % 2 === 0) {
            acc[param] = queries[i + 1];
        }
        return acc;
    }, {});
    return query;
}
