"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkillTagsWrapper = exports.TextSection = exports.TagWrapper = exports.TagsWrapper = exports.Headline = exports.MainHeadline = exports.Section = exports.HeadlineSubtitle = void 0;
const styled_components_1 = __importStar(require("styled-components"));
exports.HeadlineSubtitle = styled_components_1.default.h3 `
	margin: 1.5rem 0 1rem 0;
	color: ${({ theme }) => theme.color.brand1};
`;
exports.Section = styled_components_1.default.div `
	border-top: 1px solid ${({ theme }) => theme.color.gray4};
	padding: 1rem 0;
	margin: 1rem 0;
	${({ isBlank }) => isBlank &&
    (0, styled_components_1.css) `
			border: 0;
			margin-top: 0;
			padding-top: 0;
		`}
`;
exports.MainHeadline = styled_components_1.default.h1 `
  color: ${({ theme }) => theme.color.brand1}
  margin: 0;
`;
exports.Headline = styled_components_1.default.h2 `
	margin: 0;
`;
exports.TagsWrapper = styled_components_1.default.ul `
	list-style: none;
	margin: 1rem 0 0 0;
	padding: 0;
`;
exports.TagWrapper = styled_components_1.default.li `
	display: inline-block;
	margin: 0 0.5rem 0.5rem 0;
	padding: 0.3rem 0.8rem;
	border-radius: ${({ theme }) => theme.borderRadius.base};
	background: ${({ theme }) => theme.color.gray4};
`;
exports.TextSection = styled_components_1.default.div `
	margin-top: 0.5rem;
	-ms-word-break: break-all;
	-ms-word-wrap: break-all;
	-webkit-word-break: break-word;
	-webkit-word-wrap: break-word;
	word-break: break-word;
	word-wrap: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
	& > * {
		margin: 0;
	}
	& > p:empty {
		min-height: 20px;
	}
	${({ flex }) => flex &&
    (0, styled_components_1.css) `
			display: flex;
		`}
`;
exports.SkillTagsWrapper = styled_components_1.default.div `
	margin-top: 1.5rem;
`;
