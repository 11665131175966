"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiDragCard = exports.StyledBadge = exports.RoundedSquaredAvatar = exports.TransparentButton = exports.NameWrapper = exports.ImageWrapper = exports.CheckIconWrapper = exports.MainBlock = exports.ApplicationContent = exports.Application = void 0;
// @ts-nocheck
const styled_components_1 = __importStar(require("styled-components"));
const elements_1 = require("@src/components/elements");
const colorSelected = '#1890ff';
exports.Application = styled_components_1.default.div `
	border: 1px solid ${props => props.theme.color.gray5};
	margin: 0.5rem;
	width: 168px;
	min-width: 168px;
	height: 85px;
	border-radius: ${props => props.theme.borderRadius.base};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0.5rem;
	background: ${props => props.theme.color.white};
	transition: 0.1s;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);

	&:hover {
		border: 1px solid ${props => props.theme.color.brand2};
	}

	${({ isDragging }) => isDragging &&
    (0, styled_components_1.css) `
			border: 1px solid ${props => props.theme.color.brand2};
			box-shadow: -1px 4px 10px ${props => props.theme.color.gray5};
		`}

	${({ isCardSelected }) => isCardSelected &&
    (0, styled_components_1.css) `
			border: 1px solid ${colorSelected};

			&:hover {
				border: 1px solid ${colorSelected};
			}
		`}
	${({ isDragDisabled }) => isDragDisabled &&
    (0, styled_components_1.css) `
			background: ${props => props.theme.color.gray1};
			border: 1px solid ${props => props.theme.color.gray2};
			&:hover {
				border: 1px solid ${props => props.theme.color.gray2};
			}
		`}
	${({ isGhosting }) => isGhosting &&
    (0, styled_components_1.css) `
			display: none;
		`}
`;
exports.ApplicationContent = styled_components_1.default.div `
	display: flex;
	justify-content: space-between;
	height: 100%;
`;
exports.MainBlock = styled_components_1.default.div `
	position: relative;
	width: 100%;
	min-height: 90px;
	height: 90px;
	display: flex;
	flex-direction: column;
`;
exports.CheckIconWrapper = styled_components_1.default.div `
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	color: ${colorSelected};
	cursor: pointer;
	bottom: 1.35rem;
	right: 0;
	border-radius: 50%;
	border-style: solid;
	border-width: 1px;
	border-color: ${props => props.theme.color.gray3};
	width: 17px;
	height: 17px;
	& svg {
		height: 17px;
		width: 17px;
	}
`;
exports.ImageWrapper = styled_components_1.default.div `
	display: flex;
	justify-content: space-between;
	position: relative;
`;
exports.NameWrapper = styled_components_1.default.div `
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	max-width: 132px;
	height: 13px;
	font-size: 12px;
	line-height: 13px;
	color: ${props => props.theme.color.gray9};
`;
exports.TransparentButton = (0, styled_components_1.default)(elements_1.Button) `
	background: transparent;
	color: ${props => props.theme.color.gray6};
	&& {
		height: 17px;
		border: 1px solid ${props => props.theme.color.gray6};
		font-size: 12px;
		padding: 0.6rem 0.4rem;
	}
`;
exports.RoundedSquaredAvatar = (0, styled_components_1.default)(elements_1.Avatar) `
	border-radius: 2px;
	width: 47px;
	height: 47px;
	margin-bottom: 0.5rem;
	background: ${props => props.theme.color.gray2};
	${props => props.icon &&
    (0, styled_components_1.css) `
			padding: 0 0.5rem;
		`}
`;
exports.StyledBadge = (0, styled_components_1.default)(elements_1.Badge) `
	sup {
		width: 10px;
		height: 10px;
		box-shadow: none;
		background: ${props => props.theme.color.notification};
	}
`;
exports.MultiDragCard = styled_components_1.default.div `
	position: absolute;
	left: 0;
	bottom: 0;
	height: 95px;
	width: 168px;
	min-width: 168px;
	height: 85px;
	border-radius: ${props => props.theme.borderRadius.base};
	padding: 0.5rem;
	background: ${props => props.theme.color.white};
	transition: 0.1s;
	border: 1px solid ${props => props.theme.color.brand2};
	box-shadow: -1px 4px 10px ${props => props.theme.color.gray5};
`;
