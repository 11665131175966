"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getValidationRules = getValidationRules;
const Api = __importStar(require("@src/api"));
const lodash_1 = require("lodash");
function getValidationRules({ intl, subscriptionToken, formValues }) {
    const emailValidationRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.validation_schema.email',
            }),
        },
        {
            type: 'email',
            message: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.validation_schema.enter_valid_email',
            }),
        },
        {
            validator: async (_, value) => {
                if (!value || !subscriptionToken) {
                    return;
                }
                const emailIsTaken = await Api.OfferNotificationSubscription.isEmailTaken(value, subscriptionToken);
                if (emailIsTaken) {
                    throw new Error(intl.formatMessage({
                        id: 'forms.offer_notification_subscription_form.validation_schema.email.taken',
                    }));
                }
            },
        },
    ];
    const subscriptionFilterRules = [
        () => {
            let valuesWithoutEmail = formValues;
            if (formValues && 'email' in formValues) {
                valuesWithoutEmail = (0, lodash_1.omit)(formValues, ['email']);
            }
            const areAllFieldsEmpty = (0, lodash_1.every)(valuesWithoutEmail, lodash_1.isEmpty);
            return {
                required: areAllFieldsEmpty,
                message: intl.formatMessage({ id: 'offers.offer_notification_subscription_form.fill_at_least_on' }),
            };
        },
    ];
    const salaryMaxValidationRules = [
        {
            type: 'number',
            message: intl.formatMessage({
                id: 'admin.offer_validation.number_has_wrong_format',
            }),
        },
        ({ getFieldValue }) => ({
            validator: (_, value) => {
                const minValue = getFieldValue('salaryMin');
                const isSalaryRateSelected = Boolean(getFieldValue('salaryRate'));
                return !isSalaryRateSelected ||
                    (isSalaryRateSelected && value >= minValue) ||
                    (isSalaryRateSelected && (!value || !minValue))
                    ? Promise.resolve()
                    : Promise.reject(new Error(intl.formatMessage({
                        id: 'offers.offer_notification_subscription_form.wrong_maximum_value',
                    })));
            },
        }),
    ];
    return {
        emailValidationRules,
        subscriptionFilterRules,
        salaryMaxValidationRules,
    };
}
