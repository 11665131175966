"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EErrorCode = void 0;
exports.EErrorCode = {
    OFFER_NOT_FOUND: 'OFFER_NOT_FOUND',
    MISSING_BODY_PROPERTY: 'MISSING_BODY_PROPERTY',
    INVALID_OFFER_TYPE: 'INVALID_OFFER_TYPE',
    INVALID_CONTRACT_TYPE: 'INVALID_CONTRACT_TYPE',
    GRADUATE_NOT_PAID: 'GRADUATE_NOT_PAID',
    INVALID_DURATION: 'INVALID_DURATION',
    INVALID_BOOLEAN: 'INVALID_BOOLEAN',
    INVALID_SALARY_RATE: 'INVALID_SALARY_RATE',
    INVALID_CURRENCY: 'INVALID_CURRENCY',
    INVALID_SALARY_AMOUNT: 'INVALID_SALARY_AMOUNT',
    INVALID_EMAIL: 'INVALID_EMAIL',
    INVALID_DATE_FORMAT: 'INVALID_DATE_FORMAT',
    DEADLINE_IN_PAST: 'DEADLINE_IN_PAST',
    INVALID_STATE: 'INVALID_STATE',
    INVALID_JOB_AREA: 'INVALID_JOB_AREA',
    EMPTY_PROPERTY: 'EMPTY_PROPERTY',
    NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
    EMAIL_NOT_VERIFIED: 'EMAIL_NOT_VERIFIED',
    OFFER_ID_MISSING: 'OFFER_ID_MISSING',
    WRONG_OWNER: 'WRONG_OWNER',
    OUT_OF_CREDITS: 'OUT_OF_CREDITS',
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    NOT_AUTHORIZED: 'NOT_AUTHORIZED',
    MISSING_TIN_VAT: 'MISSING_TIN_VAT',
    UNABLE_TO_SCRAPE: 'UNABLE_TO_SCRAPE',
    DRAFTING_POSTED_OFFER: 'DRAFTING_POSTED_OFFER',
    ORDER_CHANGED: 'ORDER_CHANGED',
    OFFER_ALREADY_PROMOTED: 'OFFER_ALREADY_PROMOTED',
    OFFER_INACTIVE: 'OFFER_INACTIVE',
    INVALID_OFFER_PROMOTION_PERIOD: 'INVALID_OFFER_PROMOTION_PERIOD',
    MISSING_PRODUCT_AMOUNT: 'MISSING_PRODUCT_AMOUNT',
};
