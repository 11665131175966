const defaultState = {
	options: [],
	registrationFormSuccess: null,
	isLoading: false,
	valueKey: 'label',
	companyInfo: {},
}

export default function registration(state = defaultState, action) {
	switch (action.type) {
		case '@@router/LOCATION_CHANGE':
			return { ...state, companyInfo: {}, options: [], registrationFormSuccess: null }

		default:
			return state
	}
}
