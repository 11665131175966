"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Alert = void 0;
const antd_1 = require("antd");
const styled_components_1 = __importStar(require("styled-components"));
const styled_breakpoints_1 = require("styled-breakpoints");
exports.Alert = (0, styled_components_1.default)(antd_1.Alert) `
	${({ type }) => type === 'info' &&
    (0, styled_components_1.css) `
			background-color: #e6f7ff;
			border: 1px solid #91d5ff;
			color: rgba(0, 0, 0, 0.85);

			.anticon {
				color: #1890ff;
			}
		`}

	${(0, styled_breakpoints_1.down)('sm')} {
		flex-wrap: wrap;

		.anticon {
			width: 24px;
		}

		.ant-alert-content {
			width: calc(100% - 24px);
		}

		.ant-alert-action {
			margin: 1rem 0 0.5rem 38px;
			width: 100%;
		}
	}
`;
