import React from 'react'

const IconWomanGraduate = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<title>people-woman-graduate</title>
		<path d="M.5,13.985h3a.5.5,0,0,0,.5-.5A10.666,10.666,0,0,0,2.75,8.806v-3.6a.75.75,0,0,0-1.5,0v3.6A10.666,10.666,0,0,0,0,13.485.5.5,0,0,0,.5,13.985Z" />
		<path d="M21.186,20.271l-.017-.005L16.2,18.618a.5.5,0,0,0-.5.114l-3.358,3.232a.5.5,0,0,1-.69,0L8.242,18.748a.5.5,0,0,0-.5-.111L2.814,20.271a4.517,4.517,0,0,0-2.8,3.087.5.5,0,0,0,.483.627h23a.5.5,0,0,0,.483-.626A4.514,4.514,0,0,0,21.186,20.271Z" />
		<path d="M.414,2.978l11.157,1.94a2.506,2.506,0,0,0,.858,0l11.157-1.94a.5.5,0,0,0,0-.985L12.429.052a2.473,2.473,0,0,0-.858,0L.414,1.993a.5.5,0,0,0,0,.985Z" />
		<path d="M10.291,14.6a.751.751,0,0,0,.3,1.018,3.275,3.275,0,0,0,1.532.362h.007a3.264,3.264,0,0,0,1.524-.36.749.749,0,1,0-.714-1.318,1.758,1.758,0,0,1-.812.178,1.6,1.6,0,0,1-.816-.178A.751.751,0,0,0,10.291,14.6Z" />
		<path d="M9.5,12.059a.637.637,0,0,1,.627,0,.75.75,0,1,0,1-1.114,2.1,2.1,0,0,0-2.635,0,.75.75,0,1,0,1,1.114Z" />
		<path d="M14.066,12.06a.636.636,0,0,1,.628,0,.75.75,0,1,0,1-1.114,2.1,2.1,0,0,0-2.634,0,.75.75,0,0,0,1,1.115Z" />
		<path d="M2.807,15.546a.5.5,0,0,0-.231.8c.8.962,2.918,2.2,4.466,1.744a1.939,1.939,0,0,0,1.036-.736,6.646,6.646,0,0,0,3.9,1.885,6.725,6.725,0,0,0,4.05-2.023,2.079,2.079,0,0,0,1.033.694,4.753,4.753,0,0,0,4.361-1.564.5.5,0,0,0-.231-.8,3.907,3.907,0,0,1-2.172-2.827,3.135,3.135,0,0,0,.229-1.094,2.089,2.089,0,0,0-.5-1.473V5.639a.25.25,0,0,0-.293-.246L12.343,6.456a2.043,2.043,0,0,1-.686,0L5.543,5.393a.25.25,0,0,0-.293.246V10.1a2.073,2.073,0,0,0-.54,1.523,3.159,3.159,0,0,0,.253,1.146A3.867,3.867,0,0,1,2.807,15.546Zm3.505-4.383a.748.748,0,0,0,.438-.678V8.548a.253.253,0,0,1,.1-.2.256.256,0,0,1,.217-.044A19.469,19.469,0,0,0,12,8.952a19.476,19.476,0,0,0,4.937-.646.25.25,0,0,1,.313.242V10.37a.74.74,0,0,0,.395.786.619.619,0,0,1,.105.44,1.279,1.279,0,0,1-.559,1.1,1.75,1.75,0,0,0-1,1.306,3.576,3.576,0,0,1-.6,1.4.494.494,0,0,0-.087.142,5.6,5.6,0,0,1-3.525,2.193c-1.033,0-3.831-1.49-4.208-3.74a1.753,1.753,0,0,0-1-1.306A1.276,1.276,0,0,1,6.21,11.6.632.632,0,0,1,6.312,11.163Z" />
	</svg>
)

export default IconWomanGraduate
