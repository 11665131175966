"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EInterviewType = exports.OfferApplicationStageOrder = exports.EOfferApplicationStage = void 0;
var EOfferApplicationStage;
(function (EOfferApplicationStage) {
    EOfferApplicationStage["new"] = "new";
    EOfferApplicationStage["preselection"] = "preselection";
    EOfferApplicationStage["interview"] = "interview";
    EOfferApplicationStage["hired"] = "hired";
    EOfferApplicationStage["rejected"] = "rejected";
})(EOfferApplicationStage || (exports.EOfferApplicationStage = EOfferApplicationStage = {}));
var OfferApplicationStageOrder;
(function (OfferApplicationStageOrder) {
    OfferApplicationStageOrder[OfferApplicationStageOrder["new"] = 1] = "new";
    OfferApplicationStageOrder[OfferApplicationStageOrder["preselection"] = 2] = "preselection";
    OfferApplicationStageOrder[OfferApplicationStageOrder["interview"] = 3] = "interview";
    OfferApplicationStageOrder[OfferApplicationStageOrder["hired"] = 4] = "hired";
    OfferApplicationStageOrder[OfferApplicationStageOrder["rejected"] = 5] = "rejected";
})(OfferApplicationStageOrder || (exports.OfferApplicationStageOrder = OfferApplicationStageOrder = {}));
var EInterviewType;
(function (EInterviewType) {
    EInterviewType["video_call"] = "video_call";
    EInterviewType["personal"] = "personal";
})(EInterviewType || (exports.EInterviewType = EInterviewType = {}));
