"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkillTagSkillLevel = void 0;
var SkillTagSkillLevel;
(function (SkillTagSkillLevel) {
    SkillTagSkillLevel["beginner"] = "beginner";
    SkillTagSkillLevel["basics"] = "basics";
    SkillTagSkillLevel["preintermediate"] = "preintermediate";
    SkillTagSkillLevel["intermediate"] = "intermediate";
    SkillTagSkillLevel["expert"] = "expert";
})(SkillTagSkillLevel || (exports.SkillTagSkillLevel = SkillTagSkillLevel = {}));
