import React from 'react'

const ManGraduate = () => (
	<svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".5">
			<path d="m3.95 9.556 2.031 2.187 2.04-2.197M5.915 2.228a.491.491 0 0 0 .17 0l4.957-.863a.125.125 0 0 0 0-.246L6.085.258a.491.491 0 0 0-.17 0l-4.957.861a.125.125 0 0 0 0 .246ZM1 4.242a5.465 5.465 0 0 0-.741 2.25.234.234 0 0 0 .234.25h1a.25.25 0 0 0 .25-.269A5.475 5.475 0 0 0 1 4.243Zm0-2.869v2.87m2 .999V1.721m6 0v3.521M3 3.743a9.489 9.489 0 0 0 6 0M5.24 7.442a1.087 1.087 0 0 0 1.5 0M5.5 5.492a.544.544 0 0 0-.5-.25.544.544 0 0 0-.5.25m3 0a.544.544 0 0 0-.5-.25.544.544 0 0 0-.5.25" />
			<path d="M2.99 5.242c-.437.219-.3 1.2.24 1.445a.5.5 0 0 1 .287.373c.221 1.322 1.777 2.183 2.473 2.183s2.25-.861 2.474-2.183a.5.5 0 0 1 .286-.373c.541-.245.677-1.226.24-1.445m-.969 4.304 2.479.822.009.003a2.003 2.003 0 0 1 1.241 1.371m-7.8-2.186-2.45.813-.009.002A2.003 2.003 0 0 0 .25 11.742" />
		</g>
	</svg>
)

export default ManGraduate
