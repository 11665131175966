import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import app from './app'
import login from './login'
import admin from './admin'
import registration from './registration'
import resetPasswordEmail from './reset-password-email'
import offerApplication from './offer-application'

const reducers = () =>
	combineReducers({
		form,
		app,
		admin,
		login,
		registration,
		resetPasswordEmail,
		offerApplication,
	})

export default reducers
