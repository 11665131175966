import React from 'react'

const IconBuildingsModern = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<title>buildings-modern</title>
		<path d="M11.046,2.431a1,1,0,0,0-.952-.076L6.2,4.085a.5.5,0,0,1-.7-.457V1a1,1,0,0,0-2,0V4.962a.5.5,0,0,1-.3.456L2.1,5.911a1,1,0,0,0-.6.914V23a1,1,0,0,0,1,1h2a.5.5,0,0,0,.5-.5V21a.5.5,0,0,1,.5-.5h2A.5.5,0,0,1,8,21v2.5a.5.5,0,0,0,.5.5h2a1,1,0,0,0,1-1V16a.5.5,0,0,0-.5-.5H6A.75.75,0,0,1,6,14h5a.5.5,0,0,0,.5-.5V12a.5.5,0,0,0-.5-.5H8A.75.75,0,0,1,8,10h3a.5.5,0,0,0,.5-.5V8a.5.5,0,0,0-.5-.5H9A.75.75,0,0,1,9,6h2a.5.5,0,0,0,.5-.5V3.27A1,1,0,0,0,11.046,2.431Z" />
		<path d="M20.235,9.628A.5.5,0,0,0,21,9.2V6.5a.5.5,0,0,0-.5-.5h-5a.307.307,0,0,0-.163.567Z" />
		<path d="M22.029,12.527l-7-4.374A1,1,0,0,0,13.5,9v2.5a.5.5,0,0,0,.5.5h2a.75.75,0,0,1,0,1.5H14a.5.5,0,0,0-.5.5v1.5a.5.5,0,0,0,.5.5h4a.75.75,0,0,1,0,1.5H14a.5.5,0,0,0-.5.5v5a1,1,0,0,0,1,1H16a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,1,17,20h2a.5.5,0,0,1,.5.5v3a.5.5,0,0,0,.5.5h1.5a1,1,0,0,0,1-1V13.375A.993.993,0,0,0,22.029,12.527Z" />
	</svg>
)

export default IconBuildingsModern
