"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledPanel = void 0;
const styled_breakpoints_1 = require("styled-breakpoints");
const styled_components_1 = __importStar(require("styled-components"));
exports.StyledPanel = styled_components_1.default.div `
	padding: 2rem;
	background: ${({ theme }) => theme.color.white};

	${({ mobileFriendly }) => !mobileFriendly &&
    (0, styled_components_1.css) `
			border: 1px solid ${({ theme }) => theme.color.gray4};
			border-radius: ${({ theme }) => theme.borderRadius.base};
		`}

	${(0, styled_breakpoints_1.down)('sm')} {
		padding: 1rem;
	}
`;
