"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendResumeFormRules = void 0;
const Api = __importStar(require("@src/api"));
async function failIfUserExists(email) {
    try {
        await Api.Account.getAccountExistsByEmail(email);
        throw new Error('exists');
    }
    catch (err) {
        if (err.message === 'exists') {
            throw err;
        }
    }
}
const sendResumeFormRules = intl => {
    const firstNameRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'offer_detail.sync_validation.please_fill_first_name',
            }),
        },
        {
            // eslint-disable-next-line
            pattern: /^[^0-9_~`!@#$%^&*(),.;<>?{}\[\]\\\/]+$/g,
            message: intl.formatMessage({
                id: 'offer_detail.sync_validation.data_with_forbidden_chars',
            }),
        },
    ];
    const lastNameRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'offer_detail.sync_validation.please_fill_last_name',
            }),
        },
        {
            // eslint-disable-next-line
            pattern: /^[^0-9_~`!@#$%^&*(),.;<>?{}\[\]\\\/]+$/g,
            message: intl.formatMessage({
                id: 'offer_detail.sync_validation.data_with_forbidden_chars',
            }),
        },
    ];
    const passwordRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'offer_detail.sync_validation.fill_password_field',
            }),
        },
        {
            min: 8,
            message: intl.formatMessage({
                id: 'offer_detail.sync_validation.password_is_short',
            }),
        },
        {
            pattern: /^.*(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/g,
            message: intl.formatMessage({
                id: 'offer_detail.sync_validation.password_must_contain',
            }),
        },
    ];
    const repeatPasswordRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'offer_detail.sync_validation.fill_repeate_password_field',
            }),
        },
        ({ getFieldValue }) => ({
            validator(_, value) {
                if (value && getFieldValue('password') === value) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error(intl.formatMessage({
                    id: 'offer_detail.sync_validation.passwords_doesnt_match',
                })));
            },
        }),
    ];
    const agreementRules = [
        {
            validator: (_, value) => {
                return value
                    ? Promise.resolve()
                    : Promise.reject(new Error(intl.formatMessage({
                        id: 'offer_detail.sync_validation.you_must_agree_with',
                    })));
            },
        },
    ];
    const messageRules = [
        {
            required: false,
            message: intl.formatMessage({
                id: 'offer_detail.sync_validation.please_write_covering_message',
            }),
        },
    ];
    const motivationalLetterRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'offer_detail.sync_validation.please_add_motivational_letter',
            }),
        },
    ];
    const emailRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'offer_detail.sync_validation.fill_email_field',
            }),
        },
        {
            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
            message: intl.formatMessage({
                id: 'offer_detail.sync_validation.fill_valid_email',
            }),
        },
        {
            validator: async (_, value) => new Promise((resolve, reject) => {
                failIfUserExists(value)
                    .then(() => {
                    resolve(null);
                })
                    .catch(() => reject(new Error(intl.formatMessage({
                    id: 'profile.async_validation.this_email_is_taken',
                }))));
            }),
        },
    ];
    const addResumeRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'offer_detail.sync_validation.please_add_resume',
            }),
        },
    ];
    const telephoneRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.telephone',
            }),
        },
        {
            max: 14,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_long_telephone',
            }),
        },
        {
            min: 9,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_short_telephone',
            }),
        },
        {
            pattern: /^[^A-Za-z_~`!@#$%^&*(),.;<>?]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_title_after',
            }),
        },
    ];
    return {
        firstNameRules,
        lastNameRules,
        emailRules,
        addResumeRules,
        motivationalLetterRules,
        passwordRules,
        repeatPasswordRules,
        agreementRules,
        messageRules,
        telephoneRules,
    };
};
exports.sendResumeFormRules = sendResumeFormRules;
