"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatSearchSelectValues = formatSearchSelectValues;
const types_1 = require("@src/types");
function formatSearchSelectValues(specificSearch) {
    if (!specificSearch) {
        return { companyIds: [], skillTagIds: [], keywords: [] };
    }
    const companyIds = specificSearch
        .filter(({ group }) => group === types_1.SearchFilterOptionGroup.company)
        .map(({ value: companyId }) => Number(companyId));
    const skillTagIds = specificSearch
        .filter(({ group }) => group === types_1.SearchFilterOptionGroup.skillTag)
        .map(({ value: skillTagId }) => Number(skillTagId));
    const keywords = specificSearch
        .filter(({ group }) => group === types_1.SearchFilterOptionGroup.keyword)
        .map(({ value: keyword }) => keyword.toString());
    return { companyIds, skillTagIds, keywords };
}
