"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollDirection = void 0;
const react_1 = require("react");
const useScrollDirection = ({ initialDirection = 'down', thresholdPixels = 64 }) => {
    const [scrollDir, setScrollDir] = (0, react_1.useState)(initialDirection);
    (0, react_1.useEffect)(() => {
        const threshold = thresholdPixels || 0;
        let lastScrollY = window.pageYOffset;
        let ticking = false;
        const updateScrollDir = () => {
            const scrollY = window.pageYOffset;
            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setScrollDir(scrollY > lastScrollY ? 'down' : 'up');
            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        };
        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
        };
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [initialDirection, thresholdPixels]);
    return scrollDir;
};
exports.useScrollDirection = useScrollDirection;
