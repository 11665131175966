"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onSocialInputChange = onSocialInputChange;
function onSocialInputChange(inputStringValue, socialTypeUrl, setFieldsValue, isCompany) {
    const socialType = socialTypeUrl.slice(0, -3);
    switch (socialType) {
        case 'facebook':
            if (inputStringValue.match(/(https:\/\/)?(www\.)?([a-z]*\.)?facebook\.com\//)) {
                setFieldsValue({
                    facebookUrl: inputStringValue.replace(/(https:\/\/)?(www\.)?([a-z]*\.)?facebook\.com\//, ''),
                });
            }
            break;
        case 'instagram':
            if (inputStringValue.match(/(https:\/\/)?(www\.)?([a-z]*\.)?instagram\.com\//)) {
                setFieldsValue({
                    instagramUrl: inputStringValue.replace(/(https:\/\/)?(www\.)?([a-z]*\.)?instagram\.com\//, ''),
                });
            }
            break;
        case 'twitter':
            if (inputStringValue.match(/(https:\/\/)?(www\.)?([a-z]*\.)?twitter\.com\//)) {
                setFieldsValue({
                    twitterUrl: inputStringValue.replace(/(https:\/\/)?(www\.)?([a-z]*\.)?twitter\.com\//, ''),
                });
            }
            break;
        case 'linkedin':
            if (!isCompany && inputStringValue.match(/(https:\/\/)?(www\.)?([a-z]*\.)?linkedin\.com\/in\//)) {
                setFieldsValue({
                    linkedinUrl: inputStringValue.replace(/(https:\/\/)?(www\.)?([a-z]*\.)?linkedin\.com\/in\//, ''),
                });
            }
            else if (isCompany &&
                inputStringValue.match(/(https:\/\/)?(www\.)?([a-z]*\.)?linkedin\.com\/company\//)) {
                setFieldsValue({
                    linkedinUrl: inputStringValue.replace(/(https:\/\/)?(www\.)?([a-z]*\.)?linkedin\.com\/company\//, ''),
                });
            }
            break;
        case 'youtube':
            if (inputStringValue.match(/(https:\/\/)?(www\.)?([a-z]*\.)?youtube\.com\/channel\//)) {
                setFieldsValue({
                    youtubeUrl: inputStringValue.replace(/(https:\/\/)?(www\.)?([a-z]*\.)?youtube\.com\/channel\//, ''),
                });
            }
            break;
        // no default
    }
}
