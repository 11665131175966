"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMyOffers = getMyOffers;
exports.patchOfferTitle = patchOfferTitle;
exports.getOfferApplications = getOfferApplications;
exports.buyProductByCard = buyProductByCard;
exports.deletePaymentMethod = deletePaymentMethod;
exports.getPaymentMethods = getPaymentMethods;
exports.getCompanyAccounts = getCompanyAccounts;
exports.getClientToken = getClientToken;
exports.getPaymentMethodNonce = getPaymentMethodNonce;
exports.createPaymentMethod = createPaymentMethod;
exports.createInvitation = createInvitation;
exports.removeInvitation = removeInvitation;
exports.changeUserRole = changeUserRole;
exports.deleteUser = deleteUser;
exports.getCompanyEmptyFields = getCompanyEmptyFields;
exports.validateCoupon = validateCoupon;
exports.applyCoupon = applyCoupon;
exports.finishHiringProcess = finishHiringProcess;
exports.updateColleaguesOffers = updateColleaguesOffers;
exports.getCompanyPremiumSettings = getCompanyPremiumSettings;
exports.getOrderContent = getOrderContent;
exports.generateNewApiKey = generateNewApiKey;
const lodash_1 = require("lodash");
const axios_1 = require("./clients/axios");
async function getMyOffers() {
    try {
        const response = await axios_1.client.get('/0/offer/my-offers');
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function patchOfferTitle(id, value) {
    try {
        const response = await axios_1.client.patch(`/0/offer/${id}/title`, { title: value });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getOfferApplications(id) {
    try {
        const response = await axios_1.client.get(`/0/offer/${id}/offer-applications`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function buyProductByCard({ order, orderParams, savePaymentMethod, billingProfile, nonce, couponCode, deviceData, offerId, }) {
    try {
        const response = await axios_1.client.post(`/0/company/buy-product-by-card`, {
            order,
            orderParams,
            nonce,
            savePaymentMethod,
            billingProfile: (0, lodash_1.omit)(billingProfile, 'offers'),
            couponCode,
            deviceData,
            offerId,
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function deletePaymentMethod() {
    try {
        const response = await axios_1.client.delete(`/0/company/payment-method`, {});
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getPaymentMethods() {
    try {
        const response = await axios_1.client.get(`/0/company/payment-method`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getCompanyAccounts() {
    try {
        const response = await axios_1.client.get(`/0/company/accounts`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getClientToken() {
    try {
        const response = await axios_1.client.get(`/0/company/client-token`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getPaymentMethodNonce() {
    try {
        const response = await axios_1.client.get(`/0/company/payment-method-nonce`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function createPaymentMethod({ paymentMethodData, }) {
    await axios_1.client.post(`/0/company/payment-method`, { paymentMethodData });
}
async function createInvitation(emails) {
    try {
        const response = await axios_1.client.post(`/0/company/invitation`, { emails });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function removeInvitation(invitationId) {
    try {
        const response = await axios_1.client.delete(`/0/company/invitation/${invitationId}`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function changeUserRole(userId, role) {
    try {
        const response = await axios_1.client.patch(`/0/company/account/${userId}`, { role });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function deleteUser(userId) {
    try {
        const response = await axios_1.client.delete(`/0/company/account/${userId}`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getCompanyEmptyFields() {
    try {
        const response = await axios_1.client.get('/0/company/empty-fields');
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function validateCoupon(code, productIds, offerId) {
    try {
        const response = await axios_1.client.post('/0/coupon/validate', { code, productIds, offerId });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function applyCoupon(code, productIds, offerId) {
    try {
        const response = await axios_1.client.post('/0/coupon/apply', { code, productIds, offerId });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function finishHiringProcess(offerId, notifyRejectedApplicants) {
    try {
        const response = await axios_1.client.patch('/0/company/finish-hiring-process', { offerId, notifyRejectedApplicants });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function updateColleaguesOffers() {
    await axios_1.client.get('/0/company/update-colleagues-offers');
}
async function getCompanyPremiumSettings() {
    const response = await axios_1.client.get('/0/company/premium-settings');
    return response.data;
}
async function getOrderContent({ orderParams }) {
    const response = await axios_1.client.get('/0/company/order-content', { params: orderParams });
    return response.data;
}
async function generateNewApiKey() {
    return (await axios_1.client.post('/0/company-api-key', {})).data;
}
