"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColumnTitle = exports.CardsWrap = exports.Column = exports.ShadowWrap = exports.Columns = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const styled_breakpoints_1 = require("styled-breakpoints");
exports.Columns = styled_components_1.default.div `
	height: 600px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;

	${(0, styled_breakpoints_1.down)('lg')} {
		flex-direction: column;
		height: auto;
	}
`;
exports.ShadowWrap = styled_components_1.default.div `
	position: relative;
	${(0, styled_breakpoints_1.down)('lg')} {
		width: 100%;
	}
`;
exports.Column = styled_components_1.default.div `
	padding-top: 2rem;
	margin-right: 1rem;
	overscroll-behavior: contain;
	background: ${props => props.theme.color.gray2};
	display: flex;
	flex-direction: column;
	border-radius: 6px;
	height: auto;
	min-height: 600px;
	max-height: 600px;
	width: 189px;
	min-width: 189px;
	border: 1px solid ${props => props.theme.color.gray3};
	transition: 0.1s;
	overflow-x: hidden;
	overflow-y: auto;
	::-webkit-scrollbar {
		width: 5px;
	}
	${({ isDragging }) => isDragging &&
    (0, styled_components_1.css) `
			overflow-y: hidden;
		`}

	::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
		border-radius: 10px;
	}
	${(0, styled_breakpoints_1.up)('xl')} {
		${({ showBottomShadow }) => showBottomShadow &&
    (0, styled_components_1.css) `
				&::after {
					content: '';
					position: absolute;
					display: block;
					bottom: 1px;
					height: 20px;
					width: 188px;
					pointer-events: none;
					background: linear-gradient(to top, rgba(200, 200, 200, 1) 0%, rgba(200, 200, 200, 0) 100%);
					border-radius: 6px;
				}
			`}
	}

	${(0, styled_breakpoints_1.down)('lg')} {
		width: 100%;
		min-width: 100%;
		height: 150px;
		min-height: 150px;
		margin-bottom: 1rem;
		overflow-y: hidden;
		overflow-x: auto;
		::-webkit-scrollbar {
			height: 5px;
		}
		${({ showLeftShadow }) => showLeftShadow &&
    (0, styled_components_1.css) `
				&::before {
					content: '';
					position: absolute;
					display: block;
					top: 1px;
					left: 1px;
					height: 150px;
					width: 20px;
					pointer-events: none;
					background: linear-gradient(to right, rgba(200, 200, 200, 1) 0%, rgba(200, 200, 200, 0) 100%);
					border-radius: 6px;
				}
			`}
		${({ showRightShadow }) => showRightShadow &&
    (0, styled_components_1.css) `
				&::after {
					content: '';
					position: absolute;
					display: block;
					right: 1px;
					top: 0;
					height: 150px;
					width: 20px;
					pointer-events: none;
					background: linear-gradient(to left, rgba(200, 200, 200, 1) 0%, rgba(200, 200, 200, 0) 100%);
					border-radius: 6px;
					z-index: 2;
				}
			`}
	}

	${({ isHalfSize }) => isHalfSize &&
    (0, styled_components_1.css) `
			min-height: 292px;
			max-height: 292px;
			&:not(:last-child) {
				margin-bottom: 1rem;
			}
		`}

	${({ isDraggingOver }) => isDraggingOver &&
    (0, styled_components_1.css) `
			border: 1px solid ${props => props.theme.color.brand2};
		`}
`;
exports.CardsWrap = styled_components_1.default.div `
	${(0, styled_breakpoints_1.down)('lg')} {
		display: flex;
	}
`;
exports.ColumnTitle = styled_components_1.default.div `
	color: ${props => props.theme.color.gray7};
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
	border-radius: 6px;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 14px;
	padding: 1rem 0 1rem 0.8rem;
	min-width: 183px;
	width: 183px;
	background: linear-gradient(
		to bottom,
		rgba(245, 245, 245, 1) 0%,
		rgba(245, 245, 245, 0.8) 70%,
		rgba(245, 245, 245, 0.2) 100%
	);
	transition: 0.1s;
	${(0, styled_breakpoints_1.down)('lg')} {
		height: 1.5rem;
		width: 100%;
		padding-left: 1rem;
		background: transparent;
	}
`;
