"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useModelEffect = void 0;
const react_1 = require("react");
// useModelEffect hook allows you to create callback which is triggered
// by different set of dependencies while keeping your necessary values up-to-date
const useModelEffect = (effectCallBack, upToDateDependencies, triggeringDependencies) => {
    const upToDateRef = (0, react_1.useRef)(upToDateDependencies);
    (0, react_1.useEffect)(() => {
        upToDateRef.current = upToDateDependencies;
    }, upToDateDependencies);
    (0, react_1.useEffect)(() => {
        const returnFunction = effectCallBack(upToDateRef.current);
        if (returnFunction) {
            return () => returnFunction === null || returnFunction === void 0 ? void 0 : returnFunction(upToDateRef.current);
        }
    }, triggeringDependencies);
};
exports.useModelEffect = useModelEffect;
