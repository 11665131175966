"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EProductValidationErrorCode = exports.ProductType = exports.SubscriptionPeriods = exports.ProductId = void 0;
var ProductId;
(function (ProductId) {
    ProductId[ProductId["cvCredits"] = 1] = "cvCredits";
    ProductId[ProductId["testCredits"] = 2] = "testCredits";
    ProductId[ProductId["offerCredits"] = 3] = "offerCredits";
    ProductId[ProductId["companyPremium"] = 4] = "companyPremium";
    ProductId[ProductId["offerPromotion"] = 5] = "offerPromotion";
    ProductId[ProductId["offerCV"] = 6] = "offerCV";
})(ProductId || (exports.ProductId = ProductId = {}));
var SubscriptionPeriods;
(function (SubscriptionPeriods) {
    SubscriptionPeriods[SubscriptionPeriods["OneMonthPeriod"] = 1] = "OneMonthPeriod";
    SubscriptionPeriods[SubscriptionPeriods["SixMonthsPeriod"] = 6] = "SixMonthsPeriod";
    SubscriptionPeriods[SubscriptionPeriods["TwelveMonthsPeriod"] = 12] = "TwelveMonthsPeriod";
})(SubscriptionPeriods || (exports.SubscriptionPeriods = SubscriptionPeriods = {}));
var ProductType;
(function (ProductType) {
    ProductType["credit"] = "credit";
    ProductType["subscription"] = "subscription";
    ProductType["promotion"] = "promotion";
})(ProductType || (exports.ProductType = ProductType = {}));
var EProductValidationErrorCode;
(function (EProductValidationErrorCode) {
    EProductValidationErrorCode["INVALID_AMOUNT"] = "INVALID_AMOUNT";
    EProductValidationErrorCode["INVALID_PRODUCT"] = "INVALID_PRODUCT";
    EProductValidationErrorCode["INVALID_COUPON"] = "INVALID_COUPON";
    EProductValidationErrorCode["ALREADY_ACTIVE_PREMIUM_SUBSCRIPTION"] = "ALREADY_ACTIVE_PREMIUM_SUBSCRIPTION";
})(EProductValidationErrorCode || (exports.EProductValidationErrorCode = EProductValidationErrorCode = {}));
