import { call, put } from 'redux-saga/effects'
import { client as axios } from '@src/api/clients/axios'
import URI from 'urijs'
import { notification } from 'antd'
import config from 'config'
import actions from '../actions'

export default function* resetPasswordEmailStart(values) {
	const uri = new URI(`${config.api}/0/reset-password-email`)
	const apiUri = uri.toString()

	const sendEmail = data =>
		axios
			.post(apiUri, data, {
				withCredentials: true,
				validateStatus: status => (status >= 200 && status < 300) || status === 429,
			})
			.then(res => {
				if (res.status === 429) {
					notification.error({
						message: 'Ochrana proti útokom',
						description:
							'Snažíme sa ochrániť údaje užívateľov. Z toho dôvodu sme zablokovali vašu IP adresu na 30 minút.',
					})

					throw res
				} else {
					notification.success({
						message: 'Upozornenie',
						description: 'Email na obnovenie hesla úspešne odoslaný',
					})
				}
			})
			.catch(err => {
				notification.error({
					message: 'Chyba',
					description: 'Účet so zadaným emailom nebol nájdený',
				})
				throw err
			})

	try {
		const response = yield call(sendEmail, values.payload)
		yield put(actions.resetPasswordEmailSuccess(response))
	} catch (err) {
		yield put(actions.resetPasswordEmailError(err))
	}
}
