"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const featureFlags = {
    local: {
        automatizedMessages: false,
        applicantsOverview: true,
    },
    development: {
        automatizedMessages: false,
        applicantsOverview: false,
    },
    production: {
        automatizedMessages: false,
        applicantsOverview: false,
    },
};
function featureSwitch(flag) {
    return featureFlags[process.env.ENVIRONMENT][flag];
}
exports.default = featureSwitch;
