"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRouteActive = exports.getRouteId = exports.getLocalizedPath = exports.findMatchingRoute = exports.getPathWithoutLocale = void 0;
exports.getLanguageFromPathname = getLanguageFromPathname;
const path_to_regexp_1 = require("path-to-regexp");
const Routes_1 = require("@src/router/Routes");
const defaultLanguage = 'sk';
const supportedLanguages = ['sk', 'en', 'uk'];
const getPathWithoutLocale = (path) => {
    let pathWithoutLocale = path;
    let currentLocale = defaultLanguage;
    supportedLanguages.forEach(lang => {
        const language = path.split('/').filter(p => Boolean(p))[0];
        if (language === lang) {
            pathWithoutLocale = path.replace(`/${lang}`, '');
            currentLocale = lang;
        }
    });
    return { currentLocale, pathWithoutLocale };
};
exports.getPathWithoutLocale = getPathWithoutLocale;
function getLanguageFromPathname(pathname) {
    const splittedPath = pathname.substring(1).split('/');
    return supportedLanguages.includes(splittedPath[0]) ? splittedPath[0] : defaultLanguage;
}
const findMatchingRoute = (routesArr, pathWithoutLocale) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const route of routesArr) {
        if (route.routes) {
            const matchingRoute = (0, exports.findMatchingRoute)(route.routes, pathWithoutLocale);
            if (matchingRoute) {
                return matchingRoute;
            }
        }
        if (route.paths[defaultLanguage]) {
            // eslint-disable-next-line no-restricted-syntax
            for (const language of supportedLanguages) {
                const pathRegEx = (0, path_to_regexp_1.pathToRegexp)(route.paths[language]);
                if (pathRegEx.test(pathWithoutLocale)) {
                    return route;
                }
            }
        }
    }
    return null;
};
exports.findMatchingRoute = findMatchingRoute;
const getLocalizedPath = (path, targetLocale) => {
    const { pathWithoutLocale, currentLocale } = (0, exports.getPathWithoutLocale)(path);
    const pathWithoutLocaleAndSearch = pathWithoutLocale.split('?')[0];
    const search = pathWithoutLocale.split('?')[1] ? `?${pathWithoutLocale.split('?')[1]}` : '';
    const matchedRoute = (0, exports.findMatchingRoute)(Routes_1.routes, pathWithoutLocaleAndSearch);
    if (matchedRoute) {
        const parsedRoute = (0, path_to_regexp_1.match)(matchedRoute.paths[currentLocale])(pathWithoutLocaleAndSearch);
        const localizedPath = (0, path_to_regexp_1.compile)(matchedRoute.paths[targetLocale])({ ...parsedRoute.params });
        return targetLocale === defaultLanguage
            ? `${localizedPath}${search}`
            : `/${targetLocale}${localizedPath}${search}`;
    }
    return path;
};
exports.getLocalizedPath = getLocalizedPath;
// TODO - this might not work if there are duplicate routes under different locales, should be improved
const getRouteId = (path) => {
    const { pathWithoutLocale } = (0, exports.getPathWithoutLocale)(path);
    const matchedRoute = (0, exports.findMatchingRoute)(Routes_1.routes, pathWithoutLocale);
    if (matchedRoute) {
        return matchedRoute.id;
    }
    return '';
};
exports.getRouteId = getRouteId;
const isRouteActive = (current, target, exact = false) => {
    if (exact) {
        return (0, exports.getRouteId)(current) === (0, exports.getRouteId)(target);
    }
    return (0, exports.getRouteId)(current).startsWith((0, exports.getRouteId)(target));
};
exports.isRouteActive = isRouteActive;
