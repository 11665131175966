"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.registrationFormRules = void 0;
const Api = __importStar(require("@src/api"));
const antd_1 = require("antd");
async function failIfUserExists(email) {
    try {
        await Api.Account.getAccountExistsByEmail(email);
        throw new Error('exists');
    }
    catch (err) {
        if (err.message === 'exists') {
            throw err;
        }
    }
}
async function failIfCompanyUrlExists(companyUrl) {
    try {
        await Api.Company.getCompanyByUrl(companyUrl);
        throw new Error('exists');
    }
    catch (err) {
        if (err.message === 'exists') {
            throw err;
        }
    }
}
const registrationFormRules = intl => {
    const companyNameRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.name',
            }),
        },
        {
            max: 250,
            message: intl.formatMessage({
                id: 'registration.sync_validation.name_too_long',
            }),
        },
    ];
    const cinRules = [
        {
            max: 8,
            min: 8,
            message: intl.formatMessage({
                id: 'registration.sync_validation.wrong_cin',
            }),
        },
        {
            pattern: /^[0-9]{1,8}$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_cin',
            }),
        },
    ];
    const tinRules = [
        {
            max: 10,
            min: 10,
            message: intl.formatMessage({
                id: 'registration.sync_validation.wrong_tin',
            }),
        },
        {
            pattern: /^[0-9]{1,10}$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_tin',
            }),
        },
    ];
    const addressRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.street_address',
            }),
        },
        {
            // eslint-disable-next-line
            pattern: /^[^_~`!@#$%^&*();<>?]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_adress',
            }),
        },
    ];
    const cityRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.city',
            }),
        },
        {
            pattern: /^[^_~`!@#$%^&*().;<>?={}]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_city',
            }),
        },
    ];
    const postalCodeRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.postal_code',
            }),
        },
        {
            min: 4,
            message: intl.formatMessage({
                id: 'registration.sync_validation.too_short',
            }),
        },
        {
            max: 8,
            message: intl.formatMessage({
                id: 'registration.sync_validation.too_long',
            }),
        },
        {
            pattern: /^([a-zA-Z0-9]([\s])*)*$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_postal_code',
            }),
        },
    ];
    const descriptionRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.description',
            }),
            transform: (value) => {
                return (value || '').replace(/<[^>]*>?/g, '').replace(/\s/g, '');
            },
        },
    ];
    const areaRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.area',
            }),
        },
        {
            pattern: /^[^0-9_~`!@#$%^&*().;<>?]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_postal_code',
            }),
        },
    ];
    const employeeRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.employees',
            }),
        },
        {
            pattern: /^\d+$/g,
            message: intl.formatMessage({
                id: 'admin.company_info_edit_validation.data_with_forbidden_chars',
            }),
        },
    ];
    const companyUrlRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'admin.company_info_edit_validation.fill_company_url',
            }),
        },
        {
            pattern: /^[A-Za-z-]*$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_company_url',
            }),
        },
        {
            max: 250,
            message: intl.formatMessage({
                id: 'registration.sync_validation.company_url_too_long',
            }),
        },
        {
            validator: async (_, value) => new Promise((resolve, reject) => {
                failIfCompanyUrlExists(value)
                    .then(() => {
                    resolve(null);
                })
                    .catch(() => {
                    antd_1.notification.warning({
                        message: intl.formatMessage({
                            id: 'registration.async_validation.company_url_taken',
                        }),
                        description: intl.formatMessage({
                            id: 'registration.async_validation.company_url_used',
                        }),
                    });
                    reject(new Error(intl.formatMessage({
                        id: 'registration.async_validation.company_url_taken',
                    })));
                });
            }),
        },
    ];
    const firstNameRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.first_name',
            }),
        },
        {
            // eslint-disable-next-line
            pattern: /^[^0-9_~`!@#$%^&*(),.;<>?={}\[\]\\\/]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_first_name',
            }),
        },
    ];
    const lastNameRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.last_name',
            }),
        },
        {
            // eslint-disable-next-line
            pattern: /^[^0-9_~`!@#$%^&*(),.;<>?{}\[\]\\\/]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_last_name',
            }),
        },
    ];
    const titleBeforeRules = [
        {
            max: 12,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_length_title_before',
            }),
        },
        {
            pattern: /^[^0-9_~`!@#$%^&*(),;<>?]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_title_before',
            }),
        },
    ];
    const titleAfterRules = [
        {
            max: 30,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_length_title_after',
            }),
        },
        {
            pattern: /^[^0-9_~`!@#$%^&*(),;<>?]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_title_after',
            }),
        },
    ];
    const telephoneRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.telephone',
            }),
        },
        {
            max: 14,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_long_telephone',
            }),
        },
        {
            min: 9,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_short_telephone',
            }),
        },
        {
            pattern: /^[^A-Za-z_~`!@#$%^&*(),.;<>?]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_title_after',
            }),
        },
    ];
    const passwordRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.password',
            }),
        },
        {
            min: 8,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_length_password',
            }),
        },
        {
            pattern: /^.*(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_password',
            }),
        },
    ];
    const repeatPasswordRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.fill_repeate_password_field',
            }),
        },
        ({ getFieldValue }) => ({
            validator(_, value) {
                if (value && getFieldValue('password') === value) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error(intl.formatMessage({
                    id: 'registration.sync_validation.passwordRepeat',
                })));
            },
        }),
    ];
    const agreementRules = [
        {
            validator: (_, value) => {
                return value
                    ? Promise.resolve()
                    : Promise.reject(new Error(intl.formatMessage({
                        id: 'registration.sync_validation.protection',
                    })));
            },
        },
    ];
    const emailRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.email',
            }),
        },
        {
            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.invalid_email',
            }),
        },
        {
            validator: async (_, value) => new Promise((resolve, reject) => {
                failIfUserExists(value)
                    .then(() => {
                    resolve(null);
                })
                    .catch(() => {
                    antd_1.notification.warning({
                        message: intl.formatMessage({
                            id: 'registration.async_validation.email_taken',
                        }),
                        description: intl.formatMessage({
                            id: 'registration_student.async_validation.email_used',
                        }),
                    });
                    reject(new Error(intl.formatMessage({
                        id: 'registration.async_validation.email_taken',
                    })));
                });
            }),
        },
    ];
    const websiteRules = [
        {
            // eslint-disable-next-line no-useless-escape
            pattern: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/g,
            message: intl.formatMessage({
                id: 'registration.async_validation.wrong_website_format',
            }),
        },
    ];
    return {
        companyNameRules,
        cinRules,
        tinRules,
        addressRules,
        cityRules,
        postalCodeRules,
        descriptionRules,
        employeeRules,
        areaRules,
        companyUrlRules,
        firstNameRules,
        lastNameRules,
        titleAfterRules,
        titleBeforeRules,
        telephoneRules,
        emailRules,
        passwordRules,
        repeatPasswordRules,
        agreementRules,
        websiteRules,
    };
};
exports.registrationFormRules = registrationFormRules;
