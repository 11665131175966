"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.incrementPageView = incrementPageView;
exports.incrementPromotedOfferView = incrementPromotedOfferView;
exports.getAllDefaultCharts = getAllDefaultCharts;
exports.getAnalyticsByType = getAnalyticsByType;
const axios_1 = require("./clients/axios");
async function incrementPageView(pathname) {
    try {
        const response = await axios_1.client.post('/0/analytics/page-view', {
            pathname,
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function incrementPromotedOfferView(pathname) {
    try {
        const response = await axios_1.client.post('/0/analytics/promoted-offer-view', {
            pathname,
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getAllDefaultCharts({ dateFrom, dateTo, }) {
    const response = await axios_1.client.get('/0/analytics/chart-list', {
        params: {
            dateFrom,
            dateTo,
        },
    });
    return response.data;
}
async function getAnalyticsByType({ dateFrom, dateTo, analyticsType, offerId, specificDate, offerCompanyId, }) {
    const response = await axios_1.client.get(`/0/analytics/${analyticsType}`, {
        params: {
            dateFrom,
            dateTo,
            offerId,
            specificDate,
            offerCompanyId,
        },
    });
    return response.data;
}
