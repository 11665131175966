import { call, put } from 'redux-saga/effects'
import { history } from '@src/app/index'
import { client as axios } from '@src/api/clients/axios'
import URI from 'urijs'
import { notification } from 'antd'
import config from 'config'
import actions from '../actions'

export function* offerFeedbackStart() {
	const uri = new URI(`${config.api}/0/offer-feedback`)
	const apiUri = uri.toString()

	const postData = {
		feedback_token: history.getQuery().feedback_token,
		is_positive: history.getQuery().is_positive,
	}

	const postFeedback = data =>
		axios
			.post(apiUri, data, { withCredentials: true })
			.then(res => res)
			.catch(err => {
				throw err
			})

	try {
		yield call(postFeedback, postData)
		yield put(actions.offerFeedbackSuccess())
	} catch (err) {
		yield put(actions.offerFeedbackError(err))
	}
}

export function* offerFeedbackError(err) {
	const showError = error => {
		notification.error({
			message: 'Chyba',
			description: error.payload.response.data.error,
		})
	}
	yield call(showError, err)
}

export function* offerFeedbackSuccess() {
	const showNotification = () => {
		notification.success({
			message: 'Upozornenie',
			description: 'Ďakujeme za spätnú väzbu',
		})
	}
	yield call(showNotification)
}
