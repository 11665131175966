"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetUsage = exports.FilterAssetTypeSelect = exports.AssetLibraryHeaderRow = exports.AssetLibraryHeaderContainer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const elements_1 = require("@src/components/elements");
exports.AssetLibraryHeaderContainer = styled_components_1.default.div `
	margin-bottom: 2rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid ${props => props.theme.color.gray4};
`;
exports.AssetLibraryHeaderRow = styled_components_1.default.div `
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 1rem;
	gap: 1rem 2rem;
	align-items: center;
`;
exports.FilterAssetTypeSelect = (0, styled_components_1.default)(elements_1.Select) `
	width: 200px;
`;
exports.AssetUsage = styled_components_1.default.div `
	display: flex;
	align-items: center;
	${props => props.shouldDisplayInRed
    ? (0, styled_components_1.css) `
					color: ${props.theme.color.error};
			  `
    : (0, styled_components_1.css) `
					color: ${props.theme.color.success};
			  `}
`;
