"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const theme_1 = require("@src/styles/theme");
const renderer_1 = require("@react-pdf/renderer");
renderer_1.Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: '/media/fonts/Roboto-Regular.ttf',
        },
        {
            src: '/media/fonts/Roboto-Bold.ttf',
            fontWeight: 'bold',
        },
        {
            src: '/media/fonts/Roboto-Light.ttf',
            fontWeight: 'light',
        },
    ],
});
const text = {
    fontFamily: 'Roboto',
    fontSize: 10,
};
exports.styles = renderer_1.StyleSheet.create({
    page: {
        padding: '16px 0',
    },
    verticalDivider: {
        height: '100%',
        border: '0.25px solid gray',
    },
    leftBox: {
        padding: '0 16px',
        display: 'flex',
        flexDirection: 'column',
        width: '35vw',
    },
    rightBox: {
        padding: '0 16px',
        display: 'flex',
        flexDirection: 'column',
        width: '64vw',
    },
    shadowBox: {
        width: '100%',
        backgroundColor: theme_1.theme.color.gray2,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 12,
        display: 'flex',
        flexGrow: 1,
    },
    name: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 20,
        color: '#1E357B',
        padding: '16px 0 24px 0',
        textAlign: 'center',
    },
    image: {
        width: 100,
        height: 100,
    },
    title: {
        fontFamily: 'Roboto',
        fontSize: 13,
        color: theme_1.theme.color.brand1,
        marginBottom: 8,
    },
    experienceSubtitle: {
        fontFamily: 'Roboto',
        fontSize: 13,
        color: theme_1.theme.color.gray8,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    sectionRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    sectionRowWrap: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    text: {
        ...text,
    },
    shadowText: {
        ...text,
        color: theme_1.theme.color.gray8,
    },
    aboutMainText: {
        fontFamily: 'Roboto',
        fontSize: 9,
        fontWeight: 'bold',
        color: theme_1.theme.color.gray8,
        marginBottom: 4,
    },
    aboutSecondaryText: {
        fontFamily: 'Roboto',
        fontSize: 9,
        color: theme_1.theme.color.gray6,
    },
    typeTitle: {
        color: theme_1.theme.color.brand1,
        fontSize: 18,
        fontFamily: 'Roboto',
    },
    experienceDate: {
        color: theme_1.theme.color.gray5,
        fontSize: 12,
        fontFamily: 'Roboto',
    },
    divider: {
        width: '100%',
        border: '0.25px solid gray',
        margin: '14 0',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 8,
    },
    languageText: {
        flex: 1,
        fontSize: 11,
        fontFamily: 'Roboto',
        color: theme_1.theme.color.gray8,
    },
    capitalized: {
        textTransform: 'capitalize',
    },
    lowerCase: {
        textTransform: 'lowercase',
    },
    tag: {
        marginRight: 8,
        border: `1px solid ${theme_1.theme.color.gray5}`,
        backgroundColor: theme_1.theme.color.gray2,
        padding: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
        borderBottomLeftRadius: 3,
    },
    tagText: {
        fontSize: 9,
        fontFamily: 'Roboto',
        color: theme_1.theme.color.black,
    },
    skillTagTextWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    skillTagShadowText: {
        fontSize: 9,
        fontFamily: 'Roboto',
        color: theme_1.theme.color.gray6,
        paddingTop: 4,
        paddingBottom: 4,
    },
    skillsSection: {
        marginTop: 18,
        display: 'flex',
    },
    skillLineRange: {
        height: 5,
        backgroundColor: theme_1.theme.color.gray4,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
        borderBottomLeftRadius: 2,
        marginTop: 2,
    },
    skillLine: {
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
        borderBottomLeftRadius: 2,
        height: 5,
        backgroundColor: theme_1.theme.color.gray8,
    },
    skillsWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    skillItem: {
        marginTop: 4,
    },
});
