"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRoute = useRoute;
const react_router_1 = require("react-router");
const localized_path_1 = require("@src/shared/localized-path");
const Routes_1 = require("@src/router/Routes");
function useRoute() {
    const location = (0, react_router_1.useLocation)();
    const route = (0, localized_path_1.findMatchingRoute)(Routes_1.routes, location.pathname);
    return route;
}
