import React from 'react'

const ProfessionManConstructor = () => (
	<svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
			<path
				d="M2.25 3.75h7.5M11.75 11.75a2 2 0 0 0-1.3-1.873L8 9.056M4 9.055l-2.45.822a2 2 0 0 0-1.3 1.873M5.24 7a.983.983 0 0 0 .679.25h.142A.984.984 0 0 0 6.74 7M5.5 5a.544.544 0 0 0-.5-.25.544.544 0 0 0-.5.25M7.5 5a.544.544 0 0 0-.5-.25.544.544 0 0 0-.5.25M2.25 9.642v2.108M9.75 9.642v2.108"
				strokeWidth=".5"
			/>
			<path
				d="M5.168.825C2.953 1.25 2.767 3.409 2.99 4.75c-.437.218-.3 1.2.24 1.444a.5.5 0 0 1 .287.373C3.738 7.89 5.293 8.75 5.99 8.75s2.25-.86 2.474-2.183a.5.5 0 0 1 .286-.373c.541-.245.677-1.226.24-1.444.224-1.341.053-3.5-2.158-3.925"
				strokeWidth=".5"
			/>
			<path
				d="M7.25 3.75h-2.5L5.189.68a.5.5 0 0 1 .495-.43h.633a.5.5 0 0 1 .495.43ZM3.25 9.306v2.444M8.75 9.306v2.444M3.25 10.75h5.5v1h-5.5zM6 10.75v-1M6 2.75v-1"
				strokeWidth=".5"
			/>
		</g>
	</svg>
)

export default ProfessionManConstructor
