"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPasswordRepeatRules = exports.getCurrentPasswordRules = exports.getPasswordRules = void 0;
const getPasswordRules = (intl) => [
    {
        required: true,
        message: intl.formatMessage({
            id: 'profile.sync_validation.password_is_short',
        }),
    },
    {
        min: 8,
        message: intl.formatMessage({
            id: 'profile.sync_validation.password_is_short',
        }),
    },
    {
        pattern: /^.*(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
        message: intl.formatMessage({
            id: 'profile.sync_validation.password_must_contain',
        }),
    },
];
exports.getPasswordRules = getPasswordRules;
const getCurrentPasswordRules = (intl) => [
    {
        required: true,
        message: intl.formatMessage({
            id: 'profile.sync_validation.for_saving_changes',
        }),
    },
];
exports.getCurrentPasswordRules = getCurrentPasswordRules;
const getPasswordRepeatRules = (intl) => [
    form => ({
        message: intl.formatMessage({
            id: 'profile.sync_validation.for_saving_changes',
        }),
        validator: (_, value) => (value === form.getFieldValue('password') ? Promise.resolve() : Promise.reject()),
    }),
];
exports.getPasswordRepeatRules = getPasswordRepeatRules;
