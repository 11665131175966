"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConfig = useConfig;
const react_intl_1 = require("react-intl");
const config_1 = require("@src/config");
function useConfig() {
    const intl = (0, react_intl_1.useIntl)();
    const config = (0, config_1.getConfig)(intl.locale);
    return config;
}
