"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Queries = void 0;
var Queries;
(function (Queries) {
    Queries["getSavedOffers"] = "getSavedOffers";
    Queries["getMyResumes"] = "getMyResumes";
    Queries["getHiringProcessStages"] = "getHiringProcessStages";
    Queries["getMyAssets"] = "getMyAssets";
    Queries["getCharts"] = "getCharts";
    Queries["getAnalyticsData"] = "getAnalyticsData";
    Queries["getCompanyPremiumSettings"] = "companyPremiumSettings";
    Queries["getOrder"] = "getOrder";
    Queries["getAllPromotedOffers"] = "getAllPromotedOffers";
    Queries["getPaymentMethods"] = "getPaymentMethods";
    Queries["getAssetById"] = "getAssetById";
    Queries["getCompanyPremiumSettingsByUrl"] = "getCompanyPremiumSettingsByUrl";
    Queries["getCompanyById"] = "getCompanyById";
    Queries["getCompany"] = "getCompany";
    Queries["getMyOffers"] = "getMyOffers";
    Queries["getAccountsCount"] = "getAccountsCount";
    Queries["getCompaniesCount"] = "getCompaniesCount";
    Queries["getOffersCount"] = "getOffersCount";
    Queries["getResponsesCount"] = "getResponsesCount";
})(Queries || (exports.Queries = Queries = {}));
