"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.registrationCandidateFormRules = void 0;
const Api = __importStar(require("@src/api"));
const antd_1 = require("antd");
const types_1 = require("@src/types");
async function failIfUserExists(email) {
    try {
        await Api.Account.getAccountExistsByEmail(email);
        throw new Error('exists');
    }
    catch (err) {
        if (err.message === 'exists') {
            throw err;
        }
    }
}
const registrationCandidateFormRules = intl => {
    const firstNameRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.first_name',
            }),
        },
        {
            // eslint-disable-next-line
            pattern: /^[^0-9_~`!@#$%^&*(),.;<>?={}\[\]\\\/]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_first_name',
            }),
        },
    ];
    const lastNameRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.last_name',
            }),
        },
        {
            // eslint-disable-next-line
            pattern: /^[^0-9_~`!@#$%^&*(),.;<>?{}\[\]\\\/]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_last_name',
            }),
        },
    ];
    const passwordRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.password',
            }),
        },
        {
            min: 8,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_length_password',
            }),
        },
        {
            pattern: /^.*(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_password',
            }),
        },
    ];
    const repeatPasswordRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration_student.sync_validation.fill_repeate_password_field',
            }),
        },
        ({ getFieldValue }) => ({
            validator(_, value) {
                if (value && getFieldValue(types_1.CandidateRegistrationFormField.password) === value) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error(intl.formatMessage({
                    id: 'registration.sync_validation.passwordRepeat',
                })));
            },
        }),
    ];
    const agreementRules = [
        {
            validator: (_, value) => {
                return value
                    ? Promise.resolve()
                    : Promise.reject(new Error(intl.formatMessage({
                        id: 'registration.sync_validation.protection',
                    })));
            },
        },
    ];
    const emailRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.email',
            }),
        },
        {
            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.invalid_email',
            }),
        },
        {
            validator: async (_, value) => new Promise((resolve, reject) => {
                failIfUserExists(value)
                    .then(() => {
                    resolve(null);
                })
                    .catch(() => {
                    antd_1.notification.warning({
                        message: intl.formatMessage({
                            id: 'registration.async_validation.email_taken',
                        }),
                        description: intl.formatMessage({
                            id: 'registration_student.async_validation.email_used',
                        }),
                    });
                    reject(new Error(intl.formatMessage({
                        id: 'registration.async_validation.email_taken',
                    })));
                });
            }),
        },
    ];
    const telephoneRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.telephone',
            }),
        },
        {
            max: 14,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_long_telephone',
            }),
        },
        {
            min: 9,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_short_telephone',
            }),
        },
        {
            pattern: /^[^A-Za-z_~`!@#$%^&*(),.;<>?]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_title_after',
            }),
        },
    ];
    return {
        firstNameRules,
        lastNameRules,
        emailRules,
        passwordRules,
        repeatPasswordRules,
        agreementRules,
        telephoneRules,
    };
};
exports.registrationCandidateFormRules = registrationCandidateFormRules;
