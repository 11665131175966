"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEmailRulesWithAsync = exports.getEmailRules = exports.getEmailExistenceRule = exports.getEmailFormatRule = exports.getRequiredEmailRule = void 0;
const Api = __importStar(require("@src/api"));
async function failIfUserExists(email) {
    try {
        await Api.Account.getAccountExistsByEmail(email);
        throw new Error('exists');
    }
    catch (err) {
        if (err.message === 'exists') {
            throw err;
        }
    }
}
const getRequiredEmailRule = (intl) => [
    {
        required: true,
        message: intl.formatMessage({
            id: 'offer_detail.sync_validation.fill_email_field',
        }),
    },
];
exports.getRequiredEmailRule = getRequiredEmailRule;
const getEmailFormatRule = (intl) => [
    {
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
        message: intl.formatMessage({
            id: 'offer_detail.sync_validation.fill_valid_email',
        }),
    },
];
exports.getEmailFormatRule = getEmailFormatRule;
const getEmailExistenceRule = (intl) => [
    {
        validator: async (_, value) => new Promise((resolve, reject) => {
            failIfUserExists(value)
                .then(() => {
                resolve(null);
            })
                .catch(() => reject(new Error(intl.formatMessage({
                id: 'profile.async_validation.this_email_is_taken',
            }))));
        }),
    },
];
exports.getEmailExistenceRule = getEmailExistenceRule;
const getEmailRules = (intl) => [...(0, exports.getRequiredEmailRule)(intl), ...(0, exports.getEmailFormatRule)(intl)];
exports.getEmailRules = getEmailRules;
const getEmailRulesWithAsync = (intl) => [...(0, exports.getEmailExistenceRule)(intl), ...(0, exports.getEmailRules)(intl)];
exports.getEmailRulesWithAsync = getEmailRulesWithAsync;
