"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSavedOffer = createSavedOffer;
exports.getSavedOffers = getSavedOffers;
exports.deleteSavedOffer = deleteSavedOffer;
exports.getSavedOfferById = getSavedOfferById;
exports.deleteSavedOffers = deleteSavedOffers;
const axios_1 = require("./clients/axios");
async function createSavedOffer(offerId) {
    const response = await axios_1.client.post('/0/save-offer', { offerId });
    return response.data;
}
async function getSavedOffers() {
    const response = await axios_1.client.get('/0/save-offer');
    return response.data;
}
async function deleteSavedOffer(offerId) {
    const response = await axios_1.client.delete(`/0/save-offer/${offerId}`);
    return response.data;
}
async function getSavedOfferById(offerId) {
    const response = await axios_1.client.get(`/0/save-offer/${offerId}`, {
        params: { offerId },
    });
    return response.data;
}
async function deleteSavedOffers(query) {
    const response = await axios_1.client.delete(`/0/save-offer/all`, {
        params: query,
    });
    return response.data;
}
