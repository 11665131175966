"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocketEvents = void 0;
var SocketEvents;
(function (SocketEvents) {
    SocketEvents["ACCOUNT_AUTHENTICATED"] = "ACCOUNT_AUTHENTICATED";
    SocketEvents["ACCOUNT_LOG_OUT"] = "ACCOUNT_LOG_OUT";
    SocketEvents["ACCOUNT_REGISTERED"] = "ACCOUNT_REGISTERED";
    SocketEvents["ACCOUNT_VERIFIED"] = "ACCOUNT_VERIFIED";
    SocketEvents["NOTIFICATION_RECEIVED"] = "NOTIFICATION_RECEIVED";
    SocketEvents["MY_OFFERS_UPDATED"] = "MY_OFFERS_UPDATED";
    SocketEvents["UPDATE_COMPANY_INFO"] = "UPDATE_COMPANY_INFO";
    SocketEvents["APPLICATION_STATUS_UPDATED"] = "APPLICATION_STATUS_UPDATED";
})(SocketEvents || (exports.SocketEvents = SocketEvents = {}));
