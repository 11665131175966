"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSalaryFormatter = void 0;
const app_context_1 = require("@src/context/app-context");
const react_intl_1 = require("react-intl");
const useSalaryFormatter = () => {
    const { language } = (0, app_context_1.useAppContextValue)();
    const intl = (0, react_intl_1.useIntl)();
    const formatSalary = ({ rate, amountFrom, amountTo, currency, salaryType }) => {
        const amountFromString = amountFrom === null || amountFrom === void 0 ? void 0 : amountFrom.toLocaleString(language);
        const amountToString = amountTo === null || amountTo === void 0 ? void 0 : amountTo.toLocaleString(language);
        const rates = {
            hourly: intl.formatMessage({ id: 'offer_detail.details_model.one_hours' }),
            monthly: intl.formatMessage({ id: 'offer_detail.details_model.one_month' }),
            once: intl.formatMessage({ id: 'offer_detail.details_model.once' }),
        };
        const salaryTypeString = salaryType
            ? intl.formatMessage({ id: `offer_detail.details_model.salary_type.${salaryType}` })
            : '';
        let result = `${amountFromString} - ${amountToString} ${currency}`;
        if (!amountToString) {
            result = `${intl.formatMessage({
                id: 'offer_detail.salary.from',
            })} ${amountFromString} ${currency}`;
        }
        if (amountFromString === amountToString) {
            result = `${amountFromString} ${currency}`;
        }
        return `${result} / ${rates[rate]} ${salaryTypeString}`;
    };
    return { formatSalary };
};
exports.useSalaryFormatter = useSalaryFormatter;
