"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationSenderAccountTypeMap = exports.NotificationType = void 0;
const account_1 = require("./account");
var NotificationType;
(function (NotificationType) {
    NotificationType[NotificationType["COMPANY_SUBSCRIPTION_NOTIFICATION"] = 1] = "COMPANY_SUBSCRIPTION_NOTIFICATION";
    NotificationType[NotificationType["OFFER_SUBSCRIPTION_NOTIFICATION"] = 2] = "OFFER_SUBSCRIPTION_NOTIFICATION";
    NotificationType[NotificationType["NEW_COMPANY_SUBSCRIBER_NOTIFICATION"] = 3] = "NEW_COMPANY_SUBSCRIBER_NOTIFICATION";
    NotificationType[NotificationType["HIRING_PROCESS_RESULT_NOTIFICATION"] = 4] = "HIRING_PROCESS_RESULT_NOTIFICATION";
    NotificationType[NotificationType["HIRING_PROCESS_CLOSED_NOTIFICATION"] = 5] = "HIRING_PROCESS_CLOSED_NOTIFICATION";
    NotificationType[NotificationType["NEW_OFFER_APPLICATION_NOTIFICATION"] = 6] = "NEW_OFFER_APPLICATION_NOTIFICATION";
    NotificationType[NotificationType["NEW_CANDIDATE_INTERVIEW_NOTIFICATION"] = 7] = "NEW_CANDIDATE_INTERVIEW_NOTIFICATION";
    NotificationType[NotificationType["COMPANY_VIEWED_RESUME_NOTIFICATION"] = 8] = "COMPANY_VIEWED_RESUME_NOTIFICATION";
    NotificationType[NotificationType["CREDITS_EXPIRATION_NOTIFICATION"] = 9] = "CREDITS_EXPIRATION_NOTIFICATION";
    NotificationType[NotificationType["COMPANY_OFFER_DEADLINE_NOTIFICATION"] = 10] = "COMPANY_OFFER_DEADLINE_NOTIFICATION";
    NotificationType[NotificationType["CREDITS_PAID_NOTIFICATION"] = 11] = "CREDITS_PAID_NOTIFICATION";
})(NotificationType || (exports.NotificationType = NotificationType = {}));
exports.NotificationSenderAccountTypeMap = {
    [NotificationType.COMPANY_SUBSCRIPTION_NOTIFICATION]: account_1.AccountType.company,
    [NotificationType.OFFER_SUBSCRIPTION_NOTIFICATION]: account_1.AccountType.company,
    [NotificationType.HIRING_PROCESS_RESULT_NOTIFICATION]: account_1.AccountType.company,
    [NotificationType.NEW_CANDIDATE_INTERVIEW_NOTIFICATION]: account_1.AccountType.company,
    [NotificationType.COMPANY_VIEWED_RESUME_NOTIFICATION]: account_1.AccountType.company,
    [NotificationType.COMPANY_OFFER_DEADLINE_NOTIFICATION]: account_1.AccountType.company,
    [NotificationType.NEW_COMPANY_SUBSCRIBER_NOTIFICATION]: account_1.AccountType.applicant,
    [NotificationType.HIRING_PROCESS_CLOSED_NOTIFICATION]: account_1.AccountType.applicant,
    [NotificationType.NEW_OFFER_APPLICATION_NOTIFICATION]: account_1.AccountType.applicant,
    [NotificationType.CREDITS_EXPIRATION_NOTIFICATION]: account_1.AccountType.applicant,
    [NotificationType.CREDITS_PAID_NOTIFICATION]: account_1.AccountType.applicant,
};
