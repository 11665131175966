import React from 'react'

const SailingPerson = () => (
	<svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M.75 3.495a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 1 0-2.5 0ZM5.019.588l3.011 9a.25.25 0 0 0 .464.017 3.33 3.33 0 0 1 2.955-2.096.25.25 0 0 0 .23-.308C11.34 5.879 9.692.535 5.268.256a.25.25 0 0 0-.248.332Zm1.558 4.657h4.434m-4.936-1.5h4.183m-5.203 6.366a1.242 1.242 0 0 0-.884-.366H3a.75.75 0 0 1-.697-.471l-1-2.5A.75.75 0 0 1 2 5.745h1.275a1.255 1.255 0 0 0 .8-.289l.944-.787a.75.75 0 0 1 .96 1.15l-.944.789a2.753 2.753 0 0 1-1.76.637h-.168l.4 1h.663a2.732 2.732 0 0 1 1.945.805l.685.781m4.95 1.858c-1.25.26-2.03-.99-2.03-.99h0a2.2 2.2 0 0 1-1.81 1.029A1.911 1.911 0 0 1 6.25 10.7h0a2.641 2.641 0 0 1-1.961 1.028A2.3 2.3 0 0 1 2.48 10.7h0s-1 1.271-2.228 1.01"
			strokeWidth=".5"
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default SailingPerson
