import React from 'react'

const BusinessDealHandshake = () => (
	<svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
			<path
				d="m7.25 4.125-1.783.586a.594.594 0 0 1-.45-1.095l1.727-.863A1.214 1.214 0 0 1 7.7 2.7l2.55.925v3.5l-3.003.57"
				strokeWidth=".5"
			/>
			<path
				d="M1.75 7.125H3l1.795 2.012A.685.685 0 0 0 6 8.69v-.315l.09.036a.847.847 0 0 0 1.16-.786h.25a.75.75 0 0 0 .6-1.2l-1.6-2.05M5.276 3.485l-.183-.15A1.072 1.072 0 0 0 4.058 3.2l-2.308.925M.25 3.125h1a.5.5 0 0 1 .5.5v3.5a.5.5 0 0 1-.5.5h-1M11.75 7.625h-1a.5.5 0 0 1-.5-.5v-3.5a.5.5 0 0 1 .5-.5h1M6 8.375l-.5-.5M6.5 6.875l.75.75"
				strokeWidth=".5"
			/>
		</g>
	</svg>
)

export default BusinessDealHandshake
