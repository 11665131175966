import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { useIntl, injectIntl } from 'react-intl'
// field components
import showError from 'components/modules/forms/showError'
import renderField from 'components/modules/forms/renderField'
import validate from '../validation/sync-validation'
import Button from '../../../modules/global/button'

let ResetPasswordEmailFormComponent = props => {
	const { handleSubmit, resetPasswordEmailError } = props

	const intl = useIntl()

	return (
		<form onSubmit={handleSubmit} className="form form--reset-password-email">
			<Field
				component={renderField}
				type="email"
				name="email"
				label={intl.formatMessage({
					id: 'reset_password_email.reset_password_email.email',
					defaultMessage: 'Email',
				})}
				error={resetPasswordEmailError}
			/>
			<div className="form__submit">
				<Button
					type="submit"
					color="brand"
					text={intl.formatMessage({
						id: 'reset_password_email.reset_password_email.password_recovery',
						defaultMessage: 'Obnoviť heslo k účtu',
					})}
					disabled={props.submitting}
				/>
			</div>
		</form>
	)
}

ResetPasswordEmailFormComponent = reduxForm({
	form: 'resetPasswordEmailForm',
	destroyOnUnmount: false,
	validate,
	onSubmitFail: showError,
})(ResetPasswordEmailFormComponent)

const ResetPasswordEmailForm = ResetPasswordEmailFormComponent

export default injectIntl(ResetPasswordEmailForm)
