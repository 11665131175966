"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyCloserInformationsFields = exports.SocialLinksFields = exports.CinTinFields = exports.UploadFields = exports.AddressFields = void 0;
var AddressFields_1 = require("./AddressFields");
Object.defineProperty(exports, "AddressFields", { enumerable: true, get: function () { return AddressFields_1.AddressFields; } });
var UploadFields_1 = require("./UploadFields");
Object.defineProperty(exports, "UploadFields", { enumerable: true, get: function () { return UploadFields_1.UploadFields; } });
var CinTinFields_1 = require("./CinTinFields");
Object.defineProperty(exports, "CinTinFields", { enumerable: true, get: function () { return CinTinFields_1.CinTinFields; } });
var SocialLinksFields_1 = require("./SocialLinksFields");
Object.defineProperty(exports, "SocialLinksFields", { enumerable: true, get: function () { return SocialLinksFields_1.SocialLinksFields; } });
var CompanyCloserInformationsFields_1 = require("./CompanyCloserInformationsFields");
Object.defineProperty(exports, "CompanyCloserInformationsFields", { enumerable: true, get: function () { return CompanyCloserInformationsFields_1.CompanyCloserInformationsFields; } });
