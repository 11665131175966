"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetDocumentFileFormat = exports.AssetType = void 0;
var AssetType;
(function (AssetType) {
    AssetType["IMAGE"] = "IMAGE";
    AssetType["VIDEO"] = "VIDEO";
    AssetType["DOCUMENT"] = "DOCUMENT";
})(AssetType || (exports.AssetType = AssetType = {}));
var AssetDocumentFileFormat;
(function (AssetDocumentFileFormat) {
    AssetDocumentFileFormat["PDF"] = "PDF";
    AssetDocumentFileFormat["WORD"] = "WORD";
    AssetDocumentFileFormat["EXCEL"] = "EXCEL";
    AssetDocumentFileFormat["OTHER"] = "OTHER";
})(AssetDocumentFileFormat || (exports.AssetDocumentFileFormat = AssetDocumentFileFormat = {}));
