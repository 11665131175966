import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Col, Row } from 'antd'
import { Holder, Title, Panel } from '@src/components/elements'
import ResetPasswordEmailForm from './modules/reset-password-email'
import actions from '../../../actions'

const ResetPasswordEmail = props => {
	return (
		<Holder marginVertical>
			<Row justify="center">
				<Col>
					<Title level={1} type="secondary">
						<FormattedMessage
							id="reset_password_email.password_recovery"
							defaultMessage="Obnovenie zabudnutého hesla"
						/>
					</Title>
				</Col>
			</Row>
			<Row justify="center">
				<Col xs={24} md={18} lg={12}>
					<Panel>
						<ResetPasswordEmailForm
							onSubmit={props.handleSubmit}
							resetPasswordEmailError={props.resetPasswordEmailError}
						/>
					</Panel>
				</Col>
			</Row>
		</Holder>
	)
}

const mapStateToProps = state => ({
	resetPasswordEmailError: state.login.resetPasswordEmailError,
})

const mapDispatchToProps = dispatch => ({
	handleSubmit: values => {
		dispatch(actions.resetPasswordEmailStart(values))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordEmail)
