import { call, put } from 'redux-saga/effects'
import * as Cookies from 'js-cookie'
import config from 'config'
import actions from '../actions'

export function* mainInitStart() {
	try {
		// handle cookie consent
		const cookieConsentValue = Cookies.get('cookieConsent')
		if (!cookieConsentValue) {
			yield put(actions.cookieConsentError())
		}
		yield put(actions.mainInitSuccess())
	} catch (err) {
		yield put(actions.mainInitError())
	}
}

export function* cookieConsentStart() {
	const setCookie = () => {
		Cookies.set('cookieConsent', 'true', {
			expires: config.cookieConsentDuration,
		})
	}
	try {
		yield call(setCookie)
		yield put(actions.cookieConsentSuccess())
	} catch (err) {
		console.error(err)
		yield put(actions.cookieConsentError(err))
	}
}

export function* cookieConsentError(err) {
	const throwErr = error => {
		console.error(error)
	}
	yield call(throwErr, err)
}
