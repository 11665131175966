"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPartners = getPartners;
exports.getTeam = getTeam;
exports.getTestimonials = getTestimonials;
const axios_1 = require("../clients/axios");
async function getPartners() {
    const response = await axios_1.client.get('/0/cms/web-partners');
    return response.data;
}
async function getTeam() {
    const response = await axios_1.client.get('/0/cms/web-team');
    return response.data;
}
async function getTestimonials({ locale = 'sk' }) {
    const response = await axios_1.client.get('/0/cms/web-testimonials', {
        headers: {
            'Accept-Language': locale,
        },
    });
    return response.data;
}
