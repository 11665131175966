"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setStage = setStage;
exports.updateViewedAt = updateViewedAt;
exports.sendGroupedMessage = sendGroupedMessage;
exports.getMyOfferApplicationsStatuses = getMyOfferApplicationsStatuses;
exports.getMyOfferApplicationStatus = getMyOfferApplicationStatus;
exports.getOfferApplicationByOfferId = getOfferApplicationByOfferId;
exports.updateGroupViewedAt = updateGroupViewedAt;
exports.setStageGroup = setStageGroup;
exports.getAllOfferApplicationsCount = getAllOfferApplicationsCount;
const axios_1 = require("./clients/axios");
async function setStage(id, stage) {
    try {
        const response = await axios_1.client.put(`/0/offer-application/${id}/set-stage`, { stage });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function updateViewedAt(id, viewedAt) {
    try {
        const response = await axios_1.client.patch(`/0/offer-application/${id}`, { viewed_at: viewedAt });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function sendGroupedMessage(groups, language) {
    try {
        const response = await axios_1.client.put(`/0/offer-application/send-grouped-message`, { groups }, {
            params: { language },
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getMyOfferApplicationsStatuses(page) {
    try {
        const response = await axios_1.client.get(`/0/offer-application/statuses`, { params: { page } });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getMyOfferApplicationStatus(applicationId) {
    try {
        const response = await axios_1.client.get(`/0/offer-application/status`, {
            params: { applicationId },
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getOfferApplicationByOfferId(offerId) {
    const response = await axios_1.client.get(`/0/offer-application/${offerId}`);
    return response.data;
}
async function updateGroupViewedAt(offerApplicationIds, viewedAt, offerId) {
    const response = await axios_1.client.patch(`/0/offer-application/viewed-at-group`, {
        viewedAt,
        offerApplicationIds,
        offerId,
    });
    return response.data;
}
async function setStageGroup(offerApplicationIds, stage, offerId) {
    const response = await axios_1.client.patch('/0/offer-application/set-stage-group', {
        offerApplicationIds,
        stage,
        offerId,
    });
    return response.data;
}
async function getAllOfferApplicationsCount() {
    return (await axios_1.client.get('/0/offer-application/count-all')).data;
}
