"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAssetLibraryNotifications = useAssetLibraryNotifications;
const react_intl_1 = require("react-intl");
const antd_1 = require("antd");
// Using notification keys to pevent showing multiple instances of same notification in case of bulk upload.
// Notifications with keys will be displayed only once
const ASSET_NOTIFICATION_KEY_ASSET_COUNT_LIMIT_REACHED = 'ASSET_NOTIFICATION_KEY_ASSET_COUNT_LIMIT_REACHED';
const ASSET_NOTIFICATION_KEY_GENERAL_ERROR = 'ASSET_NOTIFICATION_KEY_GENERAL_ERROR';
const ASSET_NOTIFICATION_KEY_FEATURE_DISABLED = 'ASSET_NOTIFICATION_KEY_FEATURE_DISABLED';
function useAssetLibraryNotifications() {
    const intl = (0, react_intl_1.useIntl)();
    function showInvalidFileTypeNotification(filename) {
        antd_1.notification.error({
            message: intl.formatMessage({ id: 'assets.notifications.invalid_format.title' }),
            description: intl.formatMessage({ id: 'assets.notifications.invalid_format.description' }, { filename }),
        });
    }
    function showAssetFileTooLargeNotification(filename, maxFileSizeInMb) {
        antd_1.notification.error({
            message: intl.formatMessage({ id: 'assets.notifications.file_too_large.title' }),
            description: intl.formatMessage({ id: 'assets.notifications.file_too_large.description' }, { filename, maxFileSizeInMb }),
        });
    }
    function showAssetCountLimitReachedNotification(maxAssetCount) {
        antd_1.notification.error({
            message: intl.formatMessage({ id: 'assets.notifications.reached_count_limit.title' }),
            description: intl.formatMessage({ id: 'assets.notifications.reached_count_limit.description' }, { maxAssetCount }),
            key: ASSET_NOTIFICATION_KEY_ASSET_COUNT_LIMIT_REACHED,
        });
    }
    function showGeneralErrorNotification() {
        antd_1.notification.error({
            message: intl.formatMessage({ id: 'general.error' }),
            description: intl.formatMessage({ id: 'general.something_went_wrong' }),
            key: ASSET_NOTIFICATION_KEY_GENERAL_ERROR,
        });
    }
    function showAssetsFeatureDisabledNotification() {
        antd_1.notification.error({
            message: intl.formatMessage({ id: 'assets.notifications.assets_disabled.title' }),
            description: intl.formatMessage({ id: 'assets.notifications.assets_disabled.description' }),
            key: ASSET_NOTIFICATION_KEY_FEATURE_DISABLED,
        });
    }
    function showAssetsSuccessfullyDeletedNotification(deletedAssetCount) {
        antd_1.notification.success({
            message: intl.formatMessage({ id: 'assets.notifications.assets_deleted.title' }),
            description: intl.formatMessage({ id: 'assets.notifications.assets_deleted.description' }, { deletedAssetCount }),
        });
    }
    function showVideoProcessingFailedNotification(filename) {
        antd_1.notification.error({
            message: intl.formatMessage({ id: 'assets.notifications.video_processing_failed.title' }),
            description: intl.formatMessage({ id: 'assets.notifications.video_processing_failed.description' }, { filename }),
        });
    }
    return {
        showInvalidFileTypeNotification,
        showAssetFileTooLargeNotification,
        showAssetCountLimitReachedNotification,
        showGeneralErrorNotification,
        showAssetsFeatureDisabledNotification,
        showAssetsSuccessfullyDeletedNotification,
        showVideoProcessingFailedNotification,
    };
}
