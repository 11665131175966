"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetCardFooterText = exports.AssetCardFooter = exports.SelectedAssetOrder = exports.CheckCircleSlot = exports.AssetCardContent = exports.AssetCardOverlay = exports.AssetCardContainer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colorSelected = '#1890ff';
exports.AssetCardContainer = styled_components_1.default.div `
	cursor: pointer;
	position: relative;
	height: 245px;
	width: 245px;
	border-radius: ${props => props.theme.borderRadius.large};
	background-color: ${props => props.theme.color.gray9};
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
	${props => props.shouldHaveLowerOpacity &&
    (0, styled_components_1.css) `
			opacity: 0.75;
		`};
	${props => props.shouldHaveColoredOutline &&
    (0, styled_components_1.css) `
			outline: 2px solid ${colorSelected};
		`};
`;
exports.AssetCardOverlay = styled_components_1.default.div `
	position: absolute;
	color: ${props => props.theme.color.white};
	width: 100%;
	height: 100%;
	display: flex;
	font-size: ${props => props.theme.fontSizes.m};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgba(64, 64, 64, 0.5);
	opacity: 0;
	border-radius: inherit;
	transition: opacity 0.2s;
	z-index: 1;
	& svg {
		font-size: ${props => props.theme.fontSizes.xxxl};
	}

	${props => props.shouldDisplayOverlay &&
    (0, styled_components_1.css) `
			opacity: 1;
		`}
`;
exports.AssetCardContent = styled_components_1.default.div `
	height: 85%;
	width: 100%;
	border-radius: 8px 8px 0 0;
`;
exports.CheckCircleSlot = styled_components_1.default.div `
	position: absolute;
	background-color: ${props => props.theme.color.white};
	color: ${colorSelected};
	top: 0;
	left: 0.5rem;
	border-radius: 100%;
	height: 1.75rem;
	width: 1.75rem;
	outline: 1px solid ${props => props.theme.color.gray9};
	z-index: 2;
	opacity: 0;
	transition: all 0.2s;
	display: flex;
	align-items: center;
	justify-content: center;

	${props => props.shouldBeDisplayed &&
    (0, styled_components_1.css) `
			opacity: 1;
			top: 0.5rem;
		`}
	${props => props.shouldHaveColoredOutline &&
    (0, styled_components_1.css) `
			outline: 1px solid ${colorSelected};
		`}
	${props => props.isDisabled &&
    (0, styled_components_1.css) `
			cursor: not-allowed;
		`}
	& svg {
		height: 1.75rem;
		width: 1.75rem;
	}
`;
exports.SelectedAssetOrder = styled_components_1.default.div `
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	color: ${({ theme }) => theme.color.white};
	background: ${colorSelected};
	height: 100%;
	border-radius: 100%;
	width: 100%;
`;
exports.AssetCardFooter = styled_components_1.default.div `
	width: 100%;
	height: 15%;
	background-color: ${props => props.theme.color.white};
	border-radius: 0 0 8px 8px;
	font-size: ${props => props.theme.fontSizes.s};
	padding: 0 1.5em;
	display: flex;
	justify-content: center;
	align-items: center;
`;
exports.AssetCardFooterText = styled_components_1.default.div `
	color: ${props => props.theme.color.gray8};
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;
