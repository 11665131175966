"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCompanyByInvitationToken = getCompanyByInvitationToken;
exports.getCompaniesSuggestions = getCompaniesSuggestions;
exports.getCompanyInfo = getCompanyInfo;
exports.registerCompany = registerCompany;
exports.registerCandidate = registerCandidate;
const socket_1 = require("@src/socket");
const types_1 = require("@src/types");
const axios_1 = require("./clients/axios");
async function getCompanyByInvitationToken(invitationToken) {
    try {
        const response = await axios_1.client.get(`/0/company/invitation/${invitationToken}`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getCompaniesSuggestions(searchValue) {
    try {
        const response = await axios_1.client.get(`/0/finstat/autocomplete?query=${searchValue}`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getCompanyInfo(parameterKey, parameterValue) {
    try {
        const response = await axios_1.client.get(`/0/finstat/detail?${parameterKey}=${parameterValue}`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function registerCompany(citValue, companyRegistrationFormData) {
    const wasInvited = Boolean(citValue);
    const params = wasInvited ? { cit: citValue } : {};
    const response = await axios_1.client.post('/0/registration', companyRegistrationFormData, { params });
    socket_1.socket.emit(types_1.SocketEvents.ACCOUNT_REGISTERED, response.data);
    return response.data;
}
async function registerCandidate(candidateFieldsValues) {
    const response = await axios_1.client.post('/0/account', candidateFieldsValues);
    socket_1.socket.emit(types_1.SocketEvents.ACCOUNT_REGISTERED, response.data);
    return response.data;
}
