"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.disabledFromDate = disabledFromDate;
exports.disabledToDate = disabledToDate;
const date_fns_1 = require("date-fns");
function disabledFromDate(currentDate) {
    return currentDate && currentDate > (0, date_fns_1.endOfDay)(new Date());
}
function disabledToDate(currentDate, getFieldValue, path) {
    return currentDate && currentDate < getFieldValue(path);
}
