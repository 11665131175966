"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.login = login;
exports.logout = logout;
exports.linkedinAuthentication = linkedinAuthentication;
const axios_1 = require("./clients/axios");
async function login({ email, password }) {
    try {
        const response = await axios_1.client.post('/0/login', { email, password });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function logout() {
    return (await axios_1.client.get('/0/logout')).data;
}
async function linkedinAuthentication(code) {
    try {
        const response = await axios_1.client.post('/0/auth/linkedin', { code });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
