"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertSelectedLocations = void 0;
const types_1 = require("@src/types");
const convertSelectedLocations = (selectedLocations) => {
    const slovakiaLocationId = 1;
    if (selectedLocations.length === 0) {
        return [types_1.ABROAD, types_1.REMOTE, slovakiaLocationId];
    }
    return selectedLocations;
};
exports.convertSelectedLocations = convertSelectedLocations;
