"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyArea = exports.CompanyName = exports.CompanyNameAndAreaWrapper = exports.CompanyCardContent = exports.ImageBlock = exports.CompanyLogoWrapper = exports.CheckIconWrapper = exports.CompanyCardWrapper = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colorSelected = '#1890ff';
exports.CompanyCardWrapper = styled_components_1.default.div `
	position: relative;
	width: 300px;
	height: 150px;
	border: 3px solid;
	border-color: ${props => props.theme.color.gray2};
	border-radius: 8px;
	cursor: pointer;
	transition: 0.3s;
	background: ${props => props.theme.color.white};

	&:hover {
		border-color: ${props => props.theme.color.gray5};
	}

	${props => props.isSelected &&
    (0, styled_components_1.css) `
			border: 3px solid ${colorSelected};

			&:hover {
				border: 3px solid ${colorSelected};
			}
		`}
`;
exports.CheckIconWrapper = styled_components_1.default.div `
	position: absolute;
	color: ${colorSelected};
	top: 8px;
	right: 8px;

	& svg {
		height: 2rem;
		width: 2rem;
	}
`;
exports.CompanyLogoWrapper = styled_components_1.default.div `
	margin-left: 1rem;
`;
exports.ImageBlock = styled_components_1.default.div `
	background: ${({ theme }) => theme.color.white};
	width: 100px;
	height: 100px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;

	& > * {
		background: ${({ theme }) => theme.color.white};
		max-width: 100%;
		max-height: 100%;
	}
`;
exports.CompanyCardContent = styled_components_1.default.div `
	display: flex;
	align-items: center;
	height: 100%;
`;
exports.CompanyNameAndAreaWrapper = styled_components_1.default.div `
	margin: 1.5rem 1rem 0 1.5rem;
	width: 165px;
	max-height: 120px;
	align-self: flex-start;
	height: 100%;
	display: flex;
	flex-direction: column;
`;
exports.CompanyName = styled_components_1.default.div `
	line-height: 1.4em;
	margin-bottom: 8px;
	font-weight: bold;
`;
exports.CompanyArea = styled_components_1.default.div `
	color: ${props => props.theme.color.gray7};
	font-size: 0.8rem;
	position: relative;
	overflow: hidden;
	height: 100%;
	&:after {
		content: '';
		position: absolute;
		display: block;
		bottom: 0;
		height: 100%;
		width: 100%;
		background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%);
		pointer-events: none;
	}
`;
