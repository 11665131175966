import { createAction } from 'redux-actions'

const actions = {
	cookieConsentStart: createAction('COOKIE_CONSENT_START'),
	cookieConsentSuccess: createAction('COOKIE_CONSENT_SUCCESS'),
	cookieConsentError: createAction('COOKIE_CONSENT_ERROR'),

	contactUsStart: createAction('CONTACT_US_START'),
	contactUsSuccess: createAction('CONTACT_US_SUCCESS'),
	contactUsError: createAction('CONTACT_US_ERROR'),

	companySetCycle: createAction('COMPANY_SET_CYCLE'),

	mainInitStart: createAction('MAIN_INIT_START'),
	mainInitSuccess: createAction('MAIN_INIT_SUCCESS'),
	mainInitError: createAction('MAIN_INIT_ERROR'),

	saveSelectedFilters: createAction('SAVE_SELECTED_FILTERS'),

	loginStart: createAction('LOGIN_START'),
	loginSuccess: createAction('LOGIN_SUCCESS'),
	loginError: createAction('LOGIN_ERROR'),

	resetPasswordEmailStart: createAction('RESET_PASSWORD_EMAIL_START'),
	resetPasswordEmailSuccess: createAction('RESET_PASSWORD_EMAIL_SUCCESS'),
	resetPasswordEmailError: createAction('RESET_PASSWORD_EMAIL_ERROR'),

	loginInfoEditStart: createAction('LOGIN_INFO_EDIT_START'),
	loginInfoEditSuccess: createAction('LOGIN_INFO_EDIT_SUCCESS'),
	loginInfoEditError: createAction('LOGIN_INFO_EDIT_ERROR'),

	loadOfferApplicationStart: createAction('LOAD_OFFER_APPLICATION_START'),
	loadOfferApplicationSuccess: createAction('LOAD_OFFER_APPLICATION_SUCCESS'),
	loadOfferApplicationError: createAction('LOAD_OFFER_APPLICATION_ERROR'),

	myCompanyInfoInitStart: createAction('MY_COMPANY_INFO_INIT_START'),
	myCompanyInfoInitSuccess: createAction('MY_COMPANY_INFO_INIT_SUCCESS'),
	myCompanyInfoInitError: createAction('MY_COMPANY_INFO_INIT_ERROR'),

	logoEditStart: createAction('LOGO_EDIT_START'),
	logoEditSuccess: createAction('LOGO_EDIT_SUCCESS'),
	logoEditError: createAction('LOGO_EDIT_ERROR'),

	adminToggleCreditsReplenishedDialog: createAction('ADMIN_TOGGLE_CREDITS_REPLENISHED_DIALOG'),
	adminClearUpOrder: createAction('ADMIN_CLEAR_UP_ORDER'),

	changeProfileMenuActive: createAction('CHANGE_PROFILE_MENU_ACTIVE'),

	hideAccountProgressNotice: createAction('HIDE_ACCOUNT_PROGRESS_NOTICE'),

	offerFeedbackStart: createAction('OFFER_FEEDBACK_START'),
	offerFeedbackSuccess: createAction('OFFER_FEEDBACK_SUCCESS'),
	offerFeedbackError: createAction('OFFER_FEEDBACK_ERROR'),

	studentFeedbackStart: createAction('STUDENT_FEEDBACK_START'),
	studentFeedbackSuccess: createAction('STUDENT_FEEDBACK_SUCCESS'),
	studentFeedbackError: createAction('STUDENT_FEEDBACK_ERROR'),
}

export default actions
