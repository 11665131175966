import React from 'react'

const Send = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<g>
			<g>
				<path d="M23,.64a.49.49,0,0,0-.17-.51.51.51,0,0,0-.54,0L1.78,10.82A1.45,1.45,0,0,0,1,12.05a1.48,1.48,0,0,0,.68,1.3h0l4.56,2.88a.24.24,0,0,0,.3,0L18.7,5.34a.25.25,0,0,1,.37.34L9.35,17.86a.25.25,0,0,0-.06.2.3.3,0,0,0,.12.17l6.43,4.07a1.48,1.48,0,0,0,1.32.11,1.43,1.43,0,0,0,.87-1Z"></path>
				<path d="M7.88,19.18a.25.25,0,0,0-.25,0,.26.26,0,0,0-.13.22V23a1,1,0,0,0,1,1,1,1,0,0,0,.71-.3l2-2a.27.27,0,0,0,.07-.21.25.25,0,0,0-.12-.18Z"></path>
			</g>
		</g>
	</svg>
)

export default Send
