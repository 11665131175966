import React from 'react'

const HeartHandsHandshake = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
		<g transform="scale(.5)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
			<rect x=".5" y="15.5" width="4" height="7" rx=".5" ry=".5" />
			<path d="M4.5 21c10.528 3.51 7.3 3.611 18.723-2.164a.511.511 0 0 0 .084-.854 2.483 2.483 0 0 0-2.417-.482l-4.955 1.644" />
			<path d="M9.5 20.5H15a1 1 0 0 0 0-2h-3a6.59 6.59 0 0 0-4.5-2h-3" />
			<rect x="19.5" y="1.5" width="4" height="7" rx=".5" ry=".5" />
			<path d="M19.5 3C8.972-.51 12.2-.611.777 5.164a.511.511 0 0 0-.084.854A2.483 2.483 0 0 0 3.11 6.5l4.955-1.644" />
			<path d="M14.5 3.5H9a1 1 0 0 0 0 2h3a6.59 6.59 0 0 0 4.5 2h3M12 10.205c-.856-1.34-3.427-.861-3.427 1.531.045 1.826 2.423 3.28 3.191 3.7a.489.489 0 0 0 .472 0c.768-.423 3.146-1.877 3.191-3.7 0-2.392-2.57-2.871-3.427-1.531Z" />
		</g>
	</svg>
)
export default HeartHandsHandshake
