"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createInvoice = createInvoice;
exports.getInvoiceById = getInvoiceById;
const axios_1 = require("./clients/axios");
async function createInvoice(billingData) {
    const response = await axios_1.client.post('/0/invoice/', billingData);
    return response.data;
}
async function getInvoiceById(invoiceId) {
    const response = await axios_1.client.get('/0/invoice/get-invoice-by-id', { params: { invoiceId } });
    return response.data;
}
