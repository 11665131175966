import actionNames from '../actions'

const defaultState = {
	myOffers: [],
	company: {},
	invoices: [],
	order: {},
	// useRememberedCard: true,
	isOpenCreditsReplenishedDialog: false,
}

export default function admin(state = defaultState, action) {
	switch (action.type) {
		case actionNames.myCompanyInfoInitSuccess().type:
			return { ...state, company: action.payload.data, invoices: action.payload.data.invoices }

		case actionNames.adminToggleCreditsReplenishedDialog().type:
			return { ...state, isOpenCreditsReplenishedDialog: action.payload }

		case actionNames.adminClearUpOrder().type:
			return { ...state, order: {}, company: {} }

		default:
			return state
	}
}
