"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOfferApplications = getOfferApplications;
exports.uploadProfileImage = uploadProfileImage;
exports.removeProfileImage = removeProfileImage;
exports.setMyProfile = setMyProfile;
exports.getCompaniesSuggestion = getCompaniesSuggestion;
exports.getMyProfile = getMyProfile;
exports.getMyResumes = getMyResumes;
exports.uploadResume = uploadResume;
exports.archiveResume = archiveResume;
exports.getEmptyResumeFields = getEmptyResumeFields;
exports.sendResume = sendResume;
exports.sendProfileAsResume = sendProfileAsResume;
exports.getApplicantProfile = getApplicantProfile;
const axios_1 = require("./clients/axios");
async function getOfferApplications() {
    try {
        const response = await axios_1.client.get('/0/offer-applications');
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function uploadProfileImage(image) {
    try {
        const profileImage = new FormData();
        profileImage.append('profile_image', image);
        const response = await axios_1.client.post('/0/account-profile/images', profileImage);
        return response;
    }
    catch (err) {
        throw err;
    }
}
async function removeProfileImage() {
    try {
        const response = await axios_1.client.delete('/0/account-profile/images');
        return response;
    }
    catch (err) {
        throw err;
    }
}
async function setMyProfile(profileData, language) {
    try {
        const response = await axios_1.client.put('/0/account-profile', profileData, {
            params: { language },
        });
        return response;
    }
    catch (err) {
        throw err;
    }
}
async function getCompaniesSuggestion(type, companyName) {
    try {
        const response = await axios_1.client.get(`/0/account-profile/companies-suggestion`, { params: { type, companyName } });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getMyProfile() {
    try {
        const response = await axios_1.client.get('/0/account-profile');
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getMyResumes() {
    try {
        const response = await axios_1.client.get('/0/account-resume');
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function uploadResume(resume) {
    try {
        const data = new FormData();
        data.append('resume', resume);
        const response = await axios_1.client.post('/0/account-resume', data);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function archiveResume(e) {
    try {
        const response = await axios_1.client.put('/0/account-resume', {
            resume_id: e,
            is_archived: 1,
        });
        return await response;
    }
    catch (err) {
        throw err;
    }
}
async function getEmptyResumeFields() {
    try {
        const response = await axios_1.client.get('/0/account-profile/empty-fields');
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function sendResume(sendResumeForm) {
    try {
        const formData = new FormData();
        Object.keys(sendResumeForm).forEach(key => {
            const value = sendResumeForm[key];
            if (key === 'resume' || key === 'motivationalLetter') {
                formData.append(key, value[0].originFileObj);
            }
            else if (key === 'savedResumes' && value !== 0) {
                formData.append('resume_id', value);
            }
            else if (key === 'newsletter') {
                // @ts-ignore
                formData.append(key, Number(value === 'true'));
            }
            else {
                formData.append(key, value);
            }
        });
        const response = await axios_1.client.post('/0/send-resume-register', formData, {
            headers: {
                'content-type': 'multipart/form-data; boundary=uzpracujes',
            },
            withCredentials: true,
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function sendProfileAsResume(sendProfileForm, generatedResume, resumeUrl) {
    try {
        const formData = new FormData();
        Object.keys(sendProfileForm).forEach(key => {
            const value = sendProfileForm[key];
            if (key === 'motivationalLetter') {
                formData.append(key, value[0].originFileObj);
            }
            else {
                formData.append(key, value);
            }
        });
        if (generatedResume) {
            formData.append('generatedResume', generatedResume);
        }
        if (resumeUrl) {
            formData.append('resumeUrl', resumeUrl);
        }
        const response = await axios_1.client.post('/0/send-resume', formData, {
            headers: {
                'content-type': 'multipart/form-data; boundary=uzpracujes',
            },
            withCredentials: true,
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
async function getApplicantProfile(profileUuid) {
    try {
        const response = await axios_1.client.get('/0/account-profile/applicant-profile', { params: { profileUuid } });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
