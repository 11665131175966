"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuMobileContentInner = exports.MenuMobileContent = exports.HamburgerWrapper = exports.MyAccountInner = exports.MyAccountLinkLabel = exports.MyAccountLink = exports.UserControlsWrapper = exports.UserControls = exports.MenuContentSection = exports.MenuLinkCentered = exports.MenuLink = exports.MenuLabelWrapper = exports.MenuLabel = exports.MenuMasterItem = exports.MenuContentHighlighted = exports.MenuContentInnerAside = exports.MenuContentInner = exports.MenuContentColumn = exports.MenuContent = exports.LoadMore = exports.MenuMobile = exports.Menu = exports.Logo = exports.StyledHolder = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const styled_breakpoints_1 = require("styled-breakpoints");
const polished_1 = require("polished");
const elements_1 = require("@src/components/elements");
exports.StyledHolder = (0, styled_components_1.default)(elements_1.Holder) `
	position: relative;
	display: block;
`;
exports.Logo = (0, styled_components_1.default)(elements_1.Link) `
	display: block;
	position: absolute;
	top: 0.75rem;
	left: 1rem;
	z-index: 1;
	transition: transform 0.2s ease;

	&,
	&:link,
	&:focus,
	&:visited,
	&:active,
	&:hover {
		color: ${props => props.theme.color.white};
	}

	&:hover {
		transform: scale(1.1);
	}
`;
exports.Menu = styled_components_1.default.div `
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	top: 0;
	left: auto;
	right: auto;

	${(0, styled_breakpoints_1.down)('lg')} {
		position: static;
	}

	${(0, styled_breakpoints_1.down)('sm')} {
		display: none;
	}
`;
exports.MenuMobile = styled_components_1.default.div `
	position: absolute;
	top: 1rem;
	left: 0.5rem;
	color: ${props => props.theme.color.brand1};
	background: ${props => props.theme.color.white};
	width: calc(100% - 1rem);
	border-radius: 4px;
	z-index: 1;
	min-height: 10rem;
	max-height: 80vh;
	transform: scale(0);
	transform-origin: calc(100% - 0.8rem) 1rem;
	box-shadow: 0 0px 0 3px ${props => (0, polished_1.transparentize)(0.8, props.theme.color.gray6)}, 0 4.2px 2.4px rgba(0, 0, 0, 0.004),
		0 10.7px 6.5px rgba(0, 0, 0, 0.015), 0 21.8px 14.3px rgba(0, 0, 0, 0.029), 0 44.9px 31.6px rgba(0, 0, 0, 0.05),
		0 123px 94px rgba(0, 0, 0, 0.09);
	overflow-y: scroll;

	// chrome fux up our transitions on initial load
	// this is a temporary fix
	${props => props.mounted &&
    (0, styled_components_1.css) `
			transition: transform 0.2s ease;
		`}

	${props => props.open &&
    (0, styled_components_1.css) `
			transform: scale(1);
		`}
`;
exports.LoadMore = styled_components_1.default.div `
	width: 100%;
	font-weight: 500;
	text-align: center;
	margin: 0.5rem 0;
	cursor: pointer;
	text-transform: none;
	padding: 0.5rem;
	border-radius: 4px;
	color: ${props => props.theme.color.brand1};
	:hover {
		background: ${props => (0, polished_1.transparentize)(0.8, props.theme.color.brand4)};
	}
`;
exports.MenuContent = styled_components_1.default.div `
	overscroll-behavior: contain;
	top: 50px;
	left: 50%;
	position: absolute;
	display: flex;
	color: ${props => props.theme.color.black};
	background: ${props => props.theme.color.white};
	border-radius: 0.3rem;
	box-shadow: 0 0px 0 3px ${props => (0, polished_1.transparentize)(0.8, props.theme.color.gray6)}, 0 4.2px 2.4px rgba(0, 0, 0, 0.004),
		0 10.7px 6.5px rgba(0, 0, 0, 0.015), 0 21.8px 14.3px rgba(0, 0, 0, 0.029), 0 44.9px 31.6px rgba(0, 0, 0, 0.05),
		0 123px 94px rgba(0, 0, 0, 0.09);
	padding: 50px 0 0 0;
	z-index: 1;
	opacity: 0;
	transform-origin: top center;
	transform: translateX(-50%) perspective(400px) rotateX(-15deg);
	pointer-events: none;
	padding: 0.5em;
	min-width: 250px;
	text-align: left;
	max-height: 450px;
	overflow-y: auto;

	// chrome fux up our transitions on initial load
	// this is a temporary fix
	${props => props.mounted &&
    (0, styled_components_1.css) `
			transition: opacity 0.3s ease, transform 0.3s ease;
		`}

	& > * + * {
		margin-left: 0.5rem;
	}

	${props => props.visible &&
    (0, styled_components_1.css) `
			transform: translateX(-50%) perspective(0) rotateX(0deg);
			opacity: 1;
			pointer-events: auto;
		`}

	${(0, styled_breakpoints_1.down)('lg')} {
		left: unset;
		right: 0.6rem;
		top: 60px;
		transform: translateX(${props => (props.offset ? props.offset : 0)}) perspective(400px) rotateX(-15deg);

		${props => props.visible &&
    (0, styled_components_1.css) `
				transform: translateX(${props.offset ? props.offset : 0}) perspective(0) rotateX(0deg);
			`}

		${props => props.userControls &&
    (0, styled_components_1.css) `
				left: unset;
				right: 0rem;
				top: 40px;
				transform: translateX(0) perspective(400px) rotateX(-15deg);

				${props.visible &&
        (0, styled_components_1.css) `
					transform: translateX(0) perspective(0) rotateX(0deg);
				`}
			`}
	}

	${props => props.userControls &&
    (0, styled_components_1.css) `
			left: unset;
			right: 0rem;
			top: 40px;
			transform: translateX(0) perspective(400px) rotateX(-15deg);

			${props.visible &&
        (0, styled_components_1.css) `
				transform: translateX(0) perspective(0) rotateX(0deg);
			`}
		`}
`;
exports.MenuContentColumn = styled_components_1.default.div `
	display: flex;
	flex-direction: column;
`;
exports.MenuContentInner = styled_components_1.default.div `
	border-radius: 0.3rem;
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0;
	width: 300px;
	${props => props.width &&
    (0, styled_components_1.css) `
			width: ${props.width}px;
		`}
`;
exports.MenuContentInnerAside = (0, styled_components_1.default)(exports.MenuContentInner) `
	background: ${props => (0, polished_1.transparentize)(0.9, props.theme.color.brand2)};
	padding: 0.5rem;
	flex: 1;
`;
exports.MenuContentHighlighted = styled_components_1.default.div `
	border: 2px solid ${props => (0, polished_1.transparentize)(0.9, props.theme.color.success)};
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	text-align: center;
	margin: 1rem 0 0 0;
	padding: 1rem;
	border-radius: 0.3rem;
	font-size: 1.2rem;
`;
exports.MenuMasterItem = styled_components_1.default.button `
	display: flex;
	align-items: center;
	border: 0;
	outline: 0;
	font-weight: bold;
	padding: 0;
	margin: 10px 0;
	position: relative;
	height: calc(${props => props.theme.size.headerHeight}px - 20px);
	font-size: ${props => props.theme.fontSizes.m};
	color: ${props => props.theme.color.white};

	${(0, styled_breakpoints_1.down)('lg')} {
		font-size: 0.9rem;
		position: static;
	}
`;
exports.MenuLabel = (0, styled_components_1.default)(elements_1.Link) `
	position: relative;
	padding: 0 1em 0;

	${(0, styled_breakpoints_1.down)('lg')} {
		padding: 0 0.7em 0;
	}

	& > span {
		transition: opacity 0.2s ease;
		text-transform: uppercase;
		padding: 0 0 0.1rem 0;
		margin-bottom: -0.2rem;
		border-bottom: 2px solid transparent;
	}

	${props => props.hasBackground &&
    (0, styled_components_1.css) `
			& > span {
				border-bottom: 2px solid ${props.theme.color.white};
			}
		`};

	&,
	&:link,
	&:visited,
	&:focus,
	&:hover,
	&:active {
		height: 100%;
		display: flex;
		align-items: center;
		color: ${props => props.theme.color.white};
	}
	&:after {
		content: '';
		left: 50%;
		display: block;
		position: absolute;
		width: 0px;
		height: 0px;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 6px solid ${props => props.theme.color.white};
		opacity: 0;
		bottom: 10px;
		transform: scale(0) translateX(-50%);
		transform-origin: left bottom;
		transition: opacity 0.3s ease, transform 0.3s ease;
		z-index: 2;
	}

	${props => props.hasContent &&
    props.active &&
    (0, styled_components_1.css) `
			&:after {
				opacity: 1;
				transform: scale(1) translateX(-50%);
			}
		`};

	${props => props.otherLabelActive &&
    (0, styled_components_1.css) `
			& > * {
				opacity: 0.4;
			}
		`};

	${props => props.active &&
    (0, styled_components_1.css) `
			& > * {
				opacity: 1;
			}
		`};
`;
exports.MenuLabelWrapper = (0, styled_components_1.default)(elements_1.Link) `
	position: relative;
	padding: 0;

	&:after {
		content: '';
		left: 50%;
		display: block;
		position: absolute;
		width: 0px;
		height: 0px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid ${props => props.theme.color.white};
		opacity: 0;
		bottom: -8px;
		transform: scale(0) translateX(-50%);
		transform-origin: left bottom;
		transition: opacity 0.3s ease, transform 0.3s ease;
		z-index: 2;
	}

	${props => props.hasContent &&
    props.active &&
    (0, styled_components_1.css) `
			&:after {
				opacity: 1;
				transform: scale(1) translateX(-50%);
			}
		`};
`;
exports.MenuLink = (0, styled_components_1.default)(elements_1.Link) `
	padding: 0.6rem 1rem;
	width: 100%;
	font-size: 0.9rem;
	display: flex;
	align-items: center;
	transition: all 0.2s ease;
	border-radius: 4px;
	position: relative;

	& > * + * {
		margin-left: 0.7rem;
	}
	&:hover,
	&:active {
		background: ${props => (0, polished_1.transparentize)(0.8, props.theme.color.brand4)};
	}
	span {
		margin-top: 0.2rem;
		flex: 1;
	}
	svg {
		transform: scale(0.9);
		width: 24px;
	}
`;
exports.MenuLinkCentered = (0, styled_components_1.default)(exports.MenuLink) `
	justify-content: center;
	span {
		flex: unset;
	}
`;
exports.MenuContentSection = styled_components_1.default.h3 `
	color: ${props => (0, polished_1.transparentize)(0.7, props.theme.color.black)};
	font-size: 0.8rem;
	padding: 0.4rem 1rem;
	font-weight: bold;
	text-transform: uppercase;

	${props => props.marginTop &&
    (0, styled_components_1.css) `
			margin-top: 0.5rem;
		`};

	${props => props.paddingRight &&
    (0, styled_components_1.css) `
			padding-right: 2.5rem;
		`};

	${props => props.ellipsis &&
    (0, styled_components_1.css) `
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		`};
`;
exports.UserControls = styled_components_1.default.div `
	height: 2rem;
	display: flex;
	align-items: stretch;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 0.8rem;

	& > * + * {
		margin: 0 0 0 0.4rem;
	}
`;
exports.UserControlsWrapper = styled_components_1.default.div `
	top: 1.3rem;
	right: 1rem;
	position: absolute;
`;
exports.MyAccountLink = styled_components_1.default.div `
	display: flex;
	align-items: start;
	z-index: 0;
	height: 2.5rem;

	svg {
		color: ${props => props.theme.color.white};
		width: 18px;
		height: 18px;
		/* margin-top: -0.1rem; */
		margin-right: 0.7rem;

		${(0, styled_breakpoints_1.down)('md')} {
			margin-right: 0;
		}
	}
`;
exports.MyAccountLinkLabel = styled_components_1.default.div `
	display: flex;
	align-items: center;
	height: 100%;

	${(0, styled_breakpoints_1.down)('md')} {
		display: none;
	}
`;
exports.MyAccountInner = styled_components_1.default.div `
	border-radius: 4px;
	background: ${props => (0, polished_1.transparentize)(0.8, props.theme.color.white)};
	transition: all 0.2s ease;
	display: flex;
	align-items: center;
	padding: 0.3rem 0.7rem 0.2rem;
	height: 2rem;
	min-width: 2rem;
	color: ${props => props.theme.color.white};

	&:hover {
		background: ${props => (0, polished_1.transparentize)(0.7, props.theme.color.white)};
	}

	${(0, styled_breakpoints_1.down)('sm')} {
		&,
		&:hover {
			background: none;
		}
	}
`;
exports.HamburgerWrapper = styled_components_1.default.div `
	padding-top: 2px;
	border-radius: 4px;
	align-items: center;
	height: 2rem;
	width: 2rem;
	display: none;
	z-index: 2;
	position: relative;

	& > * {
		top: -8px;
		left: -8px;
	}

	${(0, styled_breakpoints_1.down)('sm')} {
		display: block;
	}
`;
exports.MenuMobileContent = styled_components_1.default.div `
	padding: 0.5em;
`;
exports.MenuMobileContentInner = styled_components_1.default.div ``;
