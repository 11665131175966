"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.postOfferDraft = postOfferDraft;
exports.getOffer = getOffer;
exports.updateOffer = updateOffer;
exports.archiveOffer = archiveOffer;
exports.createOffer = createOffer;
exports.getOfferTypes = getOfferTypes;
exports.fetchOfferFromExternalLink = fetchOfferFromExternalLink;
const axios_1 = require("./clients/axios");
async function postOfferDraft(data) {
    const response = await axios_1.client.post('/0/offer/draft', data);
    return response.data;
}
async function getOffer(id, slug, fetchForEdit) {
    const response = await axios_1.client.get('/0/offer', {
        params: {
            id,
            edit: fetchForEdit,
            slug,
        },
    });
    return response.data;
}
async function updateOffer(offerId, data) {
    const response = await axios_1.client.put('/0/offer', data, { params: { id: offerId } });
    return response.data;
}
async function archiveOffer(id) {
    const response = await axios_1.client.put('/0/offer/archive', {
        id,
    });
    return response.data;
}
async function createOffer(data) {
    const response = await axios_1.client.post('/0/offer', data);
    return response.data.offerId;
}
async function getOfferTypes() {
    const response = await axios_1.client.get('/0/offer-type', {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
    return response.data;
}
async function fetchOfferFromExternalLink(link) {
    const response = await axios_1.client.get('/0/offer/load-external-offer', { params: { link } });
    return response.data;
}
