"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOfferApplicationHistory = getOfferApplicationHistory;
exports.createOfferApplicationStandardNote = createOfferApplicationStandardNote;
exports.updateOfferApplicationHistoryItem = updateOfferApplicationHistoryItem;
exports.deleteOfferApplicationHistoryItem = deleteOfferApplicationHistoryItem;
const axios_1 = require("./clients/axios");
async function getOfferApplicationHistory(offerApplicationId) {
    const response = await axios_1.client.get(`/0/offer-application-history`, {
        params: {
            offerApplicationId,
        },
    });
    return response.data;
}
async function createOfferApplicationStandardNote(noteText, offerApplicationId, type) {
    const response = await axios_1.client.post(`/0/offer-application-history`, { noteText, offerApplicationId, type });
    return response.data;
}
async function updateOfferApplicationHistoryItem(historyItemId, newHistoryItemText) {
    const response = await axios_1.client.put(`/0/offer-application-history/${historyItemId}`, {
        newHistoryItemText,
    });
    return response.data;
}
async function deleteOfferApplicationHistoryItem(historyItemId) {
    const response = await axios_1.client.delete(`/0/offer-application-history/${historyItemId}`);
    return response.data;
}
