"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOfferEditContextInit = useOfferEditContextInit;
const use_offer_api_1 = require("@src/components/pages/admin/modules/offer-create-or-edit/use-offer-api");
function useOfferEditContextInit({ setOfferBasicInfoInitialValues, setOfferBasicInfo, setOfferPaymentTypeInitialValues, setOfferPaymentType, setOfferAssetsInitialValues, setOfferAssets, setIsOfferActive, }) {
    const { fetchOfferInitialValuesForEdit } = (0, use_offer_api_1.useOfferApi)();
    const initializeOfferContextForEdit = async ({ offerId, isEditingDuplicateOffer, slug, }) => {
        const { offerBasicInfo: fetchedOfferBasicInfoInitialValues, offerAssets: fetchedOfferAssetsInitialValues, offerPaymentType: fetchedOfferPaymentTypeInitialValues, isActive, } = await fetchOfferInitialValuesForEdit({ offerId, isEditingDuplicateOffer, slug });
        setOfferBasicInfoInitialValues(fetchedOfferBasicInfoInitialValues);
        setOfferBasicInfo(fetchedOfferBasicInfoInitialValues);
        setOfferPaymentTypeInitialValues(fetchedOfferPaymentTypeInitialValues);
        setOfferPaymentType(fetchedOfferPaymentTypeInitialValues);
        setOfferAssetsInitialValues(fetchedOfferAssetsInitialValues);
        setOfferAssets(fetchedOfferAssetsInitialValues);
        setIsOfferActive(isActive);
    };
    return { initializeOfferContextForEdit };
}
