"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubscription = getSubscription;
exports.createSubscription = createSubscription;
exports.updateSubscription = updateSubscription;
exports.isEmailTaken = isEmailTaken;
exports.deleteSubscription = deleteSubscription;
const axios_1 = require("./clients/axios");
async function getSubscription(token) {
    const response = await axios_1.client.get(`/0/offer-notification-subscription${token ? `?onst=${token}` : ''}`);
    return response.data;
}
async function createSubscription(subscriptionData) {
    await axios_1.client.post('/0/offer-notification-subscription', subscriptionData);
}
async function updateSubscription(subscriptionData, token) {
    await axios_1.client.put(`/0/offer-notification-subscription?onst=${token}`, subscriptionData);
}
async function isEmailTaken(email, token) {
    const tokenQueryPart = token ? `&onst=${token}` : '';
    const response = await axios_1.client.get(`/0/offer-notification-subscription/is-email-taken?email=${email}${tokenQueryPart}`);
    return response.data;
}
async function deleteSubscription(token) {
    await axios_1.client.delete(`/0/offer-notification-subscription?onst=${token}`);
}
