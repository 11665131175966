"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OfferFilterType = void 0;
var OfferFilterType;
(function (OfferFilterType) {
    OfferFilterType["type"] = "selectedTypeIds";
    OfferFilterType["location"] = "selectedLocations";
    OfferFilterType["jobArea"] = "selectedJobAreaIds";
    OfferFilterType["salaryMin"] = "salaryMin";
    OfferFilterType["salaryMax"] = "salaryMax";
    OfferFilterType["salaryRate"] = "salaryRate";
    OfferFilterType["searchCompaniesSelected"] = "selectedCompaniesIds";
    OfferFilterType["searchKeywordsSelected"] = "selectedKeywords";
    OfferFilterType["searchSkillTagsSelected"] = "selectedSkillTags";
})(OfferFilterType || (exports.OfferFilterType = OfferFilterType = {}));
