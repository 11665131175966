import React from 'react'

const BuildingOutlined = () => (
	<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g>
			<path
				d="M2.25,23.25V6a.748.748,0,0,1,.394-.66L10.894.9A.75.75,0,0,1,12,1.558V23.25"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M21.75,23.25V13.083a.75.75,0,0,0-.29-.592L16.21,8.408A.749.749,0,0,0,15,9V23.25"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M23.25 23.25L0.75 23.25"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M12 8.25L7.5 8.25"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M12 11.25L6 11.25"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M6,23.25H9v-3a1.5,1.5,0,0,0-3,0Z"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M4.5 4.34L4.5 0.75"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M15 13.5L17.25 13.5"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M15 16.5L18.75 16.5"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M20.25 11.55L20.25 8.25"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
		</g>
	</svg>
)

export default BuildingOutlined
