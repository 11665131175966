"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BillingProfileForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const Api = __importStar(require("api"));
const react_router_dom_1 = require("react-router-dom");
const react_intl_1 = require("react-intl");
const i18n_iso_countries_1 = require("i18n-iso-countries");
const elements_1 = require("@src/components/elements");
const normalize_string_1 = require("@src/helpers/normalize-string");
const app_context_1 = require("@src/context/app-context");
const useConfig_1 = require("@src/hooks/useConfig");
const useModelEffect_1 = require("@src/hooks/useModelEffect");
const BillingProfileForm_styles_1 = require("./BillingProfileForm.styles");
const BillingProfileForm = ({ billingProfileId, onSubmit, ActionsBlock, isDisabled, }) => {
    const [form] = antd_1.Form.useForm();
    const intl = (0, react_intl_1.useIntl)();
    const history = (0, react_router_dom_1.useHistory)();
    const config = (0, useConfig_1.useConfig)();
    const appContext = (0, app_context_1.useAppContextValue)();
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [selectedCountryCode, setSelectedCountryCode] = react_1.default.useState('');
    const onFinish = async (billingProfileFormData) => {
        var _a;
        if (isDisabled) {
            return;
        }
        try {
            setIsLoading(true);
            if (billingProfileId) {
                await Api.CompanyBillingProfile.editBillingProfile(Number(billingProfileId), billingProfileFormData);
            }
            else {
                await Api.CompanyBillingProfile.createBillingProfile(billingProfileFormData);
            }
            if (billingProfileId) {
                antd_1.notification.success({
                    message: intl.formatMessage({
                        id: 'admin.payments.billing_profile_edited',
                    }),
                    placement: 'bottomRight',
                });
            }
            else {
                antd_1.notification.success({
                    message: intl.formatMessage({
                        id: 'admin.payments.billing_profile_created',
                    }),
                    placement: 'bottomRight',
                });
            }
            onSubmit(billingProfileFormData);
        }
        catch (err) {
            console.error(err);
            const errDescription = ((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === 422
                ? intl.formatMessage({ id: 'global.checkout.invalid_payment_information' })
                : intl.formatMessage({ id: 'general.something_went_wrong' });
            antd_1.notification.error({
                message: intl.formatMessage({ id: 'general.error' }),
                description: errDescription,
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    const onEditForm = async () => {
        try {
            const billingProfileDataById = await Api.CompanyBillingProfile.getBillingProfileById(Number(billingProfileId));
            form.setFieldsValue(billingProfileDataById);
            setSelectedCountryCode(billingProfileDataById.country);
        }
        catch (err) {
            antd_1.notification.error({
                message: intl.formatMessage({
                    id: 'general.error',
                }),
                description: intl.formatMessage({
                    id: 'general.something_went_wrong',
                }),
            });
            console.error(err);
        }
    };
    react_1.default.useEffect(() => {
        if (billingProfileId) {
            onEditForm();
        }
        else {
            setSelectedCountryCode(config.countryCode);
        }
    }, [billingProfileId]);
    (0, useModelEffect_1.useModelEffect)(([upToDateForm, upToDateSelectedCountryCode]) => {
        if (upToDateSelectedCountryCode !== config.countryCode) {
            upToDateForm.setFieldsValue({
                tin: null,
                cin: null,
            });
        }
    }, [form, selectedCountryCode], [selectedCountryCode]);
    const countries = react_1.default.useMemo(() => Object.entries((0, i18n_iso_countries_1.getNames)(appContext.language)), [appContext.language]);
    return ((0, jsx_runtime_1.jsxs)(antd_1.Form, { form: form, name: "billingProfile", onFinish: onFinish, labelAlign: "left", layout: "vertical", scrollToFirstError: true, children: [(0, jsx_runtime_1.jsx)(antd_1.Form.Item, { rules: [
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'admin.payments.billing_profile.fill_the_name',
                        }),
                    },
                ], name: "name", label: intl.formatMessage({
                    id: 'admin.payments.billing_profile.name',
                }), children: (0, jsx_runtime_1.jsx)(elements_1.Input, { disabled: isDisabled, placeholder: intl.formatMessage({
                        id: 'admin.payments.billing_profile.name_placeholder',
                    }) }) }, "name"), (0, jsx_runtime_1.jsxs)(antd_1.Row, { gutter: 16, children: [(0, jsx_runtime_1.jsx)(antd_1.Col, { md: 12, xs: 24, children: (0, jsx_runtime_1.jsx)(antd_1.Form.Item, { name: "street", label: intl.formatMessage({
                                id: 'admin.payments.billing_profile.street',
                            }), children: (0, jsx_runtime_1.jsx)(elements_1.Input, { disabled: isDisabled, placeholder: intl.formatMessage({
                                    id: 'admin.payments.billing_profile.street_placeholder',
                                }) }) }, "street") }), (0, jsx_runtime_1.jsx)(antd_1.Col, { md: 12, xs: 24, children: (0, jsx_runtime_1.jsx)(antd_1.Form.Item, { name: "city", label: intl.formatMessage({
                                id: 'admin.payments.billing_profile.city',
                            }), children: (0, jsx_runtime_1.jsx)(elements_1.Input, { disabled: isDisabled, placeholder: intl.formatMessage({
                                    id: 'admin.payments.billing_profile.city_placeholder',
                                }) }) }, "city") })] }), (0, jsx_runtime_1.jsxs)(antd_1.Row, { gutter: 16, children: [(0, jsx_runtime_1.jsx)(antd_1.Col, { md: 12, xs: 24, children: (0, jsx_runtime_1.jsx)(antd_1.Form.Item, { name: "postal_code", label: intl.formatMessage({
                                id: 'admin.payments.billing_profile.postal_code',
                            }), validateTrigger: "onBlur", rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'registration.sync_validation.postal_code',
                                    }),
                                },
                                {
                                    min: 4,
                                    message: intl.formatMessage({
                                        id: 'registration.sync_validation.too_short',
                                    }),
                                },
                                {
                                    max: 8,
                                    message: intl.formatMessage({
                                        id: 'registration.sync_validation.too_long',
                                    }),
                                },
                                {
                                    pattern: /^([a-zA-Z0-9]([\s])*)*$/g,
                                    message: intl.formatMessage({
                                        id: 'registration.sync_validation.restricted_symbols_postal_code',
                                    }),
                                },
                            ], children: (0, jsx_runtime_1.jsx)(elements_1.Input, { disabled: isDisabled, placeholder: intl.formatMessage({
                                    id: 'admin.payments.billing_profile.zip_code_placeholder',
                                }) }) }, "postal_code") }), (0, jsx_runtime_1.jsx)(antd_1.Col, { md: 12, xs: 24, children: (0, jsx_runtime_1.jsx)(antd_1.Form.Item, { name: "country", label: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "admin.payments.billing_profile.country" }), initialValue: config.countryCode, children: (0, jsx_runtime_1.jsx)(elements_1.Select, { disabled: isDisabled, showSearch: true, optionFilterProp: "children", filterOption: (input, option) => (0, normalize_string_1.normalizeStr)(option.children).indexOf((0, normalize_string_1.normalizeStr)(input)) >= 0, onSelect: countryCode => setSelectedCountryCode(countryCode), children: countries.map(([code, name]) => ((0, jsx_runtime_1.jsx)(elements_1.Select.Option, { value: code, children: name }, code))) }) }, "country") })] }), selectedCountryCode === 'SK' && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(antd_1.Form.Item, { rules: [
                            {
                                pattern: /^(\d{8})?$/g,
                                message: intl.formatMessage({
                                    id: 'admin.payments.billing_profile.wrong_cin',
                                }),
                            },
                        ], validateTrigger: "onBlur", name: "cin", label: intl.formatMessage({
                            id: 'admin.payments.billing_profile.cin',
                        }), children: (0, jsx_runtime_1.jsx)(elements_1.Input, { disabled: isDisabled, placeholder: intl.formatMessage({
                                id: 'admin.payments.billing_profile.cin_placeholder',
                            }) }) }, "cin"), (0, jsx_runtime_1.jsx)(antd_1.Form.Item, { name: "tin", label: intl.formatMessage({
                            id: 'admin.payments.billing_profile.tin',
                        }), children: (0, jsx_runtime_1.jsx)(elements_1.Input, { disabled: isDisabled, placeholder: intl.formatMessage({
                                id: 'admin.payments.billing_profile.tin_placeholder',
                            }) }) }, "tin")] })), (0, jsx_runtime_1.jsx)(antd_1.Form.Item, { name: "tin_vat", validateTrigger: ['onBlur', 'onChange'], rules: [
                    {
                        required: selectedCountryCode !== config.countryCode,
                        message: intl.formatMessage({
                            id: 'admin.payments.billing_profile.fill_tin_wat',
                        }),
                    },
                ], label: selectedCountryCode !== 'CZ'
                    ? intl.formatMessage({
                        id: 'admin.payments.billing_profile.tin_vat',
                    })
                    : intl.formatMessage({
                        id: 'admin.payments.billing_profile.tin',
                    }), children: (0, jsx_runtime_1.jsx)(elements_1.Input, { disabled: isDisabled, placeholder: intl.formatMessage({
                        id: 'admin.payments.billing_profile.tin_vat_placeholder',
                    }) }) }, "tin_vat"), !ActionsBlock && ((0, jsx_runtime_1.jsx)(antd_1.Row, { children: (0, jsx_runtime_1.jsx)(antd_1.Col, { span: 24, children: (0, jsx_runtime_1.jsxs)(antd_1.Form.Item, { className: "text-center", children: [(0, jsx_runtime_1.jsx)(elements_1.Button, { type: "default", className: "m-r-s", onClick: () => history.pushLocalized('/admin/platby'), children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "general.cancel" }) }), (0, jsx_runtime_1.jsx)(elements_1.Button, { type: "primary", htmlType: "submit", children: billingProfileId ? ((0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "general.save" })) : ((0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "general.create" })) })] }, "buttons") }) })), !!ActionsBlock && (0, jsx_runtime_1.jsx)(ActionsBlock, { isLoading: isLoading }), (0, jsx_runtime_1.jsxs)(BillingProfileForm_styles_1.RequiredWrapper, { children: [(0, jsx_runtime_1.jsx)(BillingProfileForm_styles_1.RedText, { children: "*" }), (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "admin.payments.billing_profile.mandatory_fields" })] })] }));
};
exports.BillingProfileForm = BillingProfileForm;
