"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOfferNotifications = useOfferNotifications;
const antd_1 = require("antd");
const react_intl_1 = require("react-intl");
function useOfferNotifications() {
    const intl = (0, react_intl_1.useIntl)();
    function showGeneralErrorNotification() {
        antd_1.notification.error({
            message: intl.formatMessage({ id: 'general.error' }),
            description: intl.formatMessage({ id: 'general.something_went_wrong' }),
        });
    }
    function showOfferCreatedNotification() {
        antd_1.notification.success({
            message: intl.formatMessage({ id: 'general.notification' }),
            description: intl.formatMessage({ id: 'admin.offer.notification.created' }),
        });
    }
    function showOfferEditedNotification() {
        antd_1.notification.success({
            message: intl.formatMessage({ id: 'general.notification' }),
            description: intl.formatMessage({ id: 'admin.offer.notification.edited' }),
        });
    }
    function showNotVerifiedNotification() {
        antd_1.notification.error({
            message: intl.formatMessage({ id: 'general.error' }),
            description: intl.formatMessage({ id: 'admin.offer.notification.not_verified' }),
        });
    }
    function showSuccessfullScrapingNotification() {
        antd_1.notification.success({
            message: intl.formatMessage({ id: 'general.notice' }),
            description: intl.formatMessage({ id: 'admin.offer.notification.scraped_offer' }),
        });
    }
    function showDraftSavedNotification() {
        antd_1.notification.success({
            message: intl.formatMessage({
                id: 'admin.offer_form.draft_saved',
            }),
        });
    }
    function showAlreadyPublishedNotification() {
        antd_1.notification.warning({
            message: intl.formatMessage({ id: 'general.notice' }),
            description: intl.formatMessage({ id: 'admin.offer.offer_already_published' }),
            duration: 10,
        });
    }
    function showScrapingSuccessfullNotification() {
        antd_1.notification.success({
            message: intl.formatMessage({ id: 'general.notice' }),
            description: intl.formatMessage({ id: 'admin.offer.notification.scraped_offer' }),
        });
    }
    function showTitleGalleryChangedNotification() {
        antd_1.notification.success({
            message: intl.formatMessage({ id: 'admin.offer.assets.title_gallery_changed' }),
        });
    }
    return {
        showGeneralErrorNotification,
        showOfferCreatedNotification,
        showOfferEditedNotification,
        showNotVerifiedNotification,
        showSuccessfullScrapingNotification,
        showDraftSavedNotification,
        showAlreadyPublishedNotification,
        showScrapingSuccessfullNotification,
        showTitleGalleryChangedNotification,
    };
}
