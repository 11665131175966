"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRaceControl = void 0;
const react_1 = require("react");
const useRaceControl = () => {
    const ref = (0, react_1.useRef)(false);
    return [
        ref.current,
        (isLoading) => {
            ref.current = isLoading;
        },
    ];
};
exports.useRaceControl = useRaceControl;
