"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyAssetGalleryCardMenu = CompanyAssetGalleryCardMenu;
const jsx_runtime_1 = require("react/jsx-runtime");
const React = __importStar(require("react"));
const antd_1 = require("antd");
const icons_1 = require("@ant-design/icons");
const react_intl_1 = require("react-intl");
const Api = __importStar(require("@src/api"));
const useAppMutation_1 = require("@src/hooks/useAppMutation");
const useSSRFetch_1 = require("@src/hooks/useSSRFetch");
const CompanyAssetGalleryCardMenu_style_1 = require("./CompanyAssetGalleryCardMenu.style");
function CompanyAssetGalleryCardMenu({ gallery, setSelectedAssetGalleryAssetIds, setAssetGalleryToEdit, }) {
    const intl = (0, react_intl_1.useIntl)();
    const { invalidateSSRContext } = (0, useSSRFetch_1.useSSRFetchClient)();
    const { mutateAsync: deleteCompanyAssetGallery } = (0, useAppMutation_1.useAppMutation)(Api.CompanyAssetGallery.deleteCompanyAssetGallery, {
        onSuccess: () => {
            antd_1.notification.success({
                message: intl.formatMessage({
                    id: 'general.warning',
                }),
                description: intl.formatMessage({
                    id: 'general.successfuly_removed',
                }),
            });
        },
    });
    const menuButtons = [
        {
            key: 'edit',
            onClick: ({ domEvent }) => {
                domEvent.stopPropagation();
                if (setSelectedAssetGalleryAssetIds) {
                    const galleryAssetsIds = gallery.companyAssetGalleryAssets.map(({ assetId }) => assetId);
                    setSelectedAssetGalleryAssetIds(galleryAssetsIds);
                }
                setAssetGalleryToEdit(gallery);
            },
            icon: (0, jsx_runtime_1.jsx)(icons_1.EditOutlined, { className: "m-r-xs" }),
        },
        {
            key: 'delete',
            onClick: async ({ domEvent }) => {
                domEvent.stopPropagation();
                await deleteCompanyAssetGallery({ galleryId: gallery.id });
                invalidateSSRContext(useSSRFetch_1.SSRFetchConfig.companyProfile.key);
            },
            icon: (0, jsx_runtime_1.jsx)(icons_1.DeleteOutlined, { className: "m-r-xs" }),
        },
    ];
    return ((0, jsx_runtime_1.jsx)(CompanyAssetGalleryCardMenu_style_1.DropdownAbsoluteWrapper, { children: (0, jsx_runtime_1.jsx)(antd_1.Dropdown, { overlayStyle: { zIndex: 3000000001 }, overlay: (0, jsx_runtime_1.jsx)(antd_1.Menu, { className: "flex-col", children: menuButtons.map(menuButton => ((0, jsx_runtime_1.jsxs)(antd_1.Menu.Item, { onClick: menuButton.onClick, children: [(0, jsx_runtime_1.jsx)(CompanyAssetGalleryCardMenu_style_1.IconWrapper, { children: menuButton.icon }), (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: `admin.company_premium_profile.gallery_menu.${menuButton.key}` })] }, menuButton.key))) }), trigger: ['click'], children: (0, jsx_runtime_1.jsx)(CompanyAssetGalleryCardMenu_style_1.StopPropagationWrapper, { onClick: e => e.stopPropagation(), children: (0, jsx_runtime_1.jsx)(CompanyAssetGalleryCardMenu_style_1.StyledEllipsisOutlined, {}) }) }) }));
}
