import { fork, takeLatest, all } from 'redux-saga/effects'
import actions from '../actions'
import { mainInitStart, cookieConsentStart, cookieConsentError } from './app'

import resetPasswordEmailStart from './reset-password-email'
import { myCompanyInfoInitStart, myCompanyInfoInitError } from './my-company'
import { loginInfoEditStart, loginInfoEditError, loginInfoEditSuccess } from './login-info'
import { contactUsStart, contactUsSuccess } from './contact-us'

import { offerFeedbackStart, offerFeedbackSuccess, offerFeedbackError } from './offer-feedback'
import { studentFeedbackStart, studentFeedbackSuccess, studentFeedbackError } from './student-feedback'
import { loadOfferApplicationStart } from './offer-application'

export function* sagas() {
	yield all([
		// app
		fork(takeLatest, actions.mainInitStart().type, mainInitStart),
		fork(takeLatest, actions.cookieConsentStart().type, cookieConsentStart),
		fork(takeLatest, actions.cookieConsentError().type, cookieConsentError),

		// contact-us
		fork(takeLatest, actions.contactUsStart().type, contactUsStart),
		fork(takeLatest, actions.contactUsSuccess().type, contactUsSuccess),

		// reset-password-email
		fork(takeLatest, actions.resetPasswordEmailStart().type, resetPasswordEmailStart),

		// login-info-edit
		fork(takeLatest, actions.loginInfoEditStart().type, loginInfoEditStart),
		fork(takeLatest, actions.loginInfoEditSuccess().type, loginInfoEditSuccess),
		fork(takeLatest, actions.loginInfoEditError().type, loginInfoEditError),

		// admin-init
		fork(takeLatest, actions.myCompanyInfoInitStart().type, myCompanyInfoInitStart),
		fork(takeLatest, actions.myCompanyInfoInitError().type, myCompanyInfoInitError),

		// offer-feedback
		fork(takeLatest, actions.offerFeedbackError().type, offerFeedbackError),
		fork(takeLatest, actions.offerFeedbackStart().type, offerFeedbackStart),
		fork(takeLatest, actions.offerFeedbackSuccess().type, offerFeedbackSuccess),

		// student-feedback
		fork(takeLatest, actions.studentFeedbackStart().type, studentFeedbackStart),
		fork(takeLatest, actions.studentFeedbackSuccess().type, studentFeedbackSuccess),
		fork(takeLatest, actions.studentFeedbackError().type, studentFeedbackError),

		// load offer-application for feedback
		fork(takeLatest, actions.loadOfferApplicationStart().type, loadOfferApplicationStart),
	])
}
