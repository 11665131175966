"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOffersPageFromPathname = getOffersPageFromPathname;
function getOffersPageFromPathname(pathname) {
    const urlParts = pathname.split('/');
    let page = 1;
    if (parseInt(urlParts[urlParts.length - 1], 10)) {
        page = parseInt(urlParts.pop(), 10);
    }
    return page;
}
