"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergePromotedOffersWithOffers = void 0;
const lodash_1 = require("lodash");
function calculateInsertingIndex(offersLength) {
    const getSecondIndex = (dividingNumber) => Math.floor(offersLength / dividingNumber) + (1 + Math.floor(offersLength / dividingNumber));
    if (offersLength % 3 === 0) {
        const firstIndex = offersLength / 3;
        const secondIndex = getSecondIndex(3);
        return [firstIndex, secondIndex];
    }
    if (offersLength === 8 || offersLength === 10) {
        const firstIndex = offersLength / 2 - 1;
        const secondIndex = firstIndex + offersLength / 2;
        return [firstIndex, secondIndex];
    }
    const firstIndex = offersLength / 2;
    const secondIndex = getSecondIndex(2);
    return [firstIndex, secondIndex];
}
const mergePromotedOffersWithOffers = ({ promotedOffers, offers }) => {
    if (!offers || !offers.items) {
        return [];
    }
    const offersCopy = [...offers.items];
    if (!promotedOffers || (promotedOffers && promotedOffers.length === 0)) {
        return offersCopy;
    }
    if (promotedOffers.length === 1) {
        const indexToInsertPromotedOffer = Math.ceil(offersCopy.length / 2);
        offersCopy.splice(indexToInsertPromotedOffer, 0, { ...promotedOffers[0], isPromoted: true });
        return offersCopy;
    }
    const promotedOffersToInsert = (0, lodash_1.sampleSize)(promotedOffers, 2);
    const [firstIndex, secondIndex] = calculateInsertingIndex(offersCopy.length);
    offersCopy.splice(firstIndex, 0, { ...promotedOffersToInsert[0], isPromoted: true });
    offersCopy.splice(secondIndex, 0, { ...promotedOffersToInsert[1], isPromoted: true });
    return offersCopy;
};
exports.mergePromotedOffersWithOffers = mergePromotedOffersWithOffers;
