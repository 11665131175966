"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConvertFilterStatesTypes = void 0;
const types_1 = require("@src/types");
const useConvertFilterStatesTypes = (filterStates) => {
    const { selectedTypeIds, selectedLocations, selectedJobAreaIds, salaryMin, salaryMax, salaryRate, selectedCompaniesIds, selectedKeywords, } = filterStates;
    let min;
    let max;
    let typeIds = [];
    let locationIds = [];
    let jobAreaIds = [];
    let companiesIds = [];
    let keywords = [];
    const valueToNumber = (value) => {
        const numberValue = Number(value);
        const isValueNaN = Number.isNaN(numberValue);
        if (isValueNaN) {
            return undefined;
        }
        return numberValue;
    };
    if (salaryRate) {
        min = valueToNumber(salaryMin);
        max = valueToNumber(salaryMax);
    }
    if (selectedTypeIds) {
        typeIds = selectedTypeIds
            .split(',')
            .map(id => valueToNumber(id))
            .filter(Boolean);
    }
    if (selectedJobAreaIds) {
        jobAreaIds = selectedJobAreaIds
            .split(',')
            .map(id => valueToNumber(id))
            .filter(Boolean);
    }
    if (selectedLocations) {
        locationIds = selectedLocations.split(',').map(loc => {
            if (loc === types_1.ABROAD || loc === types_1.REMOTE) {
                return loc;
            }
            return parseInt(loc, 10);
        });
    }
    if (selectedCompaniesIds) {
        companiesIds = selectedCompaniesIds
            .split(',')
            .map(companyId => valueToNumber(companyId))
            .filter(Boolean);
    }
    if (selectedKeywords) {
        keywords = selectedKeywords.split(',');
    }
    return {
        selectedTypeIds: typeIds,
        selectedLocations: locationIds,
        selectedJobAreaIds: jobAreaIds,
        salaryMin: min,
        salaryMax: max,
        salaryRate: salaryRate,
        selectedCompaniesIds: companiesIds,
        selectedKeywords: keywords,
    };
};
exports.useConvertFilterStatesTypes = useConvertFilterStatesTypes;
