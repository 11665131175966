"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMyAssets = getMyAssets;
exports.saveUploadedImageOrDocumentAsset = saveUploadedImageOrDocumentAsset;
exports.softDeleteAssets = softDeleteAssets;
exports.getUrlForVideoUpload = getUrlForVideoUpload;
exports.saveUploadedVideoAsset = saveUploadedVideoAsset;
exports.waitForVideoToBeProcessed = waitForVideoToBeProcessed;
exports.waitForVideoMp4VersionToBeProcessedAndGetDownloadUrl = waitForVideoMp4VersionToBeProcessedAndGetDownloadUrl;
const axios_1 = require("./clients/axios");
async function getMyAssets(assetType) {
    const params = {};
    if (assetType) {
        params.type = assetType;
    }
    const response = await axios_1.client.get('/0/asset', { params });
    return response.data;
}
async function saveUploadedImageOrDocumentAsset({ uploadingAsset, url, cloudinaryPublicId, }) {
    const response = await axios_1.client.post('/0/asset/image-or-document', {
        type: uploadingAsset.type,
        filename: uploadingAsset.file.name,
        size: uploadingAsset.file.size,
        url,
        cloudinaryPublicId,
    });
    return response.data;
}
async function softDeleteAssets(assetIds) {
    await axios_1.client.delete('/0/asset', { params: { ids: assetIds } });
}
async function getUrlForVideoUpload() {
    const response = await axios_1.client.get('/0/asset/video-upload-url');
    return response.data;
}
async function saveUploadedVideoAsset({ uploadingAsset, muxDirectUploadId, }) {
    const response = await axios_1.client.post('/0/asset/video', {
        filename: uploadingAsset.file.name,
        size: uploadingAsset.file.size,
        muxDirectUploadId,
    });
    return response.data;
}
async function waitForVideoToBeProcessed(muxAssetId) {
    await axios_1.client.get('/0/asset/wait-for-video', { params: { muxAssetId } });
}
async function waitForVideoMp4VersionToBeProcessedAndGetDownloadUrl(muxAssetId) {
    const response = await axios_1.client.get('/0/asset/wait-for-video-mp4', { params: { muxAssetId } });
    return response.data.downloadUrl;
}
